import { combineReducers } from 'redux';

import layout from './modules/layout/layout.reducer';
import previousVisits from './modules/previousVisits/previousVisits.reducer';
import chart from './modules/chart/chart.reducer';
import login from './modules/login/login.reducer';
import board from './modules/board/board.reducer';
import provider from './modules/board/provider.reducer';
import providers from './modules/providers/providers.reducer';
import visit from './modules/visit/visit.reducer';
import pharmacy from './modules/pharmacy/pharmacy.reducer';

const appReducer = combineReducers({
  layout,
  login,
  previousVisits,
  chart,
  board,
  provider,
  providers,
  visit,
  pharmacy
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
