import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import moment from 'moment';
import Close from '@material-ui/icons/Close';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ChartPatientHistoryForm from '../../chart/chartPatientHistoryForm.component';
import ChartPatientPhotos from '../../chart/chartPatientPhotos.component';
import ChartPatientNotes from '../../chart/chartPatientNotes.component';
import ChartPatientAddendums from '../../chart/chartPatientAddendums.component';
import CustomTooltip from '../../../common/customTooltip/customTooltip.component';
import ChartDemographicsTooltip from '../../chart/chartDemographicsTooltip.component';
import ReactSelectField from '../../../common/reactSelectField/reactSelectField.component';
import { mapJsonArrayToSelectOptions, mapJsonToLabelList } from '../../../utilities/nihApiUtils';
import { nihApiTypes } from '../../../types/nihApiTypes';
import PreviousVisitSummaryNavigation from './previousVisitSummaryNavigation.component';
import ChartPatientPrescriptions from '../../chart/chartPatientPrescriptions.component';
import { can } from '../../login/can';
import { providerGroupPermissions } from '../../../types/permissionTypes';

const styles = theme => ({
  card: {
    boxShadow: 'none',
    minWidth: 400
  },
  cardHeader: {
    textAlign: 'center',
    paddingBottom: '1rem'
  },
  heading: {
    cursor: 'pointer'
  },
  cardContent: {
    paddingTop: 0
  },
  subheaderText: {
    color: theme.palette.primary.darkgray
  },
  visitDescriptionText: {
    width: '100%',
    minHeight: 300,
    cursor: 'default'
  },
  editButton: {
    marginLeft: 'auto',
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.primary.main
    },
    height: '2.5rem',
    alignSelf: 'center'
  },
  tooltipText: {
    fontSize: '1.1rem',
    color: theme.palette.primary.darkgray,
    alignSelf: 'center'
  },
  tooltipActionButton: {
    marginLeft: 'auto'
  },
  padding: {
    padding: '1rem'
  },
  formLabel: {
    alignSelf: 'center',
    marginRight: '0.5rem'
  },
  formField: {
    display: 'flex',
    minHeight: 50
  }
});

class PreviousVisitSummary extends Component {
  render() {
    const {
      visit,
      classes,
      handleClose,
      handleEdit,
      isAddend,
      handleSetCurrentVisit,
      visitPaging,
      noteTypes,
      isDisablePaging
    } = this.props;
    const { created, firstName, lastName, chiefComplaint, notes, addendums, patientPhotos, diagnosis, prescriptions, entranceId } = visit;
    const visitDate = created ? moment(created).format('M/D/YYYY') : '';

    return (
      <Fragment>
        <Card className={classes.card}>
          <CardHeader
            action={
              <IconButton disableRipple disableTouchRipple onClick={handleClose}>
                <Close />
              </IconButton>
            }
            title={
              <div style={{ position: 'relative' }}>
                {!isDisablePaging && (
                  <div style={{ position: 'absolute', top: 0, left: 0 }}>
                    <PreviousVisitSummaryNavigation handleSetIndex={handleSetCurrentVisit} paging={visitPaging} />
                  </div>
                )}
                <CustomTooltip content={<ChartDemographicsTooltip visit={visit} />}>
                  <Typography variant={'h6'} className={classes.heading}>{`${firstName} ${lastName} - ${visitDate}`}</Typography>
                </CustomTooltip>
              </div>
            }
            subheader={
              <div>
                <div>{`CC: "${chiefComplaint ? chiefComplaint : 'Unknown'}"`}</div>
                {can(providerGroupPermissions.readDx, entranceId) && (
                  <div>{`Dx: ${visit.diagnosis ? mapJsonToLabelList(nihApiTypes.NIH_DIAGNOSIS, visit.diagnosis) : 'Unknown'}`}</div>
                )}
              </div>
            }
            className={classes.cardHeader}
            classes={{ subheader: classes.subheaderText }}
          />
          <CardContent className={classes.cardContent}>
            <Grid container>
              <Grid item xs={12} lg={8}>
                <ChartPatientHistoryForm isReadOnly={true} visit={visit} />
                <Grid container className={classes.padding} spacing={8}>
                  {/* <Grid item xs={12} style={{ marginBottom: '1rem' }} className={classes.formField}>
                    <div className={classes.formLabel}>{'Rx:'}</div>
                    <div style={{ flex: '1 1 auto' }}>
                      <ReactSelectField
                        selectedValues={mapJsonArrayToSelectOptions(nihApiTypes.NIH_RXTERMS, visit.medications)}
                        isMulti={true}
                        isReadOnly={true}
                      />
                    </div>
                  </Grid> */}
                  {can(providerGroupPermissions.readDx, entranceId) && (
                    <Grid item xs={12} className={classes.formField}>
                      <div className={classes.formLabel}>{'Dx:'}</div>
                      <div style={{ flex: '1 1 auto' }}>
                        <ReactSelectField
                          selectedValues={diagnosis ? mapJsonArrayToSelectOptions(nihApiTypes.NIH_DIAGNOSIS, diagnosis) : []}
                          isMulti={true}
                          isReadOnly={true}
                        />
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                {can(providerGroupPermissions.readPatientImage, entranceId) && <ChartPatientPhotos images={patientPhotos} />}
              </Grid>
              <Grid item xs={12}>
                <ChartPatientPrescriptions prescriptions={prescriptions} />
              </Grid>
              <Grid item xs={12}>
                {can(providerGroupPermissions.readNote, entranceId) && (
                  <ChartPatientNotes notes={notes} noteTypes={noteTypes} isReadOnly={true} />
                )}
              </Grid>
              <Grid item xs={12}>
                {can(providerGroupPermissions.readNote, entranceId) && (
                  <ChartPatientAddendums notes={addendums} noteTypes={noteTypes} isReadOnly={true} />
                )}
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography style={{ fontSize: '1.2rem' }}>
                  {visit.signedDate ? `Last signed ${moment(visit.signedDate).format('M/D/YYYY HH:MM')} by ${visit.providerName}` : ''}
                </Typography>
                {!!handleEdit &&
                  (can(providerGroupPermissions.updateVisit, entranceId) ||
                    can(providerGroupPermissions.createAddendum, entranceId) ||
                    can(providerGroupPermissions.updateAddendum, entranceId)) && (
                    <Button variant={'outlined'} className={classes.editButton} onClick={() => handleEdit(visit.id)}>
                      {isAddend ? 'ADDEND' : 'EDIT'}
                    </Button>
                  )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Fragment>
    );
  }
}

PreviousVisitSummary.propTypes = {
  noteTypes: PropTypes.array.isRequired,
  visit: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleEdit: PropTypes.func,
  isAddend: PropTypes.bool,
  handleSetCurrentVisit: PropTypes.func.isRequired,
  visitPaging: PropTypes.object.isRequired,
  isDisablePaging: PropTypes.bool
};

export default withStyles(styles)(PreviousVisitSummary);
