import './index.css';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Router } from 'react-router';
import { render } from 'react-dom';
import React from 'react';
import ReactGA from 'react-ga';

import Colors from './styles/colors';
import Root from './root.container';
import getStore from './store/store';
import { register as registerServiceWorker } from './serviceWorker';
import { initializeFirebase } from './fcm';
import history from './history';

// injectTapEventPlugin();
const store = getStore();

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: Colors.primary.main,
      gray: Colors.primary.gray,
      darkgray: Colors.primary.darkgray,
      contrastText: Colors.primary.contrastText,
      background: Colors.primary.background,
      lightgray: Colors.primary.lightgray,
      white: Colors.primary.white,
      orange: Colors.primary.orange,
      red: Colors.primary.red,
      green: Colors.primary.green,
      lightBlue: Colors.primary.lightBlue,
    },
    secondary: {
      light: Colors.secondary.light,
      main: Colors.secondary.main,
      dark: Colors.secondary.dark,
      contrastText: Colors.secondary.contrastText,
    },
    title: {
      light: Colors.primary.white,
      main: Colors.primary.white,
      dark: Colors.primary.white,
      contrastText: Colors.secondary.contrastText,
    },
  },
});

const basename = process.env.PUBLIC_URL || '/';

function startApp() {
  ReactGA.initialize('208003275');
  initializeFirebase(store);
  render(
    <Router basename={basename} history={history}>
      <MuiThemeProvider theme={theme}>
        <Root store={store} />
      </MuiThemeProvider>
    </Router>,
    document.getElementById('root')
  );
}

startApp();

registerServiceWorker();
