import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ProvidersDataTable from './providersDataTable.component';
import { getProviders } from './providers.actions';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';

const styles = theme => ({
  container: {
    padding: '1rem',
    height: 'calc(100% - 2rem)'
  }
});

class ProvidersContainer extends Component {
  componentWillMount() {
    this.props.setPageTitle('Providers');
    this.props.getProviders();
  }

  render() {
    const { classes, providers, isLoading } = this.props;

    if (isLoading) return <LoadingOverlay />;

    return (
      <div className={classnames([classes.container, 'page-padding-bottom'])}>
        <ProvidersDataTable
          data={providers}
          handleShowMessageDialog={this.handleShowMessageDialog}
          handleStartVisit={this.handleStartVisit}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    providers: state.providers.providers,
    isLoading: state.providers.isLoading
  };
};

ProvidersContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        setPageTitle,
        getProviders
      }
    )(ProvidersContainer)
  )
);
