import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';

const styles = theme => ({
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
});

export const PreviousVisitSummaryNavigation = props => {
  const { paging, classes, handleSetIndex } = props;

  return (
    <div style={{ display: 'flex' }}>
      <IconButton
        className={classes.iconButton}
        aria-label="Previous"
        disableRipple
        disableTouchRipple
        onClick={() => handleSetIndex(paging.current - 1 >= 0 ? paging.current - 1 : 0)}
        disabled={paging.current - 1 < 0}
      >
        <ArrowLeft />
      </IconButton>
      <Typography style={{ fontSize: '1.1rem', alignSelf: 'center' }}>{`${paging.current + 1} / ${paging.total}`}</Typography>
      <IconButton
        className={classes.iconButton}
        aria-label="Next"
        disableRipple
        disableTouchRipple
        onClick={() => handleSetIndex(paging.current + 1 < paging.total ? paging.current + 1 : paging.total - 1)}
        disabled={paging.current + 1 >= paging.total}
      >
        <ArrowRight />
      </IconButton>
    </div>
  );
};

PreviousVisitSummaryNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
  paging: PropTypes.object.isRequired,
  handleSetIndex: PropTypes.func.isRequired
};

export default withStyles(styles)(PreviousVisitSummaryNavigation);
