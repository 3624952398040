import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import DataTableSearch from './dataTableSearch.component';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormLabel } from '@material-ui/core';

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.darkgray
        },
  spacer: {
    // flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex'
  },
  title: {
    flex: '1 0 auto'
  },
  colorSwitchBase: {
    color: theme.palette.primary.lightgray,
    '&$colorChecked': {
      color: theme.palette.primary.lightgray,
      '& + $colorBar': {
        backgroundColor: theme.palette.primary.darkgray
      }
    }
  },
  colorBar: {},
  colorChecked: {},
  formControlLabelRoot: {
    marginLeft: 0,
    marginRight: 0
  }
});

class DataTableToolbar extends Component {
  render() {
    const { classes, title, handleSearch, handleShowAdvancedSearch, handleIncompleteToggle, isFilterIncomplete } = this.props;

    return (
      <Toolbar className={classNames(classes.root)}>
        <div className={classes.title}>
          <Typography variant="h6" id="tableTitle">
            {title}
          </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <div style={{ marginRight: '2rem' }}>
            <FormControlLabel
              classes={{ root: classes.formControlLabelRoot }}
              control={
                <Fragment>
                  <FormLabel>{'Incomplete'}</FormLabel>
                  <Switch
                    checked={isFilterIncomplete === false}
                    onChange={handleIncompleteToggle}
                    classes={{
                      switchBase: classes.colorSwitchBase,
                      checked: classes.colorChecked,
                      bar: classes.colorBar
                    }}
                  />
                  <FormLabel>{'All'}</FormLabel>
                </Fragment>
              }
            />
          </div>

          <Tooltip title="Search">
            <DataTableSearch handleSearch={handleSearch} handleShowAdvancedSearch={handleShowAdvancedSearch} />
          </Tooltip>
        </div>
      </Toolbar>
    );
  }
}

DataTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  handleShowAdvancedSearch: PropTypes.func,
  handleSearch: PropTypes.func,
  handleIncompleteToggle: PropTypes.func,
  isFilterIncomplete: PropTypes.bool
};

DataTableToolbar = withStyles(toolbarStyles)(DataTableToolbar);

export default DataTableToolbar;
