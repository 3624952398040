import request from 'superagent';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export const geocode = async addressString => {
  const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${addressString}&key=${GOOGLE_MAPS_API_KEY}`;
  return request.get(geocodeUrl).set('Accept', 'application/json');
};

// convert google place result to address component object
export const parseAddressComponents = components => {
  const parsed = {};
  const useShortNameFields = [
    'administrative_area_level_1' // state - need abbreviation
  ];

  components.forEach(item => {
    item.types.forEach(type => {
      const foundShortName = useShortNameFields.findIndex(field => field === type) > -1;

      // use short name if desired
      // default = long name
      if (foundShortName) {
        parsed[type] = item.short_name;
      } else {
        parsed[type] = item.long_name;
      }
    });
  });

  return parsed;
};

// input: google place address_components
// output: object containing basic address keys
export const getAddressObject = components => {
  const addressComponents = parseAddressComponents(components);
  return {
    addressLine1: `${addressComponents.street_number ? addressComponents.street_number : ''} ${
      addressComponents.route ? addressComponents.route : ''
    }`,
    addressCity: `${
      addressComponents.locality ? addressComponents.locality : addressComponents.sublocality ? addressComponents.sublocality : ''
    }`,
    addressState: `${addressComponents.administrative_area_level_1 ? addressComponents.administrative_area_level_1 : ''}`,
    addressZip: `${addressComponents.postal_code ? addressComponents.postal_code : ''}`
  };
};

export default { geocode, parseAddressComponents };
