import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import { can } from '../../modules/login/can';
import { providerGroupPermissions } from '../../types/permissionTypes';

let TableRowActions = props => {
  let { rowData, handleViewAction, handleEditAction, isAddend, theme } = props;
  return (
    <Fragment>
      {(can(providerGroupPermissions.updateVisit, rowData.entranceId) ||
        can(providerGroupPermissions.createAddendum, rowData.entranceId) ||
        can(providerGroupPermissions.updateAddendum, rowData.entranceId)) && (
        <Button
          variant="outlined"
          style={{
            marginRight: '1rem',
            width: 50,
            backgroundColor: rowData.hasUnsignedChanges ? theme.palette.primary.main : 'unset',
            color: rowData.hasUnsignedChanges ? theme.palette.primary.white : theme.palette.primary.main
          }}
          onClick={handleEditAction}
        >
          {isAddend ? 'Addend' : 'Edit'}
        </Button>
      )}

      {can(providerGroupPermissions.readVisit, rowData.entranceId) && (
        <Button variant="outlined" style={{ color: theme.palette.primary.main, width: 50 }} onClick={handleViewAction}>
          {'View'}
        </Button>
      )}
    </Fragment>
  );
};

TableRowActions.propTypes = {
  rowData: PropTypes.object.isRequired,
  handleEditAction: PropTypes.func.isRequired,
  handleViewAction: PropTypes.func.isRequired,
  isAddend: PropTypes.bool
};

export default withTheme()(TableRowActions);
