import { LOGIN_STATE, SET_AUTH, CLEAR_AUTH } from './login.actions';
import produce from 'immer';

const initialState = {
  user: null,
  loginState: LOGIN_STATE.NOT_LOGGED_IN,
  authSet: false,
  roles: [],
  permissions: [],
  providerId: null
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case SET_AUTH:
      draft.authSet = true;

      if (action.auth) {
        draft.user = action.auth;
        draft.loginState = LOGIN_STATE.LOGGED_IN;
        draft.providerId = action.auth.sub.split('|')[1];
      }

      if (action.access) {
        let rolesAttr = action.access['https://relymd-auth-dev.com/roles'];
        let permissionsAttr = action.access['https://relymd-auth-dev.com/permissions'];
        draft.roles = rolesAttr;
        draft.permissions = permissionsAttr;
      }

      break;

    case CLEAR_AUTH:
      draft.user = null;
      draft.roles = [];
      draft.permissions = [];
      draft.loginState = LOGIN_STATE.NOT_LOGGED_IN;
      draft.providerId = null;
      break;

    default:
      break;
  }
}, initialState);
