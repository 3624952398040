import { isNil } from 'lodash';

import {
  GET_NOTE_TYPES_REQUEST,
  GET_NOTE_TYPES_SUCCESS,
  GET_NOTE_TYPES_FAILURE,
  ADD_CHART_NOTE_REQUEST,
  ADD_CHART_NOTE_SUCCESS,
  ADD_CHART_NOTE_FAILURE,
  DELETE_CHART_NOTE_REQUEST,
  DELETE_CHART_NOTE_SUCCESS,
  DELETE_CHART_NOTE_FAILURE,
  GET_CHART_PREVIOUS_VISITS_REQUEST,
  GET_CHART_PREVIOUS_VISITS_SUCCESS,
  GET_CHART_PREVIOUS_VISITS_FAILURE,
  SET_CURRENT_CHART_VISIT,
  CLEAR_CURRENT_CHART_VISIT,
  GET_CHART_NOTES_REQUEST,
  GET_CHART_NOTES_SUCCESS,
  GET_CHART_NOTES_FAILURE,
  CLEAR_CHART_PREVIOUS_VISITS,
  UPDATE_VISIT_REQUEST,
  UPDATE_VISIT_SUCCESS,
  UPDATE_VISIT_FAILURE,
  UPDATE_ADDENDUMS_REQUEST,
  UPDATE_ADDENDUMS_SUCCESS,
  UPDATE_ADDENDUMS_FAILURE,
  SET_CONFIRM_CHARGES
} from './chart.actions';

import produce from 'immer';
import { paymentStatusTypes } from '../../types/paymentStatusTypes';

// DEBUG DATA
//const mockChartNotes = require('./mockChartNotes.json');
//const mockChartTemplates = require('./mockChartTemplates.json');
// const mockPreviousVisits = require('./mockPreviousVisits.json');
//const mockChartImages = require('./mockChartImages.json');

const initialState = {
  noteTypes: [],
  isNoteTypesLoading: false,
  previousVisits: [],
  isLoading: false,
  currentChartVisit: null,
  notes: []
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case ADD_CHART_NOTE_REQUEST:
      // draft.isLoading = true;
      break;

    case ADD_CHART_NOTE_SUCCESS:
      let note = action.payload;
      note.id = action.response.id;
      note.providerId = action.actionMetadata.providerId;
      // draft.currentChartVisit.notes.push(note);
      // draft.isLoading = false;
      break;

    case ADD_CHART_NOTE_FAILURE:
      // draft.isLoading = false;
      break;

    case DELETE_CHART_NOTE_REQUEST:
      // draft.isLoading = true;
      break;

    case DELETE_CHART_NOTE_SUCCESS:
      // let index = draft.currentChartVisit.notes.findIndex(note => note.id === action.actionMetadata.noteId);
      // draft.currentChartVisit.notes.splice(index, 1);
      // draft.isLoading = false;
      break;

    case DELETE_CHART_NOTE_FAILURE:
      // draft.isLoading = false;
      break;

    case GET_CHART_NOTES_REQUEST:
      // draft.isLoading = true;
      break;

    case GET_CHART_NOTES_SUCCESS:
      // draft.isLoading = false;
      draft.notes = action.response.data;
      break;

    case GET_CHART_NOTES_FAILURE:
      // draft.isLoading = false;
      break;

    case UPDATE_VISIT_REQUEST:
      draft.isLoading = true;
      break;

    case UPDATE_VISIT_SUCCESS:
      draft.isLoading = false;
      break;

    case UPDATE_VISIT_FAILURE:
      draft.isLoading = false;
      break;

    case GET_NOTE_TYPES_REQUEST:
      draft.isLoading = true;
      break;

    case GET_NOTE_TYPES_SUCCESS:
      draft.isNoteTypesLoading = false;
      draft.noteTypes = action.response.data;
      break;

    case GET_NOTE_TYPES_FAILURE:
      draft.isNoteTypesLoading = false;
      break;

    case GET_CHART_PREVIOUS_VISITS_REQUEST:
      // draft.isLoading = true;
      break;

    case GET_CHART_PREVIOUS_VISITS_SUCCESS:
      draft.isLoading = false;
      draft.previousVisits = action.response.data;
      break;

    case GET_CHART_PREVIOUS_VISITS_FAILURE:
      draft.isLoading = false;
      break;

    case SET_CURRENT_CHART_VISIT:
      draft.currentChartVisit = action.payload.visit;
      break;

    case CLEAR_CURRENT_CHART_VISIT:
      draft.currentChartVisit = null;
      break;

    case CLEAR_CHART_PREVIOUS_VISITS:
      draft.previousVisits = [];
      break;

    case UPDATE_ADDENDUMS_REQUEST:
      //draft.isLoading = true;
      break;

    case UPDATE_ADDENDUMS_SUCCESS:
      //draft.isLoading = false;
      break;

    case UPDATE_ADDENDUMS_FAILURE:
      //draft.isLoading = false;
      break;

    case SET_CONFIRM_CHARGES:
      if (!isNil(draft.currentChartVisit) && draft.currentChartVisit.id === action.payload.visitId) {
        draft.currentChartVisit.pricing.status = paymentStatusTypes.COMPLETE;
      }
      break;

    default:
      break;
  }
}, initialState);
