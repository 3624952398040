export const providerGroupPermissions = {
  createVisit: 'create:visit', //Start patient visit ( Provider App )
  readVisit: 'read:visit', //View patient visit ( Provider App )
  updateVisit: 'update:visit', //Edit patient visit ( Provider App )
  createPatientMessage: 'create:patient_message', //Message patient ( Provider App )
  readDx: 'read:dx', //View patient diagnosis ( Provider App )
  updateDx: 'update:dx', //Edit patient diagnosis ( Provider App )
  readMHx: 'read:mhx', //View patient medical history ( Provider App )
  updateMHx: 'update:mhx', //Edit patient medical history ( Provider App )
  readSHx: 'read:shx', //View patient surgical history ( Provider App )
  updateSHx: 'update:shx', //Edit patient surgical history ( Provider App )
  readMedication: 'read:medication', //View patient medications ( Provider App )
  updateMedication: 'update:medication', //Edit patient medications ( Provider App )
  readAllergy: 'read:allergy', //View patient allergies ( Provider App )
  updateAllergy: 'update:allergy', //Edit patient allergies ( Provider App )
  createPatientImage: 'create:patient_image', //Create patient image from video still ( Provider App )
  readPatientImage: 'read:patient_image', //View patient images ( Provider App )
  createNote: 'create:note', //Create new notes on your own visits ( Provider App )
  readNote: 'read:note', //View visit notes and addendums ( Provider App )
  updateNote: 'update:note', //Edit notes on your own visits ( Provider App )
  deleteNote: 'delete:note', //Delete a note on your own visits
  updateNotePatientVisibility: 'update:note_patient_visiblity', //Make your notes visible to patient ( Provider App )
  createAddendum: 'create:addendum', //Create new notes on other's visits ( Provider App )
  updateAddendum: 'update:addendum', //Edit an unsigned addendment ( Provider App )
  updatePrescription: 'update:prescription', //Prescribe ( Provider App )
  readPharmacy: 'read:pharmacy', //View pharmacy ( Provider App )
  updatePharmacy: 'update:pharmacy', //Edit pharmacy ( Provider App )
  createSuggestedPaymentChange: 'create:suggested_payment_change' //Suggest payment changes ( Provider App )
};

export default { providerGroupPermissions };
