import React, { Component } from 'react';
import { Card, CardContent, Typography, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import logo from '../../images/login/logo-grey.png';

import { setPageTitle } from '../layout/layout.actions';
import { isMobile, browser } from '../../utilities/browserUtils';

const IOS_APP_URL = 'https://apps.apple.com/us/app/relymd/id962442972';
const ANDROID_APP_URL = 'https://play.google.com/store/apps/details?id=com.virtumedix.relymd&hl=en';

const MAX_WIDTH = '10rem';

const styles = theme => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100vh'
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  logo: {
    maxWidth: MAX_WIDTH,
    margin: theme.spacing.unit,
    marginBottom: '2rem'
  }
});

class UnsupportedBrowser extends Component {
  componentWillMount() {
    this.props.setPageTitle('Unsupported Browser');
  }

  getMessage = () => {
    return isMobile
      ? 'It looks like you are using a mobile device. Please use our mobile app.'
      : 'It looks like you are using an unsupported browser.';
  };

  goToAppStore = () => {
    window.location.href = browser.os === 'iOS' ? IOS_APP_URL : ANDROID_APP_URL;
  };

  render() {
    const { classes, theme } = this.props;

    return (
      <div className={classes.formWrapper} style={{ backgroundColor: theme.palette.primary.background }}>
        <div style={{ display: 'flex', justifyContent: 'center', width: '75vw', alignSelf: 'center' }}>
          <Card
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '50vw',
              height: '80vh',
              marginTop: '1rem',
              flex: 1
            }}
          >
            <CardContent className={classNames(classes.formContent)}>
              <img src={logo} className={classNames(classes.logo)} alt="RelyMD logo" />
              <Typography style={{ color: theme.palette.primary.darkgray, textAlign: 'center', fontSize: '1.3rem' }}>
                {this.getMessage()}
              </Typography>

              {!isMobile && (
                <Typography
                  style={{
                    color: theme.palette.primary.darkgray,
                    textAlign: 'center',
                    fontSize: '1rem',
                    marginTop: '2rem',
                    marginLeft: '1rem',
                    marginRight: '1rem',
                    maxWidth: 400
                  }}
                >
                  In order to provide video visits, RelyMD requires a compatible web browser. Unfortunately, Internet Explorer and Safari
                  don’t support this technology. We recommend Google Chrome or Firefox. Or, try our iPhone or Android Apps.
                </Typography>
              )}

              {isMobile && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.goToAppStore}
                  fullWidth={true}
                  style={{ margin: '1.5rem', width: 250 }}
                  type="submit"
                >
                  Go To App Store
                </Button>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}

UnsupportedBrowser.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  setPageTitle: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {};
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        setPageTitle
      }
    )(UnsupportedBrowser)
  )
);
