import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import SearchBox from 'react-google-maps/lib/components/places/SearchBox';
import { get, isNil } from 'lodash';

const styles = {};

const DEFAULT_MAP_CENTER = {
  // RDU
  lat: 35.88,
  lng: -78.78
};

class CustomGoogleMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bounds: null,
      center: props.selectedMarker ? get([props.selectedMarker], '0.position', DEFAULT_MAP_CENTER) : DEFAULT_MAP_CENTER,
      markers: props.selectedMarker ? [props.selectedMarker] : [],
      showInfoIndex: props.selectedMarker ? 0 : null,
      isSearching: false
    };

    this.searchRef = null;
    this.mapRef = null;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedMarker && !nextProps.allowMultiMarkers) {
      const nextCenter = get([nextProps.selectedMarker], '0.position', DEFAULT_MAP_CENTER);
      this.setState({
        markers: [nextProps.selectedMarker],
        center: nextCenter,
        showInfoIndex: nextProps.selectedMarker ? 0 : null
      });
    }
  }

  onMapMounted = ref => {
    this.mapRef = ref;
  };

  onBoundsChanged = () => {
    this.setState({
      bounds: this.mapRef.getBounds(),
      center: this.mapRef.getCenter()
    });
  };

  onSearchBoxMounted = ref => {
    this.searchRef = ref;
  };

  onPlacesChanged = () => {
    const places = this.searchRef.getPlaces();
    const bounds = new window.google.maps.LatLngBounds();

    places.forEach(place => {
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });

    const nextMarkers = places.map(place => ({
      position: place.geometry.location,
      place: place
    }));

    const nextCenter = get(nextMarkers, '0.position', this.state.center);

    this.setState({
      center: nextCenter,
      markers: nextMarkers,
      isSearching: false
    });

    // if only 1 location is returned, auto select it
    if (this.props.onUpdateLocation && places.length === 1) {
      this.handleShowMarkerDetail(0);
    } else {
      this.handleCloseMarkers(); // close markers and clear selection on search
    }
  };

  handleShowMarkerDetail = index => {
    this.setState({
      showInfoIndex: index
    });

    // set selected marker as selected location if update func provided
    if (this.props.onUpdateLocation) {
      this.props.onUpdateLocation(this.state.markers[index]);
    }
  };

  handleCloseMarkers = () => {
    this.setState({
      showInfoIndex: null
    });

    if (this.props.onDeselectLocation) {
      this.props.onDeselectLocation();
    }
  };

  // if in the process of searching, ignore enter key
  // if done searching and a location is selected, auto submit the location
  handleMapKeyPress = evt => {
    if (evt.charCode === 13 && !this.state.isSearching && this.state.showInfoIndex !== null) {
      evt.preventDefault();
      if (this.props.handleAutoSubmit) this.props.handleAutoSubmit(this.state.markers[this.state.showInfoIndex]);
    } else {
      this.setState({
        isSearching: true
      });
    }
  };

  render() {
    const { isSearchShown, mapOptions } = this.props;
    const { center, bounds, markers, showInfoIndex } = this.state;

    return (
      <GoogleMap defaultZoom={9} defaultCenter={{ lat: -34.397, lng: 150.644 }} center={center} options={mapOptions ? mapOptions : {}}>
        {markers.map((marker, index) => {
          return (
            <Marker
              key={index}
              position={marker.position}
              onClick={() => this.handleShowMarkerDetail(index)}
              icon={{
                url: `https://maps.google.com/mapfiles/ms/icons/${index === showInfoIndex ? 'blue-dot' : 'red-dot'}.png`,
                scale: 3
              }}
            >
              {showInfoIndex === index && !isNil(marker.place) && (
                <InfoWindow onCloseClick={this.handleCloseMarkers}>
                  <div>{marker.place.formatted_address}</div>
                </InfoWindow>
              )}

              {showInfoIndex === index && !isNil(marker.address) && (
                <InfoWindow onCloseClick={this.handleCloseMarkers}>
                  <div>
                    <div>{marker.address.addressLine1}</div>
                    <div>{`${marker.address.addressCity}, ${marker.address.addressState}`}</div>
                    <div>{`${marker.address.addressZip}`}</div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          );
        })}
        {isSearchShown && (
          <div onKeyPress={evt => this.handleMapKeyPress(evt)}>
            <SearchBox
              ref={this.onSearchBoxMounted}
              bounds={bounds}
              controlPosition={window.google.maps.ControlPosition.BOTTOM}
              onPlacesChanged={this.onPlacesChanged}
            >
              <input
                type="text"
                placeholder="Search..."
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  width: `400px`,
                  height: `32px`,
                  marginTop: `27px`,
                  padding: `0 12px`,
                  borderRadius: `3px`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                  marginBottom: '1rem'
                }}
              />
            </SearchBox>
          </div>
        )}
      </GoogleMap>
    );
  }
}

CustomGoogleMap.propTypes = {
  classes: PropTypes.object.isRequired,
  mapOptions: PropTypes.object
};

CustomGoogleMap = withScriptjs(withGoogleMap(CustomGoogleMap));

export default withStyles(styles)(CustomGoogleMap);
