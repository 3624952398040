import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { arrowGenerator } from './arrowGenerator';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const styles = theme => ({
  htmlPopper: {
    ...arrowGenerator(theme.palette.primary.white),
    opacity: 1
  },
  htmlTooltip: {
    backgroundColor: theme.palette.primary.white,
    width: 220,
    border: '1px solid #dadde9',
    '& b': {
      fontWeight: theme.typography.fontWeightMedium
    }
  },
  arrow: {
    position: 'absolute',
    fontSize: 14, // adjusts size of arrow
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  },
  tooltipContainer: {
    display: 'inline-block',
    cursor: 'pointer'
  }
});

class CustomTooltip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      arrowRef: null,
      open: false
    };
  }

  handleArrowRef = node => {
    this.setState({
      arrowRef: node
    });
  };

  handleTooltipClose = () => {
    this.setState({ open: false });
  };

  toggleTooltip = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    let { classes, children, content } = this.props;

    return (
      <div className={classes.tooltipContainer}>
        <ClickAwayListener onClickAway={this.handleTooltipClose}>
          <Tooltip
            classes={{
              popper: classes.htmlPopper,
              tooltip: classes.htmlTooltip
            }}
            PopperProps={{
              popperOptions: {
                modifiers: {
                  arrow: {
                    enabled: Boolean(this.state.arrowRef),
                    element: this.state.arrowRef
                  }
                }
              }
            }}
            title={
              <div>
                {content}
                <span className={classes.arrow} ref={this.handleArrowRef} />
              </div>
            }
            onClick={this.toggleTooltip}
            open={this.state.open}
            interactive={true}
          >
            {children}
          </Tooltip>
        </ClickAwayListener>
      </div>
    );
  }
}

CustomTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default withStyles(styles)(CustomTooltip);
