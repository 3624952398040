import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { setPageTitle as setPageTitleAction } from '../layout/layout.actions';
import MiniBoard from '../board/miniBoard.component';
import ChartDetails from './chartDetails.component';
import { getBoardItems as getBoardItemsAction } from '../board/board.actions';
import { visitStatusTypes } from '../../types/visitStatusTypes';

class ChartContainer extends Component {
  componentDidMount() {
    const { getBoardItems, setPageTitle } = this.props;

    setPageTitle('Previous');
    getBoardItems();
  }

  render() {
    const { classes, boardItems } = this.props;

    // show only NEW, SELECTED, READY
    const filteredBoardItems = boardItems.filter(
      item =>
        item.state === visitStatusTypes.NEW ||
        item.state === visitStatusTypes.ASSIGNED ||
        item.state === visitStatusTypes.SELECTED ||
        item.state === visitStatusTypes.READY
    );

    return (
      <>
        <Grid container className={classes.fullHeight}>
          <Grid item xs={12} md={4} className={classes.fullHeight}>
            <MiniBoard boardItems={filteredBoardItems} />
          </Grid>
          <Grid item xs={12} md={8} className={classes.fullHeight}>
            <ChartDetails />
          </Grid>
        </Grid>
      </>
    );
  }
}

const styles = (/* theme */) => ({
  fullHeight: {
    height: '100%',
    overflow: 'auto',

    '@media (max-width: 960px)': {
      height: 'auto',
    },
  },
});

ChartContainer.propTypes = {
  classes: PropTypes.object.isRequired,

  boardItems: PropTypes.array.isRequired,

  getBoardItems: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    boardItems: state.board.boardItems,
  };
};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, {
    getBoardItems: getBoardItemsAction,
    setPageTitle: setPageTitleAction,
  })(ChartContainer)
);
