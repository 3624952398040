import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, TextField, FormControl, Select, MenuItem, InputLabel, OutlinedInput } from '@material-ui/core';
import { omitBy, isEmpty } from 'lodash';
import { getUSStateOptions } from '../../utilities/provinceUtils';
import NumberFormat from 'react-number-format';

const spinner = require('../../images/icons/spinner.gif');

const initialState = {
  name: '',
  city: '',
  state: '',
  zip: '',
  address: '',
  phone: ''
};

const PhoneNumberFormat = props => {
  const { inputRef, name, onChange, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      format={'(###) ###-####'}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.value
          }
        });
      }}
    />
  );
};

const ZipNumberFormat = props => {
  const { inputRef, name, onChange, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      format={'#####'}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.value
          }
        });
      }}
    />
  );
};

class PharmacySearchForm extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState(prevState => ({ ...prevState, [name]: value }));
  };

  handlePharmacySearch = () => {
    const criteria = omitBy({ ...this.state }, isEmpty);
    this.props.handlePharmacySearch(criteria);
  };

  handleFormReset = () => {
    this.setState(initialState);
  };

  // TODO: check for valid pharmacy search criteria
  formHasErrors = () => {
    return Object.keys(omitBy({ ...this.state }, isEmpty)).length <= 0;
  };

  render() {
    let { classes, theme, isLoading } = this.props;
    let { name, city, state, zip, address, phone } = this.state;

    const textInputStyle = {
      fontSize: '1.1rem',
      color: theme.palette.primary.darkgray,
      width: '100%'
    };

    const isSearchDisabled = this.formHasErrors();

    return (
      <form>
        <Grid container>
          <Grid item xs={12} md={4} className={classes.gridItem}>
            <TextField
              id="pharmacyName"
              label="Pharmacy Name"
              value={name}
              name="name"
              onChange={this.handleChange}
              variant="outlined"
              InputProps={{
                style: textInputStyle,
                autoComplete: 'no' // use an invalid string to thwart browser autofill
              }}
              className={classes.formControl}
            />
          </Grid>

          <Grid item xs={12} md={4} className={classes.gridItem}>
            <TextField
              id="pharmacyAddress"
              label="Address"
              value={address}
              name="address"
              onChange={this.handleChange}
              variant="outlined"
              InputProps={{
                style: textInputStyle,
                autoComplete: 'no' // use an invalid string to thwart browser autofill
              }}
              className={classes.formControl}
            />
          </Grid>

          <Grid item xs={12} md={4} className={classes.gridItem}>
            <TextField
              id="pharmacyCity"
              label="City"
              value={city}
              name="city"
              onChange={this.handleChange}
              variant="outlined"
              InputProps={{
                style: textInputStyle,
                autoComplete: 'no' // use an invalid string to thwart browser autofill
              }}
              className={classes.formControl}
            />
          </Grid>

          <Grid item xs={12} md={4} className={classes.gridItem}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-state-simple">State</InputLabel>
              <Select
                value={state}
                onChange={this.handleChange}
                input={<OutlinedInput labelWidth={35} name="state" id="outlined-state-simple" style={textInputStyle} />}
              >
                <MenuItem value="" />
                {getUSStateOptions().map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4} className={classes.gridItem}>
            <TextField
              className={classes.formControl}
              id="pharmacyZip"
              label="Zip Code"
              name="zip"
              value={zip}
              variant="outlined"
              InputProps={{
                inputComponent: ZipNumberFormat,
                style: textInputStyle,
                autoComplete: 'no' // use an invalid string to thwart browser autofill
              }}
              onChange={this.handleChange}
            />
          </Grid>

          <Grid item xs={12} md={4} className={classes.gridItem}>
            <TextField
              id="pharmacyPhone"
              className={classes.formControl}
              label="Phone"
              name="phone"
              value={phone}
              variant="outlined"
              InputProps={{
                inputComponent: PhoneNumberFormat,
                style: textInputStyle,
                autoComplete: 'no' // use an invalid string to thwart browser autofill
              }}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>
        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
          {!isLoading && (
            <Button style={{ marginRight: '1rem' }} onClick={this.handleFormReset} variant={'text'} disableRipple>
              Reset
            </Button>
          )}
          {isLoading && (
            <div style={{ marginRight: '2rem' }}>
              <img src={spinner} alt={''} />
            </div>
          )}
          {!isLoading && (
            <Button
              onClick={this.handlePharmacySearch}
              variant="outlined"
              className={isSearchDisabled ? classes.buttonDisabled : classes.button}
              disableRipple
              disabled={isSearchDisabled}
              type="submit"
            >
              Search
            </Button>
          )}
        </div>
      </form>
    );
  }
}

const styles = theme => ({
  formControl: {
    width: '100%'
  },
  gridItem: {
    marginBottom: '1rem',
    paddingRight: '1rem'
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white
    }
  },
  buttonDisabled: {
    backgroundColor: theme.palette.primary.lightgray,
    '&:hover': {
      backgroundColor: theme.palette.secondary.lightgray
    }
  }
});

PharmacySearchForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handlePharmacySearch: PropTypes.func.isRequired,
  isSearching: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(PharmacySearchForm);
