import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Paper } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import DataTableAdvancedSearchForm from './dataTableAdvancedSearchForm.component';

const toolbarStyles = theme => ({
  root: {
    display: 'block',
    padding: '0.5rem'
  },
  closeButton: {
    padding: 0,
    color: theme.palette.primary.main
  },
  title: {
    padding: '1rem',
    display: 'flex',
    flex: '1 1 auto',
    color: theme.palette.primary.main
  },
  titleRight: {
    flex: '1 1 auto',
    textAlign: 'right'
  },
  tableTitle: {
    color: theme.palette.primary.main
  }
});

class DataTableAdvancedSearchToolbar extends Component {
  render() {
    const { classes, handleClose, advancedSearchData, numberOfRows, handleSearch } = this.props;

    return (
      <Toolbar className={classNames(classes.root)}>
        <Paper style={{ padding: '1rem' }}>
          <div className={classes.title}>
            <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
              {`All Visits - ${numberOfRows} Results`}
            </Typography>
            <div className={classes.titleRight}>
              <IconButton onClick={handleClose} classes={{ root: classes.closeButton }}>
                <Close />
              </IconButton>
            </div>
          </div>
          <DataTableAdvancedSearchForm
            data={advancedSearchData}
            handleSearch={handleSearch}
            handleClose={handleClose}
            isToolbarView={true}
          />
        </Paper>
      </Toolbar>
    );
  }
}

DataTableAdvancedSearchToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
  numberOfRows: PropTypes.number,
  advancedSearchData: PropTypes.object.isRequired
};

DataTableAdvancedSearchToolbar = withStyles(toolbarStyles)(DataTableAdvancedSearchToolbar);

export default DataTableAdvancedSearchToolbar;
