import {
  GET_PROVIDER_REQUEST,
  GET_PROVIDER_SUCCESS,
  GET_PROVIDER_FAILURE,
  UPDATE_AVAILABILITY_SUCCESS,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  SET_AVAILABILITY_SUCCESS
} from './provider.actions';
import produce from 'immer';

const initialState = {
  provider: {},
  profile: null,
  providerId: null,
  isLoading: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_PROVIDER_REQUEST:
      draft.isLoading = true;
      break;

    case GET_PROVIDER_SUCCESS:
      draft.isLoading = false;
      draft.provider = action.response;
      break;

    case GET_PROVIDER_FAILURE:
      draft.isLoading = false;
      break;

    case GET_PROFILE_REQUEST:
      draft.isLoading = true;
      break;

    case GET_PROFILE_SUCCESS:
      draft.isLoading = false;
      draft.profile = action.response;
      draft.providerId = action.response.id;
      break;

    case GET_PROFILE_FAILURE:
      draft.isLoading = false;
      break;

    case UPDATE_AVAILABILITY_SUCCESS:
      draft.profile.availability = action.payload.availability;
      draft.profile.notificationThreshold = action.payload.notificationThreshold;
      break;

    case SET_AVAILABILITY_SUCCESS:
      draft.profile.availability = action.payload.value;
      draft.profile.notificationThreshold = action.payload.notificationThreshold;
      break;

    default:
      break;
  }
}, initialState);
