import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Button,
  TextField,
  Paper,
  MenuItem,
  OutlinedInput,
  FormLabel,
  Select,
  Tooltip, Icon,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { cloneDeep, has, isNil, isEmpty } from 'lodash';
import moment, { isMoment } from 'moment';
import ReactRouterPropTypes from 'react-router-prop-types';
import ChartPatientHistoryForm from './chartPatientHistoryForm.component';
import ChartPatientPhotos from './chartPatientPhotos.component';
import ChartPatientLocation from './chartPatientLocation.component';
import ChartPatientPreviousVisits from './chartPatientPreviousVisits.component';
import ChartPatientNotes from './chartPatientNotes.component';
import ChartPatientPrescriptions from './chartPatientPrescriptions.component';
import ChartPatientInsurance from './chartPatientInsurance.component';
import ChartPatientAddendums from './chartPatientAddendums.component';
import ReactSelectField from '../../common/reactSelectField/reactSelectField.component';
import PharmacySelectionModal from '../pharmacy/pharmacySelectionModal.component';
import RxModal from '../pharmacy/rxModal.component';
import CustomTooltip from '../../common/customTooltip/customTooltip.component';
import ChartDemographicsTooltip from './chartDemographicsTooltip.component';
import { nihApiTypes } from '../../types/nihApiTypes';
import { fetchData, GET_API_DATA_SUCCESS } from '../../common/api/api.actions';
import { getProxyApiPath, mapNihDataToSelectOptions, mapJsonArrayToSelectOptions, mapOutboundNihData } from '../../utilities/nihApiUtils';
import { formatInsuranceAttachments, createInsurancePayload } from '../../utilities/insuranceUtils';
import {
  updateVisit,
  UPDATE_VISIT_SUCCESS,
  addChartNote,
  deleteChartNote,
  signVisit,
  ADD_CHART_NOTE_SUCCESS,
  DELETE_CHART_NOTE_SUCCESS,
  updateAddendums,
  UPDATE_ADDENDUMS_SUCCESS,
  REGEN_CHART_DOC_PASSWORD_SUCCESS,
  regenChartDocumentPassword,
  getChartPreviousVisits,
  getPreviousVisit,
  GET_PREVIOUS_VISIT_SUCCESS,
  setCurrentChartVisit,
  clearCurrentChartVisit,
  clearPreviousVisits,
} from './chart.actions';
import CustomDialog from '../../common/customDialog/customDialog.component';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import { showToast, clearLayoutBeforeUnload, setLayoutBeforeUnload, showConfirmChargesModal } from '../layout/layout.actions';
import {
  getPharmacy,
  clearPharmacy,
  getDoseSpotUrl,
  GET_DOSESPOT_URL_SUCCESS,
  refreshVisitPrescriptions, REFRESH_VISIT_PRESCRIPTIONS_SUCCESS,
} from '../pharmacy/pharmacy.actions';
import ChartPharmacyCard from './chartPharmacyCard.component';
import { can } from '../login/can';
import { providerGroupPermissions } from '../../types/permissionTypes';
import { logEvent } from '../../utilities/googleAnalytics';
import { visitStatusTypes } from '../../types/visitStatusTypes';
import { paymentStatusTypes } from '../../types/paymentStatusTypes';
import Colors from '../../styles/colors';
import ChartFamilyHistoryFormComponent from './chartFamilyHistoryForm.component';
import { getAgeString } from '../../utilities/dataUtils';
import { getLongGender } from '../../types/genderTypes';
import { capitalize } from 'lodash/string';
import { getUSStateOptions } from '../../utilities/provinceUtils';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';

const spinner = require('../../images/icons/spinner.gif');

class ChartDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visit: null,
      isShowSaveConfirmDialog: false,
      isShowSignConfirmDialog: false,
      isShowPharmacySelectModal: false,
      isDirty: false,
      originalNotes: [],
      originalAddendums: [],
      isShowRxModal: false,
      doseSpotUrl: '',
      refreshingPrescriptions: false,
    };

    this.disableSavingRef = React.createRef();
    this.disableSavingRef.current = false;

    // Used to hide the loading indicator when doing specific auto-save.
    this.disableSaveIndicatorRef = React.createRef();
    this.disableSaveIndicatorRef.current = false;
  }

  componentDidMount() {
    // init previous visits and chart data
    this.initChartData();

    // sets up a tab close listener
    this.props.setLayoutBeforeUnload(this.handleUnload);
  }

  componentWillReceiveProps(nextProps) {
    let originalNotes;
    let originalAddendums;

    if (has(nextProps, 'currentChartVisit.notes') && has(nextProps, 'currentChartVisit.addendums')) {
      // track original notes to revert to response data
      originalNotes = cloneDeep(nextProps.currentChartVisit.notes);
      originalAddendums = cloneDeep(nextProps.currentChartVisit.addendums);
    }

    const updateState = {
      originalNotes: originalNotes || [],
      originalAddendums: originalAddendums || [],
    };

    // only update visit state if loading a new visit
    // prevents issue where visit state is reset inadvertently after pharmacy selection or other prop-inducing action
    if (isNil(this.state.visit) || isNil(nextProps.currentChartVisit) || nextProps.currentChartVisit.id !== this.state.visit.id) {
      updateState.visit = nextProps.currentChartVisit;

      if (!isNil(updateState.visit) && !isEmpty(updateState.visit.insurance)) {
        updateState.visit.insurance = {
          ...nextProps.currentChartVisit.insurance,
          attachments: formatInsuranceAttachments(nextProps.currentChartVisit.insurance),
        };
      }
    }

    // update pricing status if needed
    if (has(nextProps, 'currentChartVisit.pricing.status') && has(this.state.visit, 'pricing.status')) {
      if (this.state.visit.pricing.status !== nextProps.currentChartVisit.pricing.status) {
        const updatedVisit = { ...this.state.visit };
        updatedVisit.pricing.status = nextProps.currentChartVisit.pricing.status;
        updateState.visit = updatedVisit;
      }
    }

    this.setState(updateState);
  }

  async componentWillUnmount() {
    // clear tab close event listener
    await this.props.clearLayoutBeforeUnload();

    // save when navigating away from chart
    if (this.state.isDirty && this.disableSavingRef.current !== true) {
      const { visitId } = this.props.match.params;

      const response = await this.props.getPreviousVisit(visitId);

      // Check the visit and see if the visit has been updated
      // and is now back "checked-in". Don't try to save the
      // visit if it is.
      if (response.type === GET_PREVIOUS_VISIT_SUCCESS) {
        const { state, providerId } = response.response;

        if (state === 'new' && !providerId) {
          // Do nothing
        } else if (this.state.visit.providerId === this.props.providerId) {
          await this.handleSaveVisit(null, true);
        } else {
          await this.handleSaveAddendums();
        }
      }
    }
  }

  handleUnload = evt => {
    evt.preventDefault();

    if (this.state.isDirty) {
      evt.returnValue = 'You have unsaved changes. Do you really want to close the application?';
      return evt;
    }
  };

  initChartData = async () => {
    // clear previous chart data
    this.props.clearCurrentChartVisit();
    this.props.clearPreviousVisits();
    this.props.clearPharmacy();

    const { visitId } = this.props.match.params;
    await this.props.getPharmacy(visitId);

    const response = await this.props.getPreviousVisit(visitId);
    if (response.type === GET_PREVIOUS_VISIT_SUCCESS) {
      this.props.setCurrentChartVisit(response.response);
      this.props.getChartPreviousVisits(response.response.patientId);
    } else {
      this.props.history.push('/previous');
    }
  };

  handleSelectFieldChange = async (fieldName, value) => {
    const updateValues = {};
    updateValues[fieldName] = value;

    this.setState(prevState => ({
      visit: {
        ...prevState.visit,
        ...updateValues,
      },
      isDirty: true,
    }), async () => {
      if (['diagnosis', 'verbalConsentStatus', 'weight', 'heightFeet', 'heightInches'].includes(fieldName)) {
        this.disableSaveIndicatorRef.current = true;
        await this.handleSaveVisit(null, true);
        this.disableSaveIndicatorRef.current = false;
      }
    });

    if (fieldName === 'diagnosis') {
      logEvent('Add_Dx', {
        providerId: this.props.providerId,
      });
    }
  };

  handleFetchData = type => async query => {
    console.log('type', type);
    console.log('query', query);
    const response = await this.props.fetchData(`v1/provider/diagnosis/${query}`);
    if (response.type === GET_API_DATA_SUCCESS) {
      console.log('response', response.response);
      return mapNihDataToSelectOptions(type, response.response);
    }
  };

  // keep track of form data in local state
  handleFormUpdate = data => {
    this.setState(prevState => ({
      visit: {
        ...prevState.visit,
        ...data,
      },
      isDirty: true,
    }));
  };

  handleNotesUpdate = notes => {
    this.setState(prevState => ({
      visit: {
        ...prevState.visit,
        notes,
      },
      isDirty: true,
    }));
  };

  handleAddendumsUpdate = addendums => {
    this.setState(prevState => ({
      visit: {
        ...prevState.visit,
        addendums,
      },
      isDirty: true,
    }));
  };

  handleSaveVisit = async (evt, isAutoSave) => {
    // copy from state
    const visit = cloneDeep(this.state.visit);

    this.handleCloseSaveConfirmDialog();

    // preserve state as isLoading fires componentWillReceiveProps
    this.props.setCurrentChartVisit(cloneDeep(visit));

    if (visit) {
      const { id } = visit;

      // provide empty array if null or undefined
      if (visit.prescriptions === null || visit.prescriptions === undefined) visit.prescriptions = [];
      if (visit.notes === null || visit.notes === undefined) visit.notes = [];
      if (visit.addendums === null || visit.addendums === undefined) visit.addendums = [];

      visit.notes.forEach(note => {
        // if the note body was modified we need to convert it from a rich text object to a string before saving
        if (typeof note.body !== 'string') {
          note.body = JSON.stringify(note.body.toJSON());
        }
        delete note.providerId;
        delete note.signedDate;
        delete note.providerName;
        delete note.isDirty;
        delete note.created;
        delete note.type;
        delete note.visibleToPatient;
        delete note.noteExportType;
      });

      visit.addendums.forEach(addendum => {
        delete addendum.type;
      });

      // map visit fields
      const nihSelectFields = ['medicalHistory', 'surgicalHistory', 'medications', 'allergies', 'diagnosis', 'familyMedicalHistory'];
      nihSelectFields.forEach(field => {
        visit[field] = visit[field] ? mapOutboundNihData(visit[field]) : [];
      });

      if (!isEmpty(visit.insurance)) {
        visit.insurance = createInsurancePayload(visit.insurance);
      }

      const deleteFields = [
        'id',
        // 'chiefComplaint',
        // 'firstName',
        // 'lastName',
        // 'gender',
        // 'weight',
        // 'mobileNumber',
        'visitId',
        'signedDate',
        'updated',
        'providerId',
        'providerName',
        'patientId',
        'created',
        'state',
        'patientPhotos',
        'signDate',
        'hasUnsignedChanges',
        'addendums',
        // 'dob',
        'entranceId',
        'entranceName',
        'pricing',
        'facility',
        'password',
        'isUnreached',
      ];
      deleteFields.forEach(field => delete visit[field]);

      const response = await this.props.updateVisit(id, visit);
      if (response.type !== UPDATE_VISIT_SUCCESS) {
        this.props.showToast('Could not save visit. Please check your connection and try again.');
        return { success: false };
      }
      if (!this.props.isInVisit && !isAutoSave) {
        // reset dirty after save if not automatic save
        this.setState(
          {
            isDirty: false, // prevents save on componentWillUnmount
          },
          () => {
            // redirect on success
            this.props.history.push('/board');
          }
        );
      } else if (this.props.isInVisit && !isAutoSave) {
        // no redirect if in visit
        this.setState({
          isDirty: false,
        });
        this.initChartData();
      } else {
        this.setState({
          isDirty: false,
        });
      }

      return { success: true, notes: response.response.notes };
    }
  };

  handleSignVisit = async () => {
    // dismiss dialog immediately
    this.handleCloseSignConfirmDialog();

    logEvent('sign_visit', {
      providerId: this.props.providerId,
    });

    // save before signing
    let saveResult;
    let notesToSign = [];

    if (this.state.isDirty) {
      // handle as auto-save, as we will redirect after sign
      saveResult = await this.handleSaveVisit(null, true);
      if (!saveResult || !saveResult.success) {
        this.props.showToast('Failed to save visit before signing. Please check your connection and try again.');
        return;
      }
      // successfully saved, get note list from response
      notesToSign = has(saveResult, 'notes') ? saveResult.notes : [];
    } else {
      // no save performed, sign list of notes and addendums from current state
      const { notes, addendums } = this.state.visit;
      notesToSign = notes.concat(addendums);
    }

    const postBody = {
      notes: notesToSign.map(n => n.id),
    };

    const response = await this.props.signVisit(this.state.visit.id, postBody);
    if (response.type !== UPDATE_VISIT_SUCCESS) {
      console.log(response);
      const message = response.messages[0] ? response.messages[0] : 'Could not sign visit. ..Please reload the visit and try again.';
      this.props.showToast(message, null, {
        handleClick: this.initChartData,
        text: 'RELOAD',
      });
    } else if (!this.props.isInVisit) {
      this.disableSavingRef.current = true;
      this.props.history.push('/board');
    }
  };

  handleSaveAddendums = async () => {
    const { addendums, id } = cloneDeep(this.state.visit);

    const providerAddendums = addendums.filter(addendum => addendum.providerId === this.props.providerId);
    providerAddendums.forEach(addendum => {
      // if the note body was modified we need to convert it from a rich text object to a string before saving
      if (typeof addendum.body !== 'string') {
        addendum.body = JSON.stringify(addendum.body.toJSON());
      }
      delete addendum.providerId;
      delete addendum.signedDate;
      delete addendum.providerName;
      delete addendum.isDirty;
      delete addendum.created;
      delete addendum.type;
    });

    const putBody = {
      notes: providerAddendums,
    };

    // dismiss dialog immediately
    this.handleCloseSignConfirmDialog();

    const response = await this.props.updateAddendums(id, putBody);
    if (response.type !== UPDATE_ADDENDUMS_SUCCESS) {
      this.props.showToast('Could not save addendum(s). Please check your connection and try again.');
    } else if (!this.props.isInVisit) {
      this.props.history.push('/board');
    }
  };

  handleSignAddendums = async () => {
    const { addendums, id } = this.state.visit;
    const providerAddendums = addendums.filter(addendum => addendum.providerId === this.props.providerId);
    providerAddendums.forEach(addendum => {
      // if the note body was modified we need to convert it from a rich text object to a string before saving
      if (typeof addendum.body !== 'string') {
        addendum.body = JSON.stringify(addendum.body.toJSON());
      }
      delete addendum.providerId;
      delete addendum.signedDate;
      delete addendum.providerName;
      delete addendum.isDirty;
      delete addendum.created;
      delete addendum.type;
    });

    const putBody = {
      notes: providerAddendums,
      sign: true,
    };

    // dismiss dialog immediately
    this.handleCloseSignConfirmDialog();

    const response = await this.props.updateAddendums(id, putBody);
    if (response.type !== UPDATE_ADDENDUMS_SUCCESS) {
      this.props.showToast('Could not sign addendum(s). Please check your connection and try again.');
    } else if (!this.props.isInVisit) {
      this.props.history.push('/board');
    }
  };

  handleShowSaveConfirmDialog = () => {
    this.setState({
      isShowSaveConfirmDialog: true,
    });
  };

  handleShowSignConfirmDialog = () => {
    this.setState({
      isShowSignConfirmDialog: true,
    });
  };

  handleCloseSaveConfirmDialog = () => {
    this.setState({
      isShowSaveConfirmDialog: false,
    });
  };

  handleCloseSignConfirmDialog = () => {
    this.setState({
      isShowSignConfirmDialog: false,
    });
  };

  handleShowPharmacySelectModal = () => {
    this.setState({
      isShowPharmacySelectModal: true,
    });
  };

  handleClosePharmacySelectModal = () => {
    this.setState({
      isShowPharmacySelectModal: false,
    });
  };

  addChartNote = async (visitId, note, metadata) => {
    const response = await this.props.addChartNote(visitId, note, metadata);
    if (response.type === ADD_CHART_NOTE_SUCCESS) {
      const updateNotes = [].concat(this.state.visit.notes);
      updateNotes.push(note);

      this.setState(prevState => ({
        visit: {
          ...prevState.visit,
          notes: updateNotes,
        },
      }));

      return { type: ADD_CHART_NOTE_SUCCESS, id: response.response.id };
    }
  };

  addChartAddendum = async (visitId, note, metadata) => {
    const response = await this.props.addChartNote(visitId, note, metadata);
    if (response.type === ADD_CHART_NOTE_SUCCESS) {
      const updatedAddendums = [].concat(this.state.visit.addendums);
      updatedAddendums.push(note);

      this.setState(prevState => ({
        visit: {
          ...prevState.visit,
          addendums: updatedAddendums,
        },
      }));

      return { type: ADD_CHART_NOTE_SUCCESS, id: response.response.id };
    }
  };

  deleteChartNote = async (visitId, noteId) => {
    const response = await this.props.deleteChartNote(visitId, noteId);

    if (response.type === DELETE_CHART_NOTE_SUCCESS) {
      const updateNotes = [].concat(this.state.visit.notes);
      const foundNoteIndex = updateNotes.findIndex(n => n.id === noteId);

      if (foundNoteIndex > -1) {
        updateNotes.splice(foundNoteIndex, 1);
      }

      this.setState(prevState => ({
        visit: {
          ...prevState.visit,
          notes: updateNotes,
        },
      }));
    }
  };

  handleDiscardNoteChanges = id => {
    const originalNotes = [...this.state.originalNotes];
    const originalIndex = originalNotes.findIndex(note => note.id === id);

    const updateNotes = [...this.state.visit.notes];
    const updateIndex = updateNotes.findIndex(note => note.id === id);

    const updateNote = cloneDeep(originalNotes[originalIndex]);

    updateNotes.splice(updateIndex, 1, updateNote);

    this.setState(prevState => ({
      visit: {
        ...prevState.visit,
        notes: updateNotes,
      },
    }));
  };

  handleDiscardAddendumChanges = id => {
    const originalAddendums = [...this.state.originalAddendums];
    const originalIndex = originalAddendums.findIndex(a => a.id === id);

    const updatedAddendums = [...this.state.visit.addendums];
    const updateIndex = updatedAddendums.findIndex(a => a.id === id);

    const updateAddendum = cloneDeep(originalAddendums[originalIndex]);

    updatedAddendums.splice(updateIndex, 1, updateAddendum);

    this.setState(prevState => ({
      visit: {
        ...prevState.visit,
        addendums: updatedAddendums,
      },
    }));
  };

  deleteChartAddendum = async (visitId, noteId) => {
    const response = await this.props.deleteChartNote(visitId, noteId);

    if (response.type === DELETE_CHART_NOTE_SUCCESS) {
      const updatedAddendums = [].concat(this.state.visit.addendums);
      const foundAddendumIndex = updatedAddendums.findIndex(a => a.id === noteId);
      if (foundAddendumIndex > -1) {
        updatedAddendums.splice(foundAddendumIndex, 1);
      }
      this.setState(prevState => ({
        visit: {
          ...prevState.visit,
          addendums: updatedAddendums,
        },
      }));
    }
  };

  onPharmacySelect = async () => {
    const { visitId } = this.props.match.params;
    this.handleClosePharmacySelectModal();
    await this.props.getPharmacy(visitId);
  };

  // save current chart to ensure data is sent to dosespot
  // request iframe url
  // show iframe modal with cancel button
  handleRx = async () => {
    logEvent('Add_Rx', {
      providerId: this.props.providerId,
    });

    await this.handleSaveVisit(null, true);

    const result = await this.props.getDoseSpotUrl(this.state.visit.id);
    if (result.type === GET_DOSESPOT_URL_SUCCESS && has(result, 'response.url')) {
      this.setState({
        isShowRxModal: true,
        doseSpotUrl: result.response.url,
      });
    } else {
      this.props.showToast('There was an error starting a prescription. Please check your connection and try again.');
    }
  };

  // generate new doc sharing password for facilitated visit and save in chart visit state
  regenDocSharePassword = async () => {
    if (window.confirm('This will create a new word that will need to be shared with the visit facilitator. Are you sure?')) {
      const result = await this.props.regenChartDocumentPassword(this.state.visit.id);

      if (result.type === REGEN_CHART_DOC_PASSWORD_SUCCESS) {
        this.setState(prevState => ({
          visit: {
            ...prevState.visit,
            password: result.response.password,
          },
        }));
      } else {
        this.props.showToast('There was an error regenerating the document sharing password. Please check your connection and try again.');
      }
    }
  };

  handleRefreshPrescriptions = async () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        refreshingPrescriptions: true,
      };
    });

    const result = await this.props.refreshVisitPrescriptions(this.state.visit.id);

    if (result.type === REFRESH_VISIT_PRESCRIPTIONS_SUCCESS) {
      this.setState((prevState) => {
        return {
          ...prevState,
          visit: {
            ...prevState.visit,
            prescriptions: result.response.prescriptions,
          },
          refreshingPrescriptions: false,
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          ...prevState,
          refreshingPrescriptions: false,
        };
      });
    }
  }

  handleCloseRxModal = async () => {
    this.setState({
      isShowRxModal: false,
    });

    await this.handleRefreshPrescriptions();
  };

  // filter note types based on provider group membership
  filterNoteTypes = () => {
    const { entranceId } = this.state.visit;
    const { profile, noteTypes } = this.props;

    // get allowed provider groups by finding the visit entrance id in any of the provider's profile groups
    const allowedProviderGroups = profile.groups.reduce((result, pg) => {
      if ((pg.entrances || []).includes(entranceId)) result.push(pg.id);
      return result;
    }, []);

    // filter noteTypes list by finding an allowed provider group within type.providerGroupIds[]
    return (noteTypes || []).filter(nt => (nt.providerGroupIds || []).some(pgid => (allowedProviderGroups || []).includes(pgid)));
  };

  // update insurance state from insurance detail component
  handleUpdateInsuranceField = name => data => {
    let value = data;

    if (isMoment(data)) {
      value = data.format('YYYY-MM-DD');
    } else if (!isNil(value) && value.target) {
      data.persist();
      value = data.target.value;
    }

    this.setState(prevState => ({
      visit: {
        ...prevState.visit,
        insurance: {
          ...prevState.visit.insurance,
          [name]: value,
        },
      },
      isDirty: true,
    }));
  };

  handleRemoveInsurancePhoto = index => {
    if (window.confirm('This will remove the insurance card photo. Are you sure?')) {
      const attachments = [].concat(this.state.visit.insurance.attachments);
      attachments.splice(index, 1);

      this.setState(prevState => ({
        visit: {
          ...prevState.visit,
          insurance: {
            ...prevState.visit.insurance,
            attachments,
          },
        },
      }));
    }
  };

  render() {
    const {
      classes,
      currentChartVisit,
      handleEndCall,
      isInVisit,
      isLoading,
      isLoadingPharmacy,
      previousVisits,
      providerId,
      selectedPharmacy,
    } = this.props;

    const { visit, isShowSignConfirmDialog, isShowSaveConfirmDialog, isShowPharmacySelectModal, isShowRxModal, doseSpotUrl } = this.state;

    if ((isLoading || !visit) && !this.disableSaveIndicatorRef.current) return <LoadingOverlay />;

    const {
      addendums,
      chiefComplaint,
      created,
      dob,
      entranceName,
      firstName,
      gender,
      insurance,
      lastName,
      location,
      notes,
      prescriptions,
      providerName,
    } = visit;

    // do not show current visit id previous visits in table
    const filteredPreviousVisits = previousVisits.filter(pv => pv.visitId !== visit.id);

    // determine whether to show the mini view
    // if not own visit and not signed
    // is is assumed visit is in completed state
    const isOwnVisit = providerId === visit.providerId;
    const isSigned = !!visit.signedDate;
    const isMiniChartView = !isOwnVisit && !isSigned;

    console.log('---------------------------', visit);

    // show or hide phi indicator
    const isFacilitatedVisit = has(visit, 'facility') && !isNil(visit.facility);
    const isShowPhiIndicator = isFacilitatedVisit && visit.facility.phiVisible !== true;
    const isShowDocumentPassword = isFacilitatedVisit && visit.facility.shareVisitDocuments && visit.password;

    const isProviderChartingDisabled = (!!visit.entranceConfig && visit.entranceConfig.disableProviderCharting) || (isFacilitatedVisit && visit.facility.skipProviderCharting === true);
    const isPharmacyInfoDisabled = isFacilitatedVisit && visit.facility.skipPharmacy === true;

    // show or hide confirm charges button
    // permission required
    // visit must be completed and payment status must be pending
    // if visit doesn't have pricing, it's a facilitated visit: TODO: determine what to do for facilitated
    const showConfirmChargesButton =
      can(providerGroupPermissions.createSuggestedPaymentChange) &&
      visit.state === visitStatusTypes.COMPLETED &&
      has(visit, 'pricing.status') &&
      visit.pricing.status === paymentStatusTypes.PENDING;

    // determine whether to disable signing
    // requires at least one note added by primary provider on visit
    // still allow signing of addendums on visits that are not your own
    let isSignDisabled =
      notes && !isEmpty(notes)
        ? !notes.some(note => {
            return note.providerId === visit.providerId;
          })
        : isOwnVisit;

    if (isProviderChartingDisabled && isOwnVisit) {
      isSignDisabled = false;
    }

    const filteredNoteTypes = this.filterNoteTypes();

    const ageString = getAgeString(visit.dob);

    return (
      <div className={classNames([classes.root, 'page-padding-bottom'])} elevation={1}>
        <Paper style={{ margin: '1rem' }}>
          <Grid container className={classes.chartHeader}>
            <Grid item xs={12} sm={4} className={classes.formField}>
              <div style={{ flex: '1 1 auto' }}>
                <FormLabel>First name</FormLabel>
                <TextField value={visit.firstName} className={classes.textField} onChange={(e) => {
                  e.persist();

                  this.setState(prevState => ({
                    visit: {
                      ...prevState.visit,
                      firstName: e.target.value,
                    },
                    isDirty: true,
                  }));
                }} />
              </div>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.formField}>
              <div style={{ flex: '1 1 auto' }}>
                <FormLabel>Last name</FormLabel>
                <TextField value={visit.lastName} className={classes.textField} onChange={(e) => {
                  e.persist();

                  this.setState(prevState => ({
                    visit: {
                      ...prevState.visit,
                      lastName: e.target.value,
                    },
                    isDirty: true,
                  }));
                }} />
              </div>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.formField}>
              <div style={{ flex: '1 1 auto' }}>
                <FormLabel>Date of birth</FormLabel>
                <TextField type="date" value={visit.dob} className={classes.textField} onChange={(e) => {
                  e.persist();

                  this.setState(prevState => ({
                    visit: {
                      ...prevState.visit,
                      dob: e.target.value,
                    },
                    isDirty: true,
                  }));
                }} />
              </div>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.formField}>
              <div style={{ flex: '1 1 auto' }}>
                <FormLabel>Gender</FormLabel>
                <Select
                  className={classes.textField}
                  value={visit.gender || ''}
                  onChange={(e) => {
                    e.persist();

                    this.setState(prevState => ({
                      visit: {
                        ...prevState.visit,
                        gender: e.target.value,
                      },
                      isDirty: true,
                    }));
                  }}
                >
                  {['male', 'female', 'other', 'ask'].map(gender => {
                    return (
                      <MenuItem value={gender} key={gender}>
                        {capitalize(gender)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.formField}>
              <div style={{ flex: '1 1 auto' }}>
                <FormLabel>Callback #</FormLabel>
                <TextField value={visit.mobileNumber} className={classes.textField} onChange={(e) => {
                  e.persist();

                  this.setState(prevState => ({
                    visit: {
                      ...prevState.visit,
                      mobileNumber: e.target.value,
                    },
                    isDirty: true,
                  }));
                }} />
              </div>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.formField}>
              <div style={{ flex: '1 1 auto' }}>
                <FormLabel>Email</FormLabel>
                <TextField type="email" value={visit.patientEmail} className={classes.textField} onChange={(e) => {
                  e.persist();

                  this.setState(prevState => ({
                    visit: {
                      ...prevState.visit,
                      patientEmail: e.target.value,
                    },
                    isDirty: true,
                  }));
                }} />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.formField}>
              <div style={{ flex: '1 1 auto' }}>
                <FormLabel>{'Street address'}</FormLabel>
                <TextField type="text" value={visit.patientAddressLine1} className={classes.textField} onChange={(e) => {
                  e.persist();

                  this.setState(prevState => ({
                    visit: {
                      ...prevState.visit,
                      patientAddressLine1: e.target.value,
                    },
                    isDirty: true,
                  }));
                }} />
              </div>
              <div style={{ flex: '1 1 auto' }}>
                <FormLabel>{'City'}</FormLabel>
                <TextField type="text" value={visit.patientAddressCity} className={classes.textField} onChange={(e) => {
                  e.persist();

                  this.setState(prevState => ({
                    visit: {
                      ...prevState.visit,
                      patientAddressCity: e.target.value,
                    },
                    isDirty: true,
                  }));
                }} />
              </div>
              <div style={{ flex: '1 1 auto' }}>
                <FormLabel>{'State'}</FormLabel>
                <Select
                  className={classes.textField}
                  value={visit.patientAddressState || ''}
                  onChange={(e) => {
                    e.persist();

                    this.setState(prevState => ({
                      visit: {
                        ...prevState.visit,
                        patientAddressState: e.target.value,
                      },
                      isDirty: true,
                    }));
                  }}
                >
                  {getUSStateOptions().map(state => {
                    return (
                      <MenuItem value={state.value} key={state.value}>
                        {state.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div style={{ flex: '1 1 auto' }}>
                <FormLabel>{'Zip'}</FormLabel>
                <TextField type="number" value={visit.patientAddressZip} className={classes.textField} onChange={(e) => {
                  e.persist();

                  this.setState(prevState => ({
                    visit: {
                      ...prevState.visit,
                      patientAddressZip: e.target.value,
                    },
                    isDirty: true,
                  }));
                }} />
              </div>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={4} className={classes.formField}>
                <div style={{ flex: '1 1 auto' }}>
                  <FormLabel>Patient Phone #</FormLabel>
                  <TextField value={visit.patientMobileNumber} className={classes.textField} onChange={(e) => {
                    e.persist();

                    this.setState(prevState => ({
                      visit: {
                        ...prevState.visit,
                        patientMobileNumber: e.target.value,
                      },
                      isDirty: true,
                    }));
                  }} />
                </div>
              </Grid>
            </Grid>

            {/*<div className={classNames(classes.headerWrapper)}>*/}
            {/*  <div className={classNames(classes.headerContainer)}>*/}
            {/*    <div className={classNames(classes.headerItem)}>*/}
            {/*      <div className={classNames(classes.headerItemTitle)}>Patient</div>*/}
            {/*      <div className={classNames(classes.headerItemBody)}>{`${firstName} ${lastName}`}</div>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(classes.headerItem)}>*/}
            {/*      <div className={classNames(classes.headerItemTitle)}>DOB</div>*/}
            {/*      <div className={classNames(classes.headerItemBody)}>{`${moment(visit.dob).format('l')} (${ageString})`}</div>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(classes.headerItem)}>*/}
            {/*      <div className={classNames(classes.headerItemTitle)}>Gender</div>*/}
            {/*      <div className={classNames(classes.headerItemBody)}>{`${getLongGender(visit.gender)}`}</div>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(classes.headerItem)}>*/}
            {/*      <div className={classNames(classes.headerItemTitle)}>Phone #</div>*/}
            {/*      <div className={classNames(classes.headerItemBody)}>{visit.mobileNumber}</div>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(classes.headerItem)}>*/}
            {/*      <div className={classNames(classes.headerItemTitle)}>Provider</div>*/}
            {/*      <div className={classNames(classes.headerItemBody)}>{providerName}</div>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(classes.headerItem)}>*/}
            {/*      <div className={classNames(classes.headerItemTitle)}>Encounter</div>*/}
            {/*      <div className={classNames(classes.headerItemBody)}>{moment(created).format('M/D/YYYY')}</div>*/}
            {/*    </div>*/}
            {/*    <div className={classNames(classes.headerItem, classes.headerComplaint)}>*/}
            {/*      <div className={classNames(classes.headerItemTitle)}>Complaint</div>*/}
            {/*      <div className={classNames(classes.headerItemBody)}>{chiefComplaint}</div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </Grid>
        </Paper>

        {!isProviderChartingDisabled && (
          <Grid container>
            {isMiniChartView && (
              <div style={{ padding: '1rem' }}>
                <Typography variant="h6">The primary note for this visit has not been signed.</Typography>
              </div>
            )}
            {!isMiniChartView && !!visit.verbalConsentEnabled && (
              <Grid item xs={12}>
                  <div style={{ flex: '1 1 auto', paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '1rem', }}>
                    <Paper>
                        <div style={{ padding: '1rem', backgroundColor: Colors.primary.lightgray }}><p style={{ margin: 0, fontWeight: 'bold' }}>Before we begin, I need to confirm that you consent to this video visit and to make sure you know that you have the right to discontinue this visit at any time since this medical visit is not the same as an in-person visit with a doctor. The benefit to a video telehealth visit is that you are able to be seen now, in the safety of your own environment and without the need for travel to an emergency department or urgent care center. However, there are limitations to video visits such as the inability to examine you as I would in person and the possibility that a technology problem could disrupt the visit. Also, it’s important for you to know that we will bill your insurance for this video visit and will use all reasonable safeguards to protect your health information during and after this video visit. Would you like to continue with this visit?</p></div>
                        <div style={{ padding: '1rem' }}><div>
                          <Checkbox
                            color="primary"
                            value="patient_agreed"
                            checked={visit.verbalConsentStatus === 'patient_agreed'}
                            onChange={(e) => this.handleSelectFieldChange('verbalConsentStatus', visit.verbalConsentStatus === 'patient_agreed' ? null : 'patient_agreed')} /> Yes
                        </div>
                          <div><Checkbox color="primary" value="did_not_ask" checked={visit.verbalConsentStatus === 'did_not_ask'} onChange={(e) => this.handleSelectFieldChange('verbalConsentStatus', visit.verbalConsentStatus === 'did_not_ask' ? null : 'did_not_ask')} /> No</div>
                          <div><Checkbox color="primary" value="patient_refused" checked={visit.verbalConsentStatus === 'patient_refused'} onChange={(e) => this.handleSelectFieldChange('verbalConsentStatus', visit.verbalConsentStatus === 'patient_refused' ? null : 'patient_refused')} /> Patient Refused</div></div>
                    </Paper>

                  </div>
              </Grid>
            )}
            {!isMiniChartView && (
              <Grid item xs={12} sm={6} lg={8}>
                <ChartPatientHistoryForm visit={visit} handleFormUpdate={this.handleSelectFieldChange} isReadOnly={!isOwnVisit} />
              </Grid>
            )}
            {!isMiniChartView && (
              <Grid item xs={12} sm={6} lg={4}>
                {can(providerGroupPermissions.readPatientImage) && <ChartPatientPhotos images={visit.patientPhotos} />}
              </Grid>
            )}
            {!isMiniChartView && (
              <Grid item xs={12} sm={4} md={6} lg={4}>
                <ChartPatientLocation
                  isReadOnly={!isOwnVisit}
                  handleFormUpdate={this.handleFormUpdate}
                  location={location}
                  providerId={providerId}
                />
              </Grid>
            )}
            {!isMiniChartView && (
              <Grid item xs={12} sm={8} md={6} lg={8}>
                <ChartPatientPreviousVisits previousVisits={filteredPreviousVisits} noteTypes={filteredNoteTypes} />
              </Grid>
            )}
            {!isMiniChartView && (
              <Grid item xs={12}>
                <ChartFamilyHistoryFormComponent visit={visit} isReadOnly={!isOwnVisit} handleFormUpdate={this.handleFormUpdate} />
              </Grid>
            )}
            {!isMiniChartView && insurance && (
              <Grid item xs={12}>
                <ChartPatientInsurance
                  insurance={insurance}
                  handleUpdateInsuranceField={this.handleUpdateInsuranceField}
                  handleRemoveInsurancePhoto={this.handleRemoveInsurancePhoto}
                />
              </Grid>
            )}
            {!isMiniChartView && !isPharmacyInfoDisabled && (
              <Grid item xs={12}>
                <ChartPatientPrescriptions prescriptions={prescriptions} isRefreshing={this.state.refreshingPrescriptions} handleRefreshPrescriptions={this.handleRefreshPrescriptions} />
              </Grid>
            )}
            {!isMiniChartView && (
              <Grid container>
                {!isPharmacyInfoDisabled && (
                  <Grid item xs={12} md={4} lg={3} style={{ padding: '1rem' }}>
                    <div>
                      <Paper style={{ padding: '1rem 1rem 2rem 1rem' }}>
                        {isLoadingPharmacy && !isShowPharmacySelectModal && <img src={spinner} alt="" />}
                        {(!isLoadingPharmacy || isShowPharmacySelectModal) && (
                          <ChartPharmacyCard
                            pharmacy={selectedPharmacy}
                            handleEditPharmacy={this.handleShowPharmacySelectModal}
                            handleRx={this.handleRx}
                          />
                        )}
                      </Paper>
                    </div>
                  </Grid>
                )}

                {!isMiniChartView && can(providerGroupPermissions.readDx) && (
                  <Grid item xs={12} md={8} lg={9}>
                    <div style={{ padding: '1rem' }}>
                      <Paper style={{ padding: '1rem 1rem 2rem 1rem' }}>
                        <Typography className={classes.padding}>{'DIAGNOSIS:'}</Typography>
                        <div style={{ flex: '1 1 auto' }}>
                          <ReactSelectField
                            selectedValues={visit.diagnosis ? mapJsonArrayToSelectOptions(nihApiTypes.NIH_DIAGNOSIS, visit.diagnosis) : []}
                            handleChange={value => this.handleSelectFieldChange('diagnosis', value)}
                            isMulti
                            isReadOnly={!isOwnVisit || !can(providerGroupPermissions.updateDx)}
                            handleFetchData={this.handleFetchData(nihApiTypes.NIH_DIAGNOSIS)}
                            placeholder={`Start typing to search...`}
                          />
                        </div>
                      </Paper>
                    </div>
                  </Grid>
                )}
              </Grid>
            )}
            {!isMiniChartView && can(providerGroupPermissions.readNote) && (
              <Grid item xs={12}>
                <ChartPatientNotes
                  visitId={visit.id}
                  notes={notes}
                  noteTypes={filteredNoteTypes}
                  addChartNote={this.addChartNote}
                  deleteChartNote={this.deleteChartNote}
                  isReadOnly={!isOwnVisit}
                  providerId={providerId}
                  primaryProviderId={visit.providerId}
                  handleNotesUpdate={this.handleNotesUpdate}
                  visitDate={created}
                  providerName={providerName}
                  entranceBrandName={entranceName}
                  patientName={`${firstName} ${lastName}`}
                  patientDob={dob}
                  patientGender={gender}
                  handleDiscardNoteChanges={this.handleDiscardNoteChanges}
                />
              </Grid>
            )}

            {can(providerGroupPermissions.readNote) && (
              <Grid item xs={12}>
                <ChartPatientAddendums
                  visitId={visit.id}
                  notes={addendums}
                  noteTypes={filteredNoteTypes}
                  addChartNote={this.addChartAddendum}
                  deleteChartNote={this.deleteChartAddendum}
                  isReadOnly={isOwnVisit} // cannot modify addendums for your own visit
                  providerId={providerId}
                  primaryProviderId={visit.providerId}
                  handleNotesUpdate={this.handleAddendumsUpdate}
                  visitDate={created}
                  providerName={providerName}
                  entranceBrandName={entranceName}
                  patientName={`${firstName} ${lastName}`}
                  patientDob={dob}
                  patientGender={gender}
                  handleDiscardNoteChanges={this.handleDiscardAddendumChanges}
                />
              </Grid>
            )}
          </Grid>
        )}

        {isProviderChartingDisabled && (
          <Grid container className={classes.chartingDisabledContainer}>
            <Grid item className={classes.chartingDisabledMessage}>
              Charting has been disabled for this encounter.
            </Grid>
          </Grid>
        )}

        {!isProviderChartingDisabled && (
          <Grid container style={{ padding: '1rem' }}>
            {!isMiniChartView && can(providerGroupPermissions.readDx) && (
              <Grid item xs={12} md={8}>
                {isShowDocumentPassword && (
                  <Grid item xs={12} className={classes.formField} style={{ marginTop: '1rem', display: 'flex' }}>
                    <div className={classes.formLabel} style={{ alignSelf: 'flex-start', marginTop: 10 }}>
                      Document Sharing Password:
                    </div>
                    <div style={{ flex: 1 }}>
                      <TextField
                        variant="outlined"
                        value={visit.password}
                        disabled
                        classes={{ root: classes.passwordTextFieldRoot }}
                        InputProps={{ classes: { disabled: classes.passwordInputRoot } }}
                      />
                      <Button style={{ display: 'block', marginTop: 4 }} size="small" onClick={this.regenDocSharePassword}>
                        Regenerate
                      </Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}

        <Grid container item xs={12} justify="flex-end" style={{ paddingRight: '1rem' }}>
          {showConfirmChargesButton && (
            <Button variant="outlined" style={{ marginRight: '0.5rem' }} onClick={this.props.showConfirmChargesModal}>
              Confirm Charges
            </Button>
          )}
          <Button variant="outlined" style={{ marginRight: '0.5rem' }} onClick={this.handleShowSaveConfirmDialog}>
            Save and Sign Later
          </Button>
          {!isInVisit && (
            <Button
              variant="outlined"
              onClick={this.handleShowSignConfirmDialog}
              classes={{ disabled: classes.buttonDisabled, root: classes.button }}
              disabled={isSignDisabled}
            >
              {`Save and Sign ${isOwnVisit ? 'Visit' : 'Addendum'}`}
            </Button>
          )}
          {isInVisit && (
            <Button variant="outlined" onClick={handleEndCall} classes={{ root: classes.button }}>
              End Visit
            </Button>
          )}
        </Grid>
        <CustomDialog
          open={isShowSaveConfirmDialog}
          handleClose={this.handleCloseSaveConfirmDialog}
          content={
            <Typography variant="subtitle1" id="modal-title">
              {`This will save the ${isOwnVisit ? 'visit' : 'addendum(s)'} without signing it.`}
            </Typography>
          }
          title={`Save and Sign ${isOwnVisit ? 'Visit' : 'Addendum'}`}
          handleAction={isOwnVisit ? this.handleSaveVisit : this.handleSaveAddendums}
        />
        <CustomDialog
          open={isShowSignConfirmDialog}
          handleClose={this.handleCloseSignConfirmDialog}
          content={
            <Typography variant="subtitle1" id="modal-title">
              {`This will save and sign the ${isOwnVisit ? 'visit' : 'addendum(s)'}. Are you sure?`}
            </Typography>
          }
          title={`Save and Sign ${isOwnVisit ? 'Visit' : 'Addendum(s)'}`}
          handleAction={isOwnVisit ? this.handleSignVisit : this.handleSignAddendums}
        />
        <PharmacySelectionModal
          open={isShowPharmacySelectModal}
          handleClose={this.handleClosePharmacySelectModal}
          onPharmacySelect={this.onPharmacySelect}
          visitId={currentChartVisit.id}
        />
        <RxModal url={doseSpotUrl} open={isShowRxModal} handleClose={this.handleCloseRxModal} />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    minHeight: '100%',
    minWidth: 500,
  },
  chartHeader: {
    padding: '1rem',
  },
  headerWrapper: {},
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  headerItem: {
    flex: '1 1 auto',

    '@media (max-width: 960px)': {
      flex: '1 0 33.33%',
      flexBasis: '33.33%',
      marginTop: '.8rem',

      '&:nth-child(-n+3)': {
        marginTop: 0,
      },
    },

    '@media (max-width: 540px)': {
      flex: '1 0 100%',
      flexBasis: '100%',
      marginTop: '.8rem',
    },
  },
  headerItemTitle: {
    fontWeight: '600',
  },
  headerItemBody: {
    fontSize: '1.1rem',
    marginTop: '.4rem',
  },
  headerComplaint: {
    flex: '1 0 100%',
    flexBasis: '100%',
    marginTop: '.8rem',
  },
  chartHeaderText: {
    fontSize: '1.1rem',
  },
  chartingDisabledContainer: {
    marginTop: '1rem',
    marginBottom: '1rem',
    padding: '3rem',
    backgroundColor: theme.palette.primary.lightgray,
  },
  chartingDisabledMessage: {
    width: '100%',
    height: '100%',
    fontSize: '1.2rem',
    textAlign: 'center',
    color: Colors.primary.darkgray,
    fontStyle: 'italic',
  },
  formField: {
    display: 'flex',
    minHeight: 50,
    paddingRight: '1rem',
    marginBottom: '1rem',
  },
  formLabel: {
    alignSelf: 'center',
    marginRight: '1rem',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white,
    },
  },
  buttonDisabled: {
    backgroundColor: theme.palette.primary.lightgray,
    '&:hover': {
      backgroundColor: theme.palette.secondary.lightgray,
    },
  },
  cancelButton: {
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.white,
      color: theme.palette.primary.main,
    },
  },
  phiIndicator: {
    color: Colors.primary.red,
    fontSize: '1.2rem',
    fontStyle: 'italic',
  },
  passwordInputRoot: {
    color: Colors.primary.darkgray,
    fontSize: '1.2rem',
    padding: 4,
  },
  passwordTextFieldRoot: {
    width: '100%',
  },
  textField: {
    width: '100%',
  },
});

ChartDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  currentChartVisit: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  isLoadingPharmacy: PropTypes.bool.isRequired,
  isInVisit: PropTypes.bool,
  noteTypes: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  providerId: PropTypes.string,
  previousVisits: PropTypes.array.isRequired,
  selectedPharmacy: PropTypes.object,

  addChartNote: PropTypes.func.isRequired,
  clearCurrentChartVisit: PropTypes.func.isRequired,
  clearLayoutBeforeUnload: PropTypes.func.isRequired,
  clearPharmacy: PropTypes.func.isRequired,
  clearPreviousVisits: PropTypes.func.isRequired,
  deleteChartNote: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  getChartPreviousVisits: PropTypes.func.isRequired,
  getDoseSpotUrl: PropTypes.func.isRequired,
  refreshVisitPrescriptions: PropTypes.func.isRequired,
  getPharmacy: PropTypes.func.isRequired,
  getPreviousVisit: PropTypes.func.isRequired,
  handleEndCall: PropTypes.func,
  regenChartDocumentPassword: PropTypes.func.isRequired,
  setCurrentChartVisit: PropTypes.func.isRequired,
  setLayoutBeforeUnload: PropTypes.func.isRequired,
  showConfirmChargesModal: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  signVisit: PropTypes.func.isRequired,
  updateAddendums: PropTypes.func.isRequired,
  updateVisit: PropTypes.func.isRequired,
};

ChartDetails.defaultProps = {
  currentChartVisit: null,
  isInVisit: false,
  providerId: null,
  selectedPharmacy: null,

  handleEndCall: () => {},
};

const mapStateToProps = state => {
  return {
    boardItems: state.board.boardItems,
    currentChartVisit: state.chart.currentChartVisit,
    isLoading: state.chart.isLoading || state.pharmacy.isGettingDoseSpotUrl,
    isLoadingPharmacy: state.pharmacy.isLoading,
    noteTypes: state.chart.noteTypes,
    previousVisits: state.chart.previousVisits,
    profile: state.provider.profile,
    providerId: state.provider.providerId,
    selectedPharmacy: state.pharmacy.selectedPharmacy,
  };
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, {
      addChartNote,
      clearCurrentChartVisit,
      clearLayoutBeforeUnload,
      clearPharmacy,
      clearPreviousVisits,
      deleteChartNote,
      fetchData,
      getChartPreviousVisits,
      getDoseSpotUrl,
      refreshVisitPrescriptions,
      getPharmacy,
      getPreviousVisit,
      regenChartDocumentPassword,
      setCurrentChartVisit,
      setLayoutBeforeUnload,
      showConfirmChargesModal,
      showToast,
      signVisit,
      updateAddendums,
      updateVisit,
    })(ChartDetails)
  )
);
