import produce from 'immer';
import moment from 'moment';
import {
  GET_BOARD_ITEMS_REQUEST,
  GET_BOARD_ITEMS_SUCCESS,
  GET_BOARD_ITEMS_FAILURE,
  GET_ACTIVE_BOARD_ITEMS_FAILURE,
  GET_ACTIVE_BOARD_ITEMS_REQUEST,
  GET_ACTIVE_BOARD_ITEMS_SUCCESS,
  GET_ASSIGNED_BOARD_ITEMS_FAILURE,
  GET_ASSIGNED_BOARD_ITEMS_REQUEST,
  GET_ASSIGNED_BOARD_ITEMS_SUCCESS,
  GET_NEW_BOARD_ITEMS_FAILURE,
  GET_NEW_BOARD_ITEMS_REQUEST,
  GET_NEW_BOARD_ITEMS_SUCCESS,
  UPDATE_BOARD_VISIT_STATUS,
  SET_VISIT_STATUS_SUCCESS,
  SET_VISIT_STATUS_FAILURE,
  SET_VISIT_STATUS_REQUEST,
} from './board.actions';
import { visitStatusTypes } from '../../types/visitStatusTypes';

const initialState = {
  boardItems: [],
  isLoading: false,
  newBoardItems: {
    data: [],
    isLoading: false,
  },
  assignedBoardItems: {
    data: [],
    isLoading: false,
  },
  activeBoardItems: {
    data: [],
    isLoading: false,
  },
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_BOARD_ITEMS_REQUEST:
      if (!action.actionMetadata || (action.actionMetadata && action.actionMetadata.isDisableLoading !== true)) {
        draft.isLoading = true;
      }
      break;

    case GET_NEW_BOARD_ITEMS_REQUEST:
      if (!action.actionMetadata || (action.actionMetadata && action.actionMetadata.isDisableLoading !== true)) {
        draft.newBoardItems.isLoading = true;
      }
      break;

    case GET_ASSIGNED_BOARD_ITEMS_REQUEST:
      if (!action.actionMetadata || (action.actionMetadata && action.actionMetadata.isDisableLoading !== true)) {
        draft.assignedBoardItems.isLoading = true;
      }
      break;

    case GET_ACTIVE_BOARD_ITEMS_REQUEST:
      if (!action.actionMetadata || (action.actionMetadata && action.actionMetadata.isDisableLoading !== true)) {
        draft.activeBoardItems.isLoading = true;
      }
      break;

    case GET_BOARD_ITEMS_SUCCESS: {
      // calculate start timestamp from waitTime for each item and save
      const mappedBoardItems = action.response.data.map(item => {
        return {
          ...item,
          waitStartTimestamp: moment() - item.waitTime,
        };
      });

      draft.boardItems = mappedBoardItems;
      draft.isLoading = false;
      break;
    }

    case GET_NEW_BOARD_ITEMS_SUCCESS: {
      // calculate start timestamp from waitTime for each item and save
      const mappedBoardItems = action.response.data.map(item => {
        return {
          ...item,
          waitStartTimestamp: moment() - item.waitTime,
        };
      });

      draft.newBoardItems.data = mappedBoardItems;
      draft.newBoardItems.isLoading = false;
      break;
    }

    case GET_ACTIVE_BOARD_ITEMS_SUCCESS: {
      // calculate start timestamp from waitTime for each item and save
      const mappedBoardItems = action.response.data.map(item => {
        return {
          ...item,
          waitStartTimestamp: moment() - item.waitTime,
        };
      });

      draft.activeBoardItems.data = mappedBoardItems;
      draft.activeBoardItems.isLoading = false;
      break;
    }

    case GET_ASSIGNED_BOARD_ITEMS_SUCCESS: {
      // calculate start timestamp from waitTime for each item and save
      const mappedBoardItems = action.response.data.map(item => {
        return {
          ...item,
          waitStartTimestamp: moment() - item.waitTime,
        };
      });

      draft.assignedBoardItems.data = mappedBoardItems;
      draft.assignedBoardItems.isLoading = false;
      break;
    }

    case GET_BOARD_ITEMS_FAILURE:
      draft.isLoading = false;
      break;

    case GET_NEW_BOARD_ITEMS_FAILURE:
      draft.newBoardItems.isLoading = false;
      break;

    case GET_ASSIGNED_BOARD_ITEMS_FAILURE:
      draft.assignedBoardItems.isLoading = false;
      break;

    case GET_ACTIVE_BOARD_ITEMS_FAILURE:
      draft.activeBoardItems.isLoading = false;
      break;

    case UPDATE_BOARD_VISIT_STATUS: {
      const boardItemIndex = draft.boardItems.findIndex(visit => visit.id === action.payload.visitId);

      if (boardItemIndex > -1) {
        if (draft.boardItems[boardItemIndex].state === visitStatusTypes.CANCELED) {
          draft.boardItems.splice(boardItemIndex, 1);
        } else {
          draft.boardItems[boardItemIndex].state = action.payload.newState;
        }
      }
      break;
    }

    case SET_VISIT_STATUS_REQUEST:
      draft.isLoading = true;
      break;

    case SET_VISIT_STATUS_SUCCESS:
      draft.isLoading = false;
      break;

    case SET_VISIT_STATUS_FAILURE:
      draft.isLoading = false;
      break;

    default:
      break;
  }
}, initialState);
