import produce from 'immer';
import {
  GET_TWILIO_VIDEO_AUTH_REQUEST,
  GET_TWILIO_VIDEO_AUTH_SUCCESS,
  GET_TWILIO_VIDEO_AUTH_FAILURE,
  SEND_PATIENT_MESSAGE_REQUEST,
  SEND_PATIENT_MESSAGE_SUCCESS,
  SEND_PATIENT_MESSAGE_FAILURE,
  CONFIRM_VISIT_CHARGE_REQUEST,
  CONFIRM_VISIT_CHARGE_SUCCESS,
  CONFIRM_VISIT_CHARGE_FAILURE,
  CALL_PATIENT_REQUEST,
  CALL_PATIENT_SUCCESS,
  CALL_PATIENT_FAILURE,
  SET_VIDEO_CONNECTION_STATUS,
} from './visit.actions';

const initialState = {
  twilioVideoAuth: null,
  isLoading: false,
  videoConnectionStatus: 'disconnected',
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_TWILIO_VIDEO_AUTH_SUCCESS:
      draft.twilioVideoAuth = action.response;
      draft.isLoading = false;
      break;

    case SET_VIDEO_CONNECTION_STATUS:
      draft.videoConnectionStatus = action.payload;
      break;

    case GET_TWILIO_VIDEO_AUTH_FAILURE:
      draft.isLoading = false;
      break;

    case GET_TWILIO_VIDEO_AUTH_REQUEST:
      draft.isLoading = true;
      break;

    case SEND_PATIENT_MESSAGE_SUCCESS:
      draft.isLoading = false;
      break;

    case SEND_PATIENT_MESSAGE_FAILURE:
      draft.isLoading = false;
      break;

    case SEND_PATIENT_MESSAGE_REQUEST:
      draft.isLoading = true;
      break;

    case CONFIRM_VISIT_CHARGE_SUCCESS:
      draft.isLoading = false;
      break;

    case CONFIRM_VISIT_CHARGE_FAILURE:
      draft.isLoading = false;
      break;

    case CONFIRM_VISIT_CHARGE_REQUEST:
      draft.isLoading = true;
      break;

    case CALL_PATIENT_SUCCESS:
      draft.isLoading = false;
      break;

    case CALL_PATIENT_FAILURE:
      draft.isLoading = false;
      break;

    case CALL_PATIENT_REQUEST:
      draft.isLoading = true;
      break;

    default:
      break;
  }
}, initialState);
