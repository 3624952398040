import { Button, Card, CardContent, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import logo from '../../images/login/logo-grey.png';
// import MessageBanner from '../layout/messageBanner.component';
import { setPageTitle } from '../layout/layout.actions';
// import { isNil } from 'lodash';
// import { isInTimeRange } from '../../utilities/dataUtils';

const MAX_WIDTH = '10rem';

const styles = theme => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    paddingBottom: '6rem',
    height: '100vh'
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  logo: {
    maxWidth: MAX_WIDTH,
    margin: theme.spacing.unit,
    marginBottom: '1rem'
  },
  textFieldWrapper: {
    width: '100%',
    maxWidth: MAX_WIDTH,
    marginBottom: '2rem'
  },
  buttonsWrapper: {
    marginTop: '1.5rem',
    width: '100%',
    maxWidth: MAX_WIDTH
  },
  messageBanner: {
    position: 'absolute',
    top: theme.spacing.unit
  }
});

class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleLogin = this.handleLogin.bind(this);
  }

  componentWillMount() {
    this.props.setPageTitle('Login');
    // this.props.getMessageBanner();
  }

  handleLogin() {
    this.props.auth.login();
  }

  // shouldShowMessageBanner = () => {
  //   const { messageBanner } = this.props;
  //   if (!isNil(messageBanner)) {
  //     if (!isNil(messageBanner.startTime)) {
  //       return isInTimeRange(messageBanner.startTime, messageBanner.endTime);
  //     } else {
  //       // no timeframe provided
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  render() {
    const { classes, theme } = this.props;

    return (
      <div className={classes.formWrapper} style={{ backgroundColor: theme.palette.primary.background }}>
        <div style={{ display: 'flex', justifyContent: 'center', width: '50vw', alignSelf: 'center' }}>
          <Card
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '50vw',
              height: '80vh',
              flex: 1,
              position: 'relative'
            }}
          >
            {/* {this.shouldShowMessageBanner() && <MessageBanner message={messageBanner.text} type={messageBanner.state} />} */}
            <CardContent className={classNames(classes.formContent)}>
              <img src={logo} className={classNames(classes.logo)} alt="RelyMD logo" />
              <div style={{ marginBottom: '2rem' }}>
                <Typography variant="h6">Provider Login</Typography>
              </div>
              <div className={classNames(classes.buttonsWrapper)}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleLogin}
                  fullWidth={true}
                  style={{ marginBottom: '1.5rem' }}
                  type="submit"
                >
                  Login
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}

LoginContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
  // messageBanner: PropTypes.object
};

const mapStateToProps = state => {
  return {
    // messageBanner: state.layout.messageBanner
  };
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, {
      setPageTitle
      // getMessageBanner
    })(LoginContainer)
  )
);
