import {
  PHARMACY_SEARCH_REQUEST,
  PHARMACY_SEARCH_SUCCESS,
  PHARMACY_SEARCH_FAILURE,
  PHARMACY_SELECT_REQUEST,
  PHARMACY_SELECT_SUCCESS,
  PHARMACY_SELECT_FAILURE,
  GET_DOSESPOT_URL_REQUEST,
  GET_DOSESPOT_URL_SUCCESS,
  GET_DOSESPOT_URL_FAILURE,
  GET_PHARMACY_REQUEST,
  GET_PHARMACY_SUCCESS,
  GET_PHARMACY_FAILURE,
  CLEAR_PHARMACY,
  GET_TRANSMISSION_ERRORS_REQUEST,
  GET_TRANSMISSION_ERRORS_SUCCESS,
  GET_TRANSMISSION_ERRORS_FAILURE
} from './pharmacy.actions';

import produce from 'immer';

const initialState = {
  isLoading: false,
  isSelectingPharmacy: false,
  isGettingDoseSpotUrl: false,
  selectedPharmacy: null
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case PHARMACY_SEARCH_REQUEST:
      draft.isLoading = true;
      break;

    case PHARMACY_SEARCH_SUCCESS:
      draft.isLoading = false;
      break;

    case PHARMACY_SEARCH_FAILURE:
      draft.isLoading = false;
      break;

    case PHARMACY_SELECT_REQUEST:
      draft.isSelectingPharmacy = true;
      break;

    case PHARMACY_SELECT_SUCCESS:
      draft.isSelectingPharmacy = false;
      break;

    case PHARMACY_SELECT_FAILURE:
      draft.isSelectingPharmacy = false;
      break;

    case GET_DOSESPOT_URL_REQUEST:
      draft.isGettingDoseSpotUrl = true;
      break;

    case GET_DOSESPOT_URL_SUCCESS:
      draft.isGettingDoseSpotUrl = false;
      break;

    case GET_DOSESPOT_URL_FAILURE:
      draft.isGettingDoseSpotUrl = false;
      break;

    case GET_PHARMACY_REQUEST:
      draft.isLoading = true;
      break;

    case GET_PHARMACY_SUCCESS:
      draft.isLoading = false;
      draft.selectedPharmacy = action.response;
      break;

    case GET_PHARMACY_FAILURE:
      draft.isLoading = false;
      break;

    case CLEAR_PHARMACY:
      draft.selectedPharmacy = {};
      break;

    case GET_TRANSMISSION_ERRORS_REQUEST:
      // draft.isLoading = true;
      break;

    case GET_TRANSMISSION_ERRORS_SUCCESS:
      console.log('GET_TRANSMISSION_ERRORS_SUCCESS', action.response);
      // draft.isLoading = false;
      draft.transmissionErrors = action.response;
      break;

    case GET_TRANSMISSION_ERRORS_FAILURE:
      // draft.isLoading = false;
      draft.transmissionErrors = {};
      break;

    default:
      break;
  }
}, initialState);
