import React, { Component, Fragment } from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getLongGender } from '../../types/genderTypes';
import { getAgeString } from '../../utilities/dataUtils';
import moment from 'moment';

const styles = theme => ({
  tooltipText: {
    fontSize: '1.1rem',
    color: theme.palette.primary.darkgray,
    alignSelf: 'center'
  },
  tooltipActionButton: {
    marginLeft: 'auto'
  }
});

class ChartDemographicsTooltip extends Component {
  render() {
    let { classes, visit } = this.props;
    const ageString = getAgeString(visit.dob);

    return (
      <Fragment>
        <Typography className={classes.tooltipText}>{`DOB ${moment(visit.dob).format('l')} (${ageString})`}</Typography>
        <Typography className={classes.tooltipText}>{`${getLongGender(visit.gender)}`}</Typography>
        <Typography className={classes.tooltipText}>{`${visit.mobileNumber}`}</Typography>
      </Fragment>
    );
  }
}

ChartDemographicsTooltip.propTypes = {
  classes: PropTypes.object,
  visit: PropTypes.object
};

ChartDemographicsTooltip = withStyles(styles)(ChartDemographicsTooltip);

export default ChartDemographicsTooltip;
