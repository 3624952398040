import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomModal from '../modal/customModal.component';
import Close from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const styles = {
  imageContainer: { height: 'inherit', overflow: 'auto', maxHeight: 800 },
  image: { height: 1024, width: 1024, margin: 'auto', objectFit: 'contain' },
  closeButton: { marginLeft: 'auto', display: 'block' }
};

class ImageDetailModal extends Component {
  render() {
    const { open, handleClose, image, classes } = this.props;
    return (
      <CustomModal open={open} escapeKeyDown backdropClick handleClose={handleClose}>
        <IconButton disableRipple disableTouchRipple onClick={handleClose} className={classes.closeButton}>
          <Close />
        </IconButton>
        <div className={classes.imageContainer}>{image && <img src={image.url} alt={image.alt} className={classes.image} />}</div>
      </CustomModal>
    );
  }
}

ImageDetailModal.propTypes = {
  image: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ImageDetailModal);
