import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from '../../../common/modal/customModal.component';
import PreviousVisitSummary from './previousVisitSummary.component';

export const PreviousVisitSummaryModal = props => {
  return (
    <CustomModal
      open={props.open}
      handleClose={props.handleClose}
      backdropClick
      escapeKeyDown
      modalContentProps={{
        style: {
          // big ole modal
          width: '95%',
          maxHeight: '95%',
          overflow: 'auto'
        }
      }}
    >
      <PreviousVisitSummary
        visit={props.visit ? props.visit : {}}
        handleClose={props.handleClose}
        handleEdit={props.handleEdit ? props.handleEdit : null}
        isAddend={props.isAddend}
        visitPaging={props.visitPaging}
        handleSetCurrentVisit={props.handleSetCurrentVisit}
        noteTypes={props.noteTypes}
        isDisablePaging={props.isDisablePaging}
      />
    </CustomModal>
  );
};

PreviousVisitSummaryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  visit: PropTypes.object.isRequired,
  handleEdit: PropTypes.func,
  isAddend: PropTypes.bool,
  handleSetCurrentVisit: PropTypes.func.isRequired,
  visitPaging: PropTypes.object.isRequired,
  noteTypes: PropTypes.array.isRequired,
  isDisablePaging: PropTypes.bool
};

export default PreviousVisitSummaryModal;
