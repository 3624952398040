import { matchPath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setDirtyForm, toggleDrawerMenu } from './layout.actions';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import BackIcon from '@material-ui/icons/ChevronLeft';
import DrawerMenu, { DRAWER_WIDTH } from './drawerMenu.component';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import CallStatusSelect from './callStatusSelect.component';
import { updateAvailability } from '../board/provider.actions';
import WarningIcon from '@material-ui/icons/Warning';
import Badge from '@material-ui/core/Badge';
import { getTransmissionErrors } from '../pharmacy/pharmacy.actions';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { isEmpty } from 'lodash';
import RxModal from '../pharmacy/rxModal.component';

let transmissionInterval;

const transmissionStrings = {
  PendingPrescriptionsCount: 'Pending Prescriptions',
  RefillRequestsCount: 'Refill Requests',
  TransactionErrorsCount: 'Transmission Errors'
};

const styles = theme => ({
  // logo: {
  //   maxHeight: '2.5rem',
  //   marginRight: '1rem'
  menuButtonWrapper: {
    // width: '3rem'
  },
  toolbarContents: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  pageTitle: {
    marginLeft: '1rem'
  },
  pageTitleTypography: {
    fontWeight: 300,
    fontSize: '1.2rem'
  },
  root: {
    flexGrow: 1,
    height: 440,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  hamburgerButton: {
    marginLeft: 12,
    marginRight: 12
  },
  hide: {
    display: 'none'
  },
  badge: {
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.main
  },
  menuPaper: {
    minWidth: 200
  },
  menuListItem: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

class Header extends Component {
  constructor(props) {
    super(props);

    this.viewPage = this.viewPage.bind(this);
    this.isActiveRoute = this.isActiveRoute.bind(this);
  }

  state = {
    anchorEl: null,
    isShowRxModal: false,
    doseSpotUrl: ''
  };

  async componentDidMount() {
    await this.props.getTransmissionErrors();
    transmissionInterval = setInterval(() => this.props.getTransmissionErrors(), 60000);
  }

  componentWillUnmount() {
    clearInterval(transmissionInterval);
  }

  isActiveRoute(route) {
    const { pathname } = this.props.location;

    //explicitly return true/false because matchpath doesn't return a boolean
    if (
      matchPath(pathname, {
        path: route,
        exact: true,
        strict: false
      })
    ) {
      return true;
    }

    return false;
  }

  viewPage(route) {
    if (!this.isActiveRoute(route)) {
      if (route.indexOf('http') > -1) {
        window.open(route, '_blank');
      } else {
        this.props.history.push(route);
      }
    }
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleRx = () => {
    this.setState({
      isShowRxModal: true,
      doseSpotUrl: this.props.transmissionErrors.url,
      anchorEl: null
    });
  };

  handleCloseRxModal = () => {
    this.setState({
      isShowRxModal: false,
      doseSpotUrl: ''
    });
  };

  getTransmissionErrorsCount() {
    let errorCount = 0;
    if (!isEmpty(this.props.transmissionErrors)) {
      Object.keys(this.props.transmissionErrors).forEach(key => {
        if (key === 'TransactionErrorsCount' || key === 'PendingPrescriptionsCount') {
          errorCount += this.props.transmissionErrors[key];
        }
      });
    }
    return errorCount;
  }

  renderMenuItems() {
    let menuItems = [];

    Object.keys(this.props.transmissionErrors).forEach(key => {
      if (key === 'TransactionErrorsCount' || key === 'PendingPrescriptionsCount') {
        menuItems.push(
          <MenuItem
            key={key}
            onClick={this.handleRx}
            classes={{ root: this.props.classes.menuListItem }}
            disabled={key !== 'TransactionErrorsCount'}
          >
            <Typography variant="subtitle1">{transmissionStrings[key]}</Typography>
            <Typography variant="subtitle1">{`(${this.props.transmissionErrors[key]})`}</Typography>
          </MenuItem>
        );
      }
    });
    return menuItems;
  }

  render() {
    let { classes, shouldShowBackButton, isDrawerMenuOpen, user, pageTitle, loginState, profile } = this.props;
    const open = Boolean(this.state.anchorEl);

    return (
      <Fragment>
        <AppBar position="absolute" className={classNames(classes.appBar, isDrawerMenuOpen && classes.appBarShift)}>
          <Toolbar disableGutters={!isDrawerMenuOpen}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.props.toggleDrawerMenu}
              className={classNames(classes.hamburgerButton, isDrawerMenuOpen && classes.hide)}
            >
              <MenuIcon />
            </IconButton>

            <div className={classes.toolbarContents}>
              {shouldShowBackButton ? (
                <IconButton
                  className={classes.menuButtonWrapper}
                  color="inherit"
                  aria-label="Back"
                  onClick={() => {
                    if (this.props.hasDirtyForm) {
                      console.log('Had dirty form... manually assessing prompt');
                      if (window.confirm('You have unsaved changes. Clicking OK will not save these changes.')) {
                        this.props.setDirtyForm(false);
                        this.props.history.goBack();
                      }
                    } else {
                      this.props.history.goBack();
                    }
                  }}
                >
                  <BackIcon />
                </IconButton>
              ) : (
                <div className={classes.menuButtonWrapper} />
              )}

              <div className={classes.pageTitle}>
                <Typography color="inherit" noWrap className={classes.pageTitleTypography}>
                  {pageTitle}
                </Typography>
              </div>

              <div className={classes.menuButtonWrapper} />
            </div>
            <IconButton
              style={{ marginRight: '1rem' }}
              color="inherit"
              aria-label="Transmission Errors"
              onClick={this.handleMenu}
              disabled={isEmpty(this.props.transmissionErrors) || this.getTransmissionErrorsCount() === 0}
            >
              <Badge badgeContent={this.getTransmissionErrorsCount()} classes={{ badge: classes.badge }}>
                <WarningIcon />
              </Badge>
            </IconButton>
            {!isEmpty(this.props.transmissionErrors) && this.getTransmissionErrorsCount() > 0 && (
              <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={open}
                onClose={this.handleClose}
                classes={{ paper: classes.menuPaper }}
              >
                {this.renderMenuItems()}
              </Menu>
            )}
            <CallStatusSelect provider={profile} updateAvailability={this.props.updateAvailability} />
          </Toolbar>
        </AppBar>

        <DrawerMenu
          isDrawerMenuOpen={isDrawerMenuOpen}
          toggleDrawerMenu={this.props.toggleDrawerMenu}
          user={user}
          viewPage={this.viewPage}
          loginState={loginState}
        />
        <RxModal url={this.state.doseSpotUrl} open={this.state.isShowRxModal} handleClose={this.handleCloseRxModal} />
      </Fragment>
    );
  }
}

Header.propTypes = {
  responsiveBreakpoint: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  shouldShowBackButton: PropTypes.bool,
  isDrawerMenuOpen: PropTypes.bool,
  toggleDrawerMenu: PropTypes.func.isRequired,
  setDirtyForm: PropTypes.func.isRequired,
  hasDirtyForm: PropTypes.bool,
  loginState: PropTypes.number,
  getTransmissionErrors: PropTypes.func.isRequired,
  transmissionErrors: PropTypes.object
};

const mapStateToProps = state => {
  return {
    responsiveBreakpoint: state.layout.responsiveBreakpoint,
    pageTitle: state.layout.pageTitle,
    isDrawerMenuOpen: state.layout.isDrawerMenuOpen,
    user: state.login.user,
    loginState: state.login.loginState,
    hasDirtyForm: state.layout.hasDirtyForm,
    profile: state.provider.profile,
    transmissionErrors: state.pharmacy.transmissionErrors
  };
};

const withRouterHeader = withRouter(
  connect(
    mapStateToProps,
    {
      toggleDrawerMenu,
      setDirtyForm,
      updateAvailability,
      getTransmissionErrors
    }
  )(Header)
);

export default withStyles(styles, { withTheme: true })(withRouterHeader);
