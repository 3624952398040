import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import CustomDialog from '../customDialog/customDialog.component';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  textField: {
    width: '100%'
  },
  actionButton: {
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.main
  }
});

const MAX_MESSAGE_CHARS = 120;

class MessageDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: ''
    };
  }

  handleChange = name => event => {
    let updatedText = event.target.value;

    if (updatedText.length <= MAX_MESSAGE_CHARS) {
      this.setState({ [name]: event.target.value });
    }
  };

  getDialogContent = () => {
    const { text } = this.state;
    return (
      <div>
        <Typography>{'Send a message to the patient through the app. Message is not HIPAA secure, do not include PHI'}</Typography>
        <TextField
          id="oneway-message-text"
          multiline
          rows={1}
          rowsMax={5}
          value={this.state.text}
          onChange={this.handleChange('text')}
          className={this.props.classes.textField}
          margin="normal"
          helperText={`${text.length}/${MAX_MESSAGE_CHARS}`}
          autoFocus
        />
      </div>
    );
  };

  handleClose = () => {
    this.setState({
      text: ''
    });
    this.props.handleClose();
  };

  handleAction = () => {
    this.props.handleAction(this.state.text);
    this.setState({
      text: ''
    });
  };

  render() {
    let { open, classes } = this.props;
    const { text } = this.state;

    const isActionDisabled = text.trim() === '';

    return (
      <CustomDialog
        actionText={'Send'}
        open={open}
        handleClose={this.handleClose}
        handleAction={this.handleAction}
        title={'One-Way Message'}
        content={this.getDialogContent()}
        isActionDisabled={isActionDisabled}
        actionButtonProps={
          !isActionDisabled
            ? {
                className: classes.actionButton
              }
            : {}
        }
      />
    );
  }
}

MessageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired
};

export default withStyles(styles)(MessageDialog);
