import { CALL_API } from '../../middleware/api';

export const GET_NOTE_TYPES_REQUEST = 'GET_NOTE_TYPES_REQUEST';
export const GET_NOTE_TYPES_SUCCESS = 'GET_NOTE_TYPES_SUCCESS';
export const GET_NOTE_TYPES_FAILURE = 'GET_NOTE_TYPES_FAILURE';

export const GET_CHART_NOTES_REQUEST = 'GET_CHART_NOTES_REQUEST';
export const GET_CHART_NOTES_SUCCESS = 'GET_CHART_NOTES_SUCCESS';
export const GET_CHART_NOTES_FAILURE = 'GET_CHART_NOTES_FAILURE';

export const ADD_CHART_NOTE_REQUEST = 'ADD_CHART_NOTE_REQUEST';
export const ADD_CHART_NOTE_SUCCESS = 'ADD_CHART_NOTE_SUCCESS';
export const ADD_CHART_NOTE_FAILURE = 'ADD_CHART_NOTE_FAILURE';

export const DELETE_CHART_NOTE_REQUEST = 'DELETE_CHART_NOTE_REQUEST';
export const DELETE_CHART_NOTE_SUCCESS = 'DELETE_CHART_NOTE_SUCCESS';
export const DELETE_CHART_NOTE_FAILURE = 'DELETE_CHART_NOTE_FAILURE';

export const GET_CHART_PREVIOUS_VISITS_REQUEST = 'GET_CHART_PREVIOUS_VISITS_REQUEST';
export const GET_CHART_PREVIOUS_VISITS_SUCCESS = 'GET_CHART_PREVIOUS_VISITS_SUCCESS';
export const GET_CHART_PREVIOUS_VISITS_FAILURE = 'GET_CHART_PREVIOUS_VISITS_FAILURE';

export const GET_CHART_IMAGES_REQUEST = 'GET_CHART_IMAGES_REQUEST';
export const GET_CHART_IMAGES_SUCCESS = 'GET_CHART_IMAGES_SUCCESS';
export const GET_CHART_IMAGES_FAILURE = 'GET_CHART_IMAGES_FAILURE';

export const GET_PREVIOUS_VISIT_REQUEST = 'GET_PREVIOUS_VISIT_REQUEST';
export const GET_PREVIOUS_VISIT_SUCCESS = 'GET_PREVIOUS_VISIT_SUCCESS';
export const GET_PREVIOUS_VISIT_FAILURE = 'GET_PREVIOUS_VISIT_FAILURE';

export const SET_CURRENT_CHART_VISIT = 'SET_CURRENT_CHART_VISIT';
export const CLEAR_CURRENT_CHART_VISIT = 'CLEAR_CURRENT_CHART_VISIT';

export const UPDATE_VISIT_REQUEST = 'UPDATE_VISIT_REQUEST';
export const UPDATE_VISIT_SUCCESS = 'UPDATE_VISIT_SUCCESS';
export const UPDATE_VISIT_FAILURE = 'UPDATE_VISIT_FAILURE';

export const SIGN_VISIT_REQUEST = 'SIGN_VISIT_REQUEST';
export const SIGN_VISIT_SUCCESS = 'SIGN_VISIT_SUCCESS';
export const SIGN_VISIT_FAILURE = 'SIGN_VISIT_FAILURE';

export const CLEAR_CHART_PREVIOUS_VISITS = 'CLEAR_CHART_PREVIOUS_VISITS';

export const UPDATE_ADDENDUMS_REQUEST = 'UPDATE_ADDENDUMS_REQUEST';
export const UPDATE_ADDENDUMS_SUCCESS = 'UPDATE_ADDENDUMS_SUCCESS';
export const UPDATE_ADDENDUMS_FAILURE = 'UPDATE_ADDENDUMS_FAILURE';

export const SET_NOTE_VISIBILITY_REQUEST = 'SET_NOTE_VISIBILITY_REQUEST';
export const SET_NOTE_VISIBILITY_SUCCESS = 'SET_NOTE_VISIBILITY_SUCCESS';
export const SET_NOTE_VISIBILITY_FAILURE = 'SET_NOTE_VISIBILITY_FAILURE';

export const SEARCH_ALLERGIES_REQUEST = 'SEARCH_ALLERGIES_REQUEST';
export const SEARCH_ALLERGIES_SUCCESS = 'SEARCH_ALLERGIES_SUCCESS';
export const SEARCH_ALLERGIES_FAILURE = 'SEARCH_ALLERGIES_FAILURE';

export const REGEN_CHART_DOC_PASSWORD_REQUEST = 'REGEN_CHART_DOC_PASSWORD_REQUEST';
export const REGEN_CHART_DOC_PASSWORD_SUCCESS = 'REGEN_CHART_DOC_PASSWORD_SUCCESS';
export const REGEN_CHART_DOC_PASSWORD_FAILURE = 'REGEN_CHART_DOC_PASSWORD_FAILURE';

export const SET_CONFIRM_CHARGES = 'SET_CONFIRM_CHARGES';

export const getNoteTypes = () => {
  return {
    [CALL_API]: {
      types: [GET_NOTE_TYPES_REQUEST, GET_NOTE_TYPES_SUCCESS, GET_NOTE_TYPES_FAILURE],
      authenticated: true,
      endpoint: 'v1/provider/notetypes',
      method: 'GET'
    }
  };
};

export const getChartNotes = visitId => {
  return {
    [CALL_API]: {
      types: [GET_CHART_NOTES_REQUEST, GET_CHART_NOTES_SUCCESS, GET_CHART_NOTES_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}/notes`,
      method: 'GET'
    }
  };
};

export const addChartNote = (visitId, note, metadata) => {
  return {
    [CALL_API]: {
      types: [ADD_CHART_NOTE_REQUEST, ADD_CHART_NOTE_SUCCESS, ADD_CHART_NOTE_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}/notes`,
      method: 'POST',
      payload: note,
      actionMetadata: metadata
    }
  };
};

export const deleteChartNote = (visitId, noteId) => {
  return {
    [CALL_API]: {
      types: [DELETE_CHART_NOTE_REQUEST, DELETE_CHART_NOTE_SUCCESS, DELETE_CHART_NOTE_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}/notes/${noteId}`,
      method: 'DELETE',
      actionMetadata: { noteId }
    }
  };
};

export const getChartPreviousVisits = patientId => {
  return {
    [CALL_API]: {
      types: [GET_CHART_PREVIOUS_VISITS_REQUEST, GET_CHART_PREVIOUS_VISITS_SUCCESS, GET_CHART_PREVIOUS_VISITS_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/previousVisits?patientId=${patientId}`,
      method: 'GET'
    }
  };
};

export const getPreviousVisit = visitId => {
  return {
    [CALL_API]: {
      types: [GET_PREVIOUS_VISIT_REQUEST, GET_PREVIOUS_VISIT_SUCCESS, GET_PREVIOUS_VISIT_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}`,
      method: 'GET'
    }
  };
};

export const setCurrentChartVisit = visit => {
  return {
    type: SET_CURRENT_CHART_VISIT,
    payload: { visit }
  };
};

export const clearCurrentChartVisit = () => {
  return {
    type: CLEAR_CURRENT_CHART_VISIT
  };
};

export const clearPreviousVisits = () => {
  return {
    type: CLEAR_CHART_PREVIOUS_VISITS
  };
};

export const updateVisit = (visitId, payload) => {
  return {
    [CALL_API]: {
      types: [UPDATE_VISIT_REQUEST, UPDATE_VISIT_SUCCESS, UPDATE_VISIT_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}`,
      method: 'PUT',
      payload
    }
  };
};

export const signVisit = (visitId, payload) => {
  return {
    [CALL_API]: {
      types: [UPDATE_VISIT_REQUEST, UPDATE_VISIT_SUCCESS, UPDATE_VISIT_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}/sign`,
      method: 'POST',
      payload
    }
  };
};

export const updateAddendums = (visitId, payload) => {
  return {
    [CALL_API]: {
      types: [UPDATE_ADDENDUMS_REQUEST, UPDATE_ADDENDUMS_SUCCESS, UPDATE_ADDENDUMS_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}/addendums`,
      method: 'PUT',
      payload
    }
  };
};

export const setNoteVisibility = (visitId, noteId, visibleToPatient) => {
  const payload = {
    visibleToPatient
  };

  return {
    [CALL_API]: {
      types: [SET_NOTE_VISIBILITY_REQUEST, SET_NOTE_VISIBILITY_SUCCESS, SET_NOTE_VISIBILITY_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}/notes/${noteId}/visibility`,
      method: 'POST',
      payload
    }
  };
};

export const searchAllergies = searchTerm => {
  return {
    [CALL_API]: {
      types: [SEARCH_ALLERGIES_REQUEST, SEARCH_ALLERGIES_SUCCESS, SEARCH_ALLERGIES_FAILURE],
      authenticated: true,
      endpoint: `v1/allergies/search?q=${searchTerm}`,
      method: 'GET'
    }
  };
};

// set chart charges as confirmed
export const setConfirmCharges = visitId => {
  return {
    type: SET_CONFIRM_CHARGES,
    payload: {
      visitId
    }
  };
};

export const regenChartDocumentPassword = (visitId) => {
  return {
    [CALL_API]: {
      types: [REGEN_CHART_DOC_PASSWORD_REQUEST, REGEN_CHART_DOC_PASSWORD_SUCCESS, REGEN_CHART_DOC_PASSWORD_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}/password`,
      method: 'PUT',
    }
  };
};
