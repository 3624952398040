import React, { Component, Fragment } from 'react';
import { Typography, Tooltip, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import Warning from '@material-ui/icons/Warning';
import { can } from '../login/can';
import { providerGroupPermissions } from '../../types/permissionTypes';

const styles = theme => ({
  heading: {
    fontSize: '1.2rem',
    color: theme.palette.primary.darkgray
  },
  text: {
    fontSize: '1rem',
    color: theme.palette.primary.darkgray
  },
  actions: {
    display: 'flex',
    paddingTop: '1rem'
  }
});

class ChartPharmacyCard extends Component {
  render() {
    const { pharmacy, handleEditPharmacy, classes, theme, handleRx } = this.props;
    const { verified } = pharmacy;

    return (
      <Fragment>
        {can(providerGroupPermissions.readPharmacy) && (
          <Fragment>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
              <Typography className={classes.heading}>{`${isEmpty(pharmacy) ? 'No Pharmacy Selected' : 'Pharmacy'}`}</Typography>
              {!verified && !isEmpty(pharmacy) && (
                <Tooltip
                  title={
                    <Typography style={{ color: theme.palette.primary.white }}>
                      Pharmacy is not verified. Please select a new pharmacy.
                    </Typography>
                  }
                >
                  <Warning style={{ color: theme.palette.primary.red, marginLeft: '2rem' }} />
                </Tooltip>
              )}
            </div>
            {!isEmpty(pharmacy) && (
              <Fragment>
                <Typography className={classes.text}>{pharmacy.StoreName}</Typography>
                <Typography className={classes.text}>{pharmacy.Address1}</Typography>
                {!isEmpty(pharmacy.addressLine2) && <Typography className={classes.text}>{pharmacy.Address2}</Typography>}
                <Typography className={classes.text}>{`${pharmacy.City}, ${pharmacy.State} ${pharmacy.ZipCode}`}</Typography>
                {!isEmpty(pharmacy.PrimaryPhone) && <Typography className={classes.text}>{pharmacy.PrimaryPhone}</Typography>}
              </Fragment>
            )}
          </Fragment>
        )}
        <div className={classes.actions}>
          {!isEmpty(pharmacy) && verified === true && can(providerGroupPermissions.updatePrescription) && (
            <Button
              variant={'outlined'}
              style={{ color: theme.palette.primary.main, marginRight: '1rem' }}
              onClick={handleRx}
              disableRipple
            >
              {'ADD Rx'}
            </Button>
          )}
          {can(providerGroupPermissions.updatePharmacy) && (
            <Button variant={'text'} onClick={handleEditPharmacy}>
              {isEmpty(pharmacy) ? 'SELECT PHARMACY' : 'EDIT'}
            </Button>
          )}
        </div>
      </Fragment>
    );
  }
}

ChartPharmacyCard.propTypes = {
  classes: PropTypes.object,
  pharmacy: PropTypes.object,
  handleEditPharmacy: PropTypes.func.isRequired,
  handleRx: PropTypes.func.isRequired,
  isPharmacyVerified: PropTypes.bool
};

ChartPharmacyCard = withStyles(styles, { withTheme: true })(ChartPharmacyCard);

export default ChartPharmacyCard;
