import { CALL_API } from '../../middleware/api';
import { encode } from 'querystring';

export const PHARMACY_SEARCH_REQUEST = 'PHARMACY_SEARCH_REQUEST';
export const PHARMACY_SEARCH_SUCCESS = 'PHARMACY_SEARCH_SUCCESS';
export const PHARMACY_SEARCH_FAILURE = 'PHARMACY_SEARCH_FAILURE';

export const PHARMACY_SELECT_REQUEST = 'PHARMACY_SELECT_REQUEST';
export const PHARMACY_SELECT_SUCCESS = 'PHARMACY_SELECT_SUCCESS';
export const PHARMACY_SELECT_FAILURE = 'PHARMACY_SELECT_FAILURE';

export const GET_PHARMACY_REQUEST = 'GET_PHARMACY_REQUEST';
export const GET_PHARMACY_SUCCESS = 'GET_PHARMACY_SUCCESS';
export const GET_PHARMACY_FAILURE = 'GET_PHARMACY_FAILURE';

export const GET_DOSESPOT_URL_REQUEST = 'GET_DOSESPOT_URL_REQUEST';
export const GET_DOSESPOT_URL_SUCCESS = 'GET_DOSESPOT_URL_SUCCESS';
export const GET_DOSESPOT_URL_FAILURE = 'GET_DOSESPOT_URL_FAILURE';

export const REFRESH_VISIT_PRESCRIPTIONS_REQUEST = 'REFRESH_VISIT_PRESCRIPTIONS_REQUEST';
export const REFRESH_VISIT_PRESCRIPTIONS_SUCCESS = 'REFRESH_VISIT_PRESCRIPTIONS_SUCCESS';
export const REFRESH_VISIT_PRESCRIPTIONS_FAILURE = 'REFRESH_VISIT_PRESCRIPTIONS_FAILURE';

export const GET_TRANSMISSION_ERRORS_REQUEST = 'GET_TRANSMISSION_ERRORS_REQUEST';
export const GET_TRANSMISSION_ERRORS_SUCCESS = 'GET_TRANSMISSION_ERRORS_SUCCESS';
export const GET_TRANSMISSION_ERRORS_FAILURE = 'GET_TRANSMISSION_ERRORS_FAILURE';

export const CLEAR_PHARMACY = 'CLEAR_PHARMACY';

export const pharmacySearch = (visitId, criteria) => {
  const q = encode(criteria);
  return {
    [CALL_API]: {
      types: [PHARMACY_SEARCH_REQUEST, PHARMACY_SEARCH_SUCCESS, PHARMACY_SEARCH_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}/pharmacysearch?${q}`,
      method: 'GET'
    }
  };
};

export const pharmacySelect = (visitId, doseSpotId) => {
  return {
    [CALL_API]: {
      types: [PHARMACY_SELECT_REQUEST, PHARMACY_SELECT_SUCCESS, PHARMACY_SELECT_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}/pharmacy`,
      method: 'POST',
      payload: {
        doseSpotId
      }
    }
  };
};

export const getPharmacy = visitId => {
  return {
    [CALL_API]: {
      types: [GET_PHARMACY_REQUEST, GET_PHARMACY_SUCCESS, GET_PHARMACY_FAILURE],
      authenticated: true,
      endpoint: `v2/provider/visits/${visitId}/pharmacy`,
      method: 'GET'
    }
  };
};

export const clearPharmacy = () => {
  return {
    type: CLEAR_PHARMACY
  };
};

export const getDoseSpotUrl = visitId => {
  return {
    [CALL_API]: {
      types: [GET_DOSESPOT_URL_REQUEST, GET_DOSESPOT_URL_SUCCESS, GET_DOSESPOT_URL_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}/prescribe`,
      method: 'POST'
    }
  };
};

export const refreshVisitPrescriptions = visitId => {
  return {
    [CALL_API]: {
      types: [REFRESH_VISIT_PRESCRIPTIONS_REQUEST, REFRESH_VISIT_PRESCRIPTIONS_SUCCESS, REFRESH_VISIT_PRESCRIPTIONS_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}/refreshPrescriptions`,
      method: 'POST'
    }
  };
};

export const getTransmissionErrors = () => {
  return {
    [CALL_API]: {
      types: [GET_TRANSMISSION_ERRORS_REQUEST, GET_TRANSMISSION_ERRORS_SUCCESS, GET_TRANSMISSION_ERRORS_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/pharmacy/notificationcounts`,
      method: 'GET'
    }
  };
};
