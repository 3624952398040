import produce from 'immer';
import { isNil } from 'lodash';

import {
  CLEAR_LAYOUT_BEFORE_UNLOAD,
  CLEAR_SELECTED_VISIT,
  GET_MESSAGE_BANNER_SUCCESS,
  HIDE_CONFIRM_CHARGES_MODAL,
  HIDE_TOAST_MESSAGE,
  SET_BYPASS_VISIT_PROMPT,
  SET_DIRTY_FORM,
  SET_LAYOUT_BEFORE_UNLOAD,
  SET_LAYOUT_INITALIZING,
  SET_PAGE_TITLE,
  SET_RESPONSIVE_BREAKPOINT,
  SET_SELECTED_VISIT,
  SET_TOAST_POSITION,
  SHOW_CONFIRM_CHARGES_MODAL,
  SHOW_TOAST_MESSAGE,
  TOGGLE_DRAWER_MENU,
  UPDATE_LAYOUT_VISIT_STATUS,
  UPDATE_LAYOUT_VOICE_VISIT_STATUS,
} from './layout.actions';
import responsiveBreakpoints from './responsiveBreakpoints';
import { visitStatusTypes } from '../../types/visitStatusTypes';

const initialState = {
  pageTitle: 'Home',
  responsiveBreakpoint: responsiveBreakpoints.LARGE,

  handleUnload: null,
  hasDirtyForm: false,
  isBypassVisitPrompt: false,
  isDrawerMenuOpen: false,
  isInitializing: true, // default to loading until loaded
  isShowConfirmChargesModal: false,
  isShowToast: false,
  toastAction: null,
  toastMessage: '',
  toastPosition: '0',
  variant: 'error',

  // current selected visit
  selectedVisit: null,

  // message banner for outages etc
  messageBanner: null,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_TITLE:
      draft.pageTitle = action.payload;
      draft.breadcrumb = action.breadcrumb;
      draft.breadcrumbTitle = action.breadcrumbTitle;
      break;

    case SET_RESPONSIVE_BREAKPOINT:
      draft.responsiveBreakpoint = action.payload;
      break;

    case SHOW_TOAST_MESSAGE:
      draft.isShowToast = true;
      draft.toastMessage = action.message;
      draft.variant = action.variant;
      draft.toastAction = action.toastAction;
      break;

    case HIDE_TOAST_MESSAGE:
      draft.isShowToast = false;
      break;

    case SHOW_CONFIRM_CHARGES_MODAL:
      draft.isShowConfirmChargesModal = true;
      break;

    case HIDE_CONFIRM_CHARGES_MODAL:
      draft.isShowConfirmChargesModal = false;
      break;

    case SET_TOAST_POSITION:
      draft.toastPosition = action.position;
      break;

    case TOGGLE_DRAWER_MENU:
      draft.isDrawerMenuOpen = !draft.isDrawerMenuOpen;
      break;

    case SET_DIRTY_FORM:
      draft.hasDirtyForm = action.payload.flag;
      break;

    case SET_SELECTED_VISIT:
      draft.selectedVisit = action.payload.visit;
      break;

    case CLEAR_SELECTED_VISIT:
      draft.selectedVisit = null;
      break;

    case SET_LAYOUT_INITALIZING:
      draft.isInitializing = action.payload;
      break;

    case SET_LAYOUT_BEFORE_UNLOAD:
      window.addEventListener('beforeunload', action.payload);
      draft.handleUnload = action.payload;
      break;

    case CLEAR_LAYOUT_BEFORE_UNLOAD:
      window.removeEventListener('beforeunload', draft.handleUnload);
      draft.handleUnload = null;
      break;

    // update selected visit if currently selected
    // ensures fcm-based state updates actually update draft.selectedVisit
    case UPDATE_LAYOUT_VISIT_STATUS: {
      const isUpdatingSelectedVisit = !isNil(draft.selectedVisit) && draft.selectedVisit.id === action.payload.visitId;

      if (isUpdatingSelectedVisit) {
        // deselect visit if it becomes canceled
        if (action.payload.newState === visitStatusTypes.CANCELED) {
          draft.selectedVisit = null;
        } else {
          draft.selectedVisit.state = action.payload.newState;
        }
      }

      break;
    }

    case UPDATE_LAYOUT_VOICE_VISIT_STATUS: {
      const isUpdatingSelectedVisit = !isNil(draft.selectedVisit) && draft.selectedVisit.id === action.payload.visitId;

      if (isUpdatingSelectedVisit) {
        draft.selectedVisit.isUnreached = action.payload.isUnreached;
        draft.selectedVisit.voiceState = action.payload.status;
      }
      break;
    }

    case SET_BYPASS_VISIT_PROMPT:
      draft.isBypassVisitPrompt = action.payload;
      break;

    case GET_MESSAGE_BANNER_SUCCESS:
      draft.messageBanner = action.payload;
      break;

    default:
      break;
  }
}, initialState);
