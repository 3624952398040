import { CALL_API } from '../../middleware/api';
import { isEmpty } from 'lodash';
import { encode } from 'querystring';

export const GET_MY_PREVIOUS_VISITS_REQUEST = 'GET_MY_PREVIOUS_VISITS_REQUEST';
export const GET_MY_PREVIOUS_VISITS_SUCCESS = 'GET_MY_PREVIOUS_VISITS_SUCCESS';
export const GET_MY_PREVIOUS_VISITS_FAILURE = 'GET_MY_PREVIOUS_VISITS_FAILURE';

export const GET_PREVIOUS_VISITS_SEARCH_REQUEST = 'GET_PREVIOUS_VISITS_SEARCH_REQUEST';
export const GET_PREVIOUS_VISITS_SEARCH_SUCCESS = 'GET_PREVIOUS_VISITS_SEARCH_SUCCESS';
export const GET_PREVIOUS_VISITS_SEARCH_FAILURE = 'GET_PREVIOUS_VISITS_SEARCH_FAILURE';

export const getMyPreviousVisits = () => {
  return {
    [CALL_API]: {
      types: [GET_MY_PREVIOUS_VISITS_REQUEST, GET_MY_PREVIOUS_VISITS_SUCCESS, GET_MY_PREVIOUS_VISITS_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits?state=completed`,
      method: 'GET'
    }
  };
};

export const previousVisitsSearch = searchTerms => {
  // only send used terms
  let terms = {};
  if (!isEmpty(searchTerms.dob)) terms.dob = searchTerms.dob;
  if (!isEmpty(searchTerms.firstName)) terms.firstName = searchTerms.firstName;
  if (!isEmpty(searchTerms.lastName)) terms.lastName = searchTerms.lastName;
  if (!isEmpty(searchTerms.providerName)) terms.providerName = searchTerms.providerName;
  if (!isEmpty(searchTerms.visitDate)) terms.visitDate = searchTerms.visitDate;

  const query = encode(terms);

  return {
    [CALL_API]: {
      types: [GET_PREVIOUS_VISITS_SEARCH_REQUEST, GET_PREVIOUS_VISITS_SEARCH_SUCCESS, GET_PREVIOUS_VISITS_SEARCH_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visitSearch?${query}`,
      method: 'GET'
    }
  };
};
