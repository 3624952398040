import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import history from '../../history';
import BoardDataTable from './boardDataTable.component';
import { setPageTitle as setPageTitleAction, setSelectedVisit as setSelectedVisitAction } from '../layout/layout.actions';
import {
  getBoardItems as getBoardItemsAction,
  getNewBoardItems,
  getAssignedBoardItems,
  getActiveBoardItems,
  setVisitStatus as setVisitStatusAction,
  SET_VISIT_STATUS_SUCCESS,
} from './board.actions';
import MessageDialog from '../../common/messageDialog/messageDialog.component';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import { visitStatusTypes } from '../../types/visitStatusTypes';
import { sendPatientMessage as sendPatientMessageAction } from '../visit/visit.actions';
import { logEvent } from '../../utilities/googleAnalytics';

const styles = (/* theme */) => ({
  wrapper: {
    padding: '1rem',
  },
  board: {
    padding: '1rem',
    marginBottom: '1rem',
    backgroundColor: '#fff',
    boxShadow:
      '0 .085rem .12rem rgba(0, 0, 0, 0.07),\n' +
      '0 .125rem .2rem rgba(0, 0, 0, 0.07),\n' +
      '0 .25rem .4rem rgba(0, 0, 0, 0.07),\n' +
      '0 .5rem .8rem rgba(0, 0, 0, 0.07)',
  },
  boardTitle: {
    fontSize: '1.3rem',
  },
  boardDisclaimer: {
    marginTop: '.4rem',
    color: 'rgba(0, 0, 0, .75)',
  },
  container: {},
  new: {},
  assigned: {},
  active: {
    marginBottom: 0,
  },
});

class BoardContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowMessageDialog: false,
      currentRow: null,
    };
  }

  componentWillMount() {
    this.props.setPageTitle('Board');
    this.refreshBoard();
  }

  refreshBoard = () => {
    this.props.getNewBoardItems();
    this.props.getAssignedBoardItems();
    this.props.getActiveBoardItems();
  };

  handleShowMessageDialog = row => event => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      isShowMessageDialog: true,
      currentRow: row,
    });
  };

  handleCloseMessageDialog = () => {
    this.setState({
      isShowMessageDialog: false,
      currentRow: null,
    });
  };

  handleSendMessage = msg => {
    const visitId = this.state.currentRow.id;
    logEvent('message', {
      visitId,
      providerId: this.props.providerId,
    });
    this.props.sendPatientMessage(visitId, msg);
    this.handleCloseMessageDialog();
  };

  handleStartVisit = (row, action = 'view') => async event => {
    event.stopPropagation();
    event.preventDefault();

    const { setVisitStatus, setSelectedVisit } = this.props;

    const { type } = await setVisitStatus(row.id, visitStatusTypes.STARTED, false);

    if (type === SET_VISIT_STATUS_SUCCESS) {
      // send reset timestamp flag, as server resets db visit waitTime on visit becoming selected
      setSelectedVisit({ ...row, state: visitStatusTypes.STARTED, isUnreached: false }, true);
      this.refreshBoard();

      localStorage.setItem(`visit_${row.id}_action`, action);

      history.push(`/visit/${row.id}`);
    }
  };

  render() {
    const { classes, boardItems, newBoardItems, assignedBoardItems, activeBoardItems, isLoading, selectedVisit } = this.props;
    const { isShowMessageDialog } = this.state;

    // if (isLoading) {
    //   return <LoadingOverlay />;
    // }

    return (
      <div className={classNames(classes.wrapper)}>
        <div className={classNames(classes.board, classes.new)}>
          <div className={classNames(classes.boardTitle)}>Checked In</div>
          <div className={classNames(classes.boardDisclaimer)}>
            Patients in this room are available to be picked-up by you or any members of your provider group
          </div>
          <div className={classNames([classes.container])}>
            <BoardDataTable
              data={newBoardItems.data}
              selectedVisit={selectedVisit}
              handleShowMessageDialog={this.handleShowMessageDialog}
              handleStartVisit={this.handleStartVisit}
            />
            <MessageDialog open={isShowMessageDialog} handleClose={this.handleCloseMessageDialog} handleAction={this.handleSendMessage} />
          </div>
        </div>

        <div className={classNames(classes.board, classes.assigned)}>
          <div className={classNames(classes.boardTitle)}>Assigned Visits</div>
          <div className={classNames(classes.boardDisclaimer)}>Patients in this room have been explicitly assigned to you</div>
          <div className={classNames([classes.container])}>
            <BoardDataTable
              data={assignedBoardItems.data}
              selectedVisit={selectedVisit}
              handleShowMessageDialog={this.handleShowMessageDialog}
              handleStartVisit={this.handleStartVisit}
            />
            <MessageDialog open={isShowMessageDialog} handleClose={this.handleCloseMessageDialog} handleAction={this.handleSendMessage} />
          </div>
        </div>

        <div className={classNames(classes.board, classes.active)}>
          <div className={classNames(classes.boardTitle)}>Active Visits</div>
          <div className={classNames(classes.boardDisclaimer)}>Patients in this room may be in an active session or have an open chart</div>
          <div className={classNames([classes.container])}>
            <BoardDataTable
              data={activeBoardItems.data}
              selectedVisit={selectedVisit}
              handleShowMessageDialog={this.handleShowMessageDialog}
              handleStartVisit={this.handleStartVisit}
            />
            <MessageDialog open={isShowMessageDialog} handleClose={this.handleCloseMessageDialog} handleAction={this.handleSendMessage} />
          </div>
        </div>
      </div>
    );
  }
}

BoardContainer.propTypes = {
  classes: PropTypes.object.isRequired,

  boardItems: PropTypes.array.isRequired,
  newBoardItems: PropTypes.object,
  assignedBoardItems: PropTypes.object,
  activeBoardItems: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  providerId: PropTypes.string,
  selectedVisit: PropTypes.object,

  getBoardItems: PropTypes.func.isRequired,
  getNewBoardItems: PropTypes.func.isRequired,
  getAssignedBoardItems: PropTypes.func.isRequired,
  getActiveBoardItems: PropTypes.func.isRequired,
  sendPatientMessage: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  setSelectedVisit: PropTypes.func.isRequired,
  setVisitStatus: PropTypes.func.isRequired,
};

BoardContainer.defaultProps = {
  providerId: null,
  selectedVisit: null,
};

const mapStateToProps = state => ({
  boardItems: state.board.boardItems.filter(item => item.state === visitStatusTypes.NEW || item.state === visitStatusTypes.ASSIGNED),
  newBoardItems: state.board.newBoardItems,
  assignedBoardItems: state.board.assignedBoardItems,
  activeBoardItems: state.board.activeBoardItems,
  isLoading: state.board.isLoading,
  providerId: state.provider.providerId,
  selectedVisit: state.layout.selectedVisit,
});

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, {
      getBoardItems: getBoardItemsAction,
      getNewBoardItems,
      getAssignedBoardItems,
      getActiveBoardItems,
      sendPatientMessage: sendPatientMessageAction,
      setPageTitle: setPageTitleAction,
      setSelectedVisit: setSelectedVisitAction,
      setVisitStatus: setVisitStatusAction,
    })(BoardContainer)
  )
);
