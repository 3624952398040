import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const COLUMN_HEADER_FONT_SIZE = '1rem';
export const ACTION_COLUMN_KEY = 'action_column';

const DataTableHead = ({ columns, order, orderBy, onRequestSort }) => {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map(
          column => (
            <TableCell
              key={column.id}
              align={column.numeric ? 'right' : 'left'}
              padding={column.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === column.id ? order : false}
            >
              {column.sortable ? (
                <Tooltip title="Sort" placement={column.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={createSortHandler(column.id)}
                    style={{ fontSize: COLUMN_HEADER_FONT_SIZE }}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              ) : (
                <div style={{ fontSize: COLUMN_HEADER_FONT_SIZE }}>{column.label}</div>
              )}
            </TableCell>
          ),
          this
        )}
      </TableRow>
    </TableHead>
  );
};

DataTableHead.propTypes = {
  columns: PropTypes.array.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,

  onRequestSort: PropTypes.func.isRequired,
};

export default DataTableHead;
