import React, { Component } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';

const DATE_FORMAT = 'MM/DD/YYYY';

const styles = theme => ({
  segmentWrapper: {
    marginBottom: '1rem'
  }
});

class ChartPrescriptionInfo extends Component {
  getPharmcyAddress(pharmacy) {
    const { Address1, Address2, City, State, ZipCode } = pharmacy;

    return `${Address1} ${Address2 ? `, ${Address2}` : ''} ${City}, ${State} ${ZipCode}`;
  }

  render() {
    const { classes, prescription } = this.props;

    return (
      <div>
        <div className={classes.segmentWrapper}>
          {prescription.DisplayName && <Typography variant="h6">{prescription.DisplayName}</Typography>}
          {prescription.GenericProductName && <Typography variant="body1">Generic Name: {prescription.GenericProductName}</Typography>}
        </div>

        {prescription.DateInactive && (
          <div className={classes.segmentWrapper}>
            <Typography variant="body1">Date Inactive: {moment(prescription.DateInactive).format(DATE_FORMAT)}</Typography>
          </div>
        )}

        <div className={classes.segmentWrapper}>
          {prescription.Quantity && <Typography variant="body1">Quantity: {prescription.Quantity}</Typography>}
          {prescription.DaysSupply && <Typography variant="body1">Days Supply: {prescription.DaysSupply}</Typography>}
          {prescription.DispenseUnitDescription && (
            <Typography variant="body1">Dispense Unit: {prescription.DispenseUnitDescription}</Typography>
          )}
          {prescription.Refills && <Typography variant="body1">Refills: {prescription.Refills}</Typography>}
          {prescription.OTC !== null && <Typography variant="body1">OTC: {prescription.OTC ? 'Yes' : 'No'}</Typography>}
        </div>

        <div className={classes.segmentWrapper}>
          {prescription.Directions && <Typography variant="body1">Directions: {prescription.Directions}</Typography>}
          {prescription.Comment && <Typography variant="body1">Comment: {prescription.Comment}</Typography>}
          {prescription.PharmacyNotes && <Typography variant="body1">Pharmacy Notes: {prescription.PharmacyNotes}</Typography>}
        </div>

        {prescription.pharmacy && (
          <div className={classes.segmentWrapper}>
            <Typography variant="body1">Pharmacy: {prescription.pharmacy.StoreName}</Typography>
            <Typography variant="body1">Address: {this.getPharmcyAddress(prescription.pharmacy)}</Typography>
          </div>
        )}
      </div>
    );
  }
}

ChartPrescriptionInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  prescription: PropTypes.object.isRequired
};

export default withStyles(styles)(ChartPrescriptionInfo);
