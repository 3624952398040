const MapRouteTitle = location => {
  let pathname = location.pathname;

  function locate(route) {
    return pathname.indexOf(route) !== -1;
  }

  if (locate('board')) {
    return 'Board';
  } else if (locate('previous')) {
    return 'Previous';
  } else if (locate('providers')) {
    return 'Providers';
  } else if (locate('resources')) {
    return 'Resources';
  } else if (locate('login')) {
    return 'Login';
  }

  return 'Board';
};

export default MapRouteTitle;
