import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles, TableRow, TableCell, Icon, Collapse, Typography, Tooltip } from '@material-ui/core';
import ChatBubble from '@material-ui/icons/ChatBubble';
import Phone from '@material-ui/icons/Phone';
import Video from '@material-ui/icons/Videocam';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import classNames from 'classnames';
import { isNil } from 'lodash';
import moment from 'moment';
import history from '../../history';
import BoardRowExpansionPanel from './boardRowExpansionPanel.component';
import { getShortGender } from '../../types/genderTypes';
import { getChartPreviousVisits, GET_CHART_PREVIOUS_VISITS_SUCCESS } from '../chart/chart.actions';
import Timer from '../../common/timer/timer.component';
import { getAgeString } from '../../utilities/dataUtils';
import { can } from '../login/can';
import { providerGroupPermissions } from '../../types/permissionTypes';
import DataTable from '../../common/dataTable/dataTable.component';
import Colors from '../../styles/colors';
import BoardStartCallButton from './boardStartCallButton.component';
import { ACTION_COLUMN_KEY } from '../../common/dataTable/dataTableHead.component';
import RelyMDPrimaryButton from '../../common/buttons/relyMDPrimaryButton.component';
import { DATE_FIELD_FORMAT } from '../../utilities/dateUtils';
import { visitStatusTypes } from '../../types/visitStatusTypes';
import { setSelectedVisit } from '../layout/layout.actions';
const MESSAGE_BUTTON_WIDTH_REM = 4;
export const START_CALL_BUTTON_WIDTH_REM = 11.5;
const ASSIGN_ICON_WIDTH_REM = 2;

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  { id: ACTION_COLUMN_KEY }, // This allows us to not have a header for the actions
  { id: 'lastName', numeric: false, disablePadding: false, label: 'Name', sortable: true },
  { id: 'dob', numeric: false, disablePadding: false, label: 'Age/Gen', sortable: true },
  { id: 'waitTime', numeric: false, disablePadding: false, label: 'Wait', sortable: true },
  { id: 'group', numeric: false, disablePadding: false, label: 'Group/Facility', sortable: true },
  { id: 'entry', numeric: false, disablePadding: false, label: 'Entry', sortable: true },
  { id: 'pricing', numeric: false, disablePadding: false, label: 'Coupon/Employer', sortable: true },
  { id: 'location', numeric: false, disablePadding: false, label: 'Location', sortable: true },
  { id: 'chiefComplaint', numeric: false, disablePadding: false, label: 'Chief Complaint' },
];

class BoardDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedRowId: null,
      currentPreviousVisits: [],
    };
  }

  handleRowClick = async (event, row) => {
    event.stopPropagation();
    event.preventDefault();

    // collapse already-open row
    if (row.id === this.state.expandedRowId) {
      this.setState({
        expandedRowId: null,
        currentPreviousVisits: [],
      });
    } else if (this.state.expandedRowId) {
      // finish collapsing one row before expanding another
      this.setState({
        expandedRowId: null,
        currentPreviousVisits: [],
      });

      setTimeout(async () => {
        this.setState(prevState => ({
          expandedRowId: row.id === prevState.expandedRowId ? null : row.id,
        }));

        const response = await this.props.getChartPreviousVisits(row.patientId);
        if (response.type === GET_CHART_PREVIOUS_VISITS_SUCCESS) {
          this.setState({
            currentPreviousVisits: this.props.previousVisits,
          });
        }
      }, 150);
    } else {
      // expand an unexpanded row
      this.setState({
        expandedRowId: row.id,
      });

      const response = await this.props.getChartPreviousVisits(row.patientId);
      if (response.type === GET_CHART_PREVIOUS_VISITS_SUCCESS) {
        this.setState({
          currentPreviousVisits: this.props.previousVisits,
        });
      }
    }
  };

  // reset row expansion any time data changes in table
  handleTablePagingChange = () => {
    this.setState({
      expandedRowId: null,
    });
  };

  collapseComponent = ({ className, children }) => {
    const { classes } = this.props;

    return (
      <TableRow>
        <td className={classes.collapse} colSpan={columns.length + 1}>
          <div className={className}>{children}</div>
        </td>
      </TableRow>
    );
  };

  getFacilityComponent = name => {
    const { classes } = this.props;

    return (
      <div className={classes.facility}>
        <Typography>{name}</Typography>
        <Icon className={classNames(classes.facilityIcon, 'fal fa-hospital-user')} />
      </div>
    );
  };

  getPricing = row => {
    return `${row.coupon ? row.coupon.name : ''}${row.coupon && row.pricingGroup ? ' / ' : ''}${
      row.pricingGroup ? row.pricingGroup.name : ''
    }`;
  };

  // get custom row render component
  renderRow = (row, i) => {
    const { currentPreviousVisits } = this.state;
    const isRowExpanded = row.id === this.state.expandedRowId;

    const gender = getShortGender(row.gender);
    const ageString = getAgeString(row.dob);
    const { classes, selectedVisit, handleShowMessageDialog, handleStartVisit, setSelectedVisit } = this.props;

    return (
      <Fragment key={row.id}>
        <TableRow
          onClick={event => this.handleRowClick(event, row)}
          role="checkbox"
          tabIndex={-1}
          classes={{
            root: i % 2 === 0 ? classNames([classes.tableRowRoot, classes.rowHighlight]) : classes.tableRowRoot,
          }}
          key={row.id}
        >
          <TableCell align="left" classes={{ root: classes.startContainer }}>
            <div className={classes.actionsContainer}>
              {(row.state === visitStatusTypes.NEW || row.state === visitStatusTypes.ASSIGNED) && (
                <>
                  <RelyMDPrimaryButton
                    className={classNames(classes.button)}
                    hasSelectedVisit={!isNil(selectedVisit)}
                    visit={row}
                    onClick={handleStartVisit(row, 'video')}
                  >
                    <div className={classNames(classes.startBtnIcon)}>
                      <Video className={classNames(classes.icon)} />
                    </div>
                    <div className={classNames(classes.startBtnText)}>start</div>
                  </RelyMDPrimaryButton>
                  <RelyMDPrimaryButton
                    className={classNames(classes.button)}
                    hasSelectedVisit={!isNil(selectedVisit)}
                    visit={row}
                    onClick={handleStartVisit(row, 'phone')}
                  >
                    <div className={classNames(classes.startBtnIcon)}>
                      <Phone className={classNames(classes.icon)} />
                    </div>
                    <div className={classNames(classes.startBtnText)}>start</div>
                  </RelyMDPrimaryButton>
                  {!row.isVoiceCall && can(providerGroupPermissions.createPatientMessage, row.entranceId) && (
                    <RelyMDPrimaryButton
                      className={classes.chatButton}
                      onClick={handleShowMessageDialog(row)}
                      className={classNames(classes.button)}
                    >
                      <ChatBubble />
                    </RelyMDPrimaryButton>
                  )}
                </>
              )}

              {row.state === visitStatusTypes.STARTED && (
                <RelyMDPrimaryButton
                  className={classNames(classes.button)}
                  hasSelectedVisit={!isNil(selectedVisit)}
                  visit={row}
                  onClick={() => {
                    setSelectedVisit(row, false);
                    history.push(`/visit/${row.id}`);
                  }}
                >
                  view
                </RelyMDPrimaryButton>
              )}

              {row.isVoiceCall && row.isUnreached && (
                <div className={classes.unreached}>
                  <Tooltip
                    title={`Marked as unreached on ${moment(row.isUnreached).format(DATE_FIELD_FORMAT)} at ${moment(row.isUnreached).format(
                      'hh:mm a'
                    )}`}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Icon className={classNames(classes.unreachedIcon, 'fal fa-phone-slash')} />
                  </Tooltip>
                </div>
              )}
            </div>
          </TableCell>
          <TableCell>
            <div className={classes.collapseCell}>
              {isRowExpanded ? <ArrowDown /> : <ArrowRight />}
              <div>{`${row.firstName} ${row.lastName}`}</div>
            </div>
          </TableCell>
          <TableCell align="left">{`${ageString}/${gender}`}</TableCell>
          <TableCell align="left" key={row.id}>
            <Timer key={row.id} startTime={row.waitStartTimestamp} />
          </TableCell>
          <TableCell align="left">{!isNil(row.facility) ? this.getFacilityComponent(row.facility.name) : row.group}</TableCell>
          <TableCell align="left">{row.entry}</TableCell>
          <TableCell align="left">{this.getPricing(row)}</TableCell>
          <TableCell align="left">{row.location.addressState ? row.location.addressState : 'Unknown'}</TableCell>
          <TableCell align="left">{`"${row.chiefComplaint}"`}</TableCell>
        </TableRow>
        <Collapse component={this.collapseComponent} in={isRowExpanded} timeout="auto" unmountOnExit>
          <BoardRowExpansionPanel data={row} previousVisits={currentPreviousVisits} />
        </Collapse>
      </Fragment>
    );
  };

  render() {
    const { data } = this.props;

    return (
      <DataTable
        data={data}
        title=""
        columns={columns}
        renderRow={this.renderRow}
        disableSearch
        onPagingChanged={this.handleTablePagingChange}
        defaultSortOrder="asc"
        defaultSortField="waitTime"
      />
    );
  }
}

const styles = theme => ({
  tableRowRoot: {
    cursor: 'pointer !important',
  },
  rowHighlight: {
    backgroundColor: theme.palette.primary.lightgray,
  },
  facilityIcon: {
    color: Colors.primary.orange,
    marginLeft: '0.5rem',
    overflow: 'visible',
  },
  collapse: {
    backgroundColor: theme.palette.primary.white,
  },
  facility: {
    alignItems: 'center',
    display: 'flex',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    // width: `${MESSAGE_BUTTON_WIDTH_REM + START_CALL_BUTTON_WIDTH_REM + ASSIGN_ICON_WIDTH_REM + 1}rem`, // plus 1 in order to allow spacing between
  },
  button: {
    marginRight: '1rem',
    padding: '5px 12px',
    lineHeight: 1,
  },
  startBtnIcon: {
    display: 'inline-block',
    lineHeight: 1,
    verticalAlign: 'middle',
  },
  startBtnText: {
    display: 'inline-block',
    lineHeight: 1,
    verticalAlign: 'middle',
    paddingLeft: '.5rem',
  },
  icon: {
    fontSize: '1.4rem',
  },
  chatButton: {
    width: `${MESSAGE_BUTTON_WIDTH_REM}rem`,
  },
  unreached: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: `${MESSAGE_BUTTON_WIDTH_REM}rem`,
  },
  assigned: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: `${ASSIGN_ICON_WIDTH_REM}rem`,
  },
  assignedIcon: {
    color: theme.palette.primary.main,
  },
  unreachedIcon: {
    color: theme.palette.primary.red,
    paddingRight: theme.spacing.unit * 2,
  },
  collapseCell: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: -24,
  },
  startContainer: {
    paddingRight: 24,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.red,
    fontSize: '1rem',
    maxWidth: 'none',
  },
  assignTooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '1rem',
    maxWidth: 'none',
  },
});

BoardDataTable.propTypes = {
  classes: PropTypes.object.isRequired,

  data: PropTypes.array.isRequired,
  previousVisits: PropTypes.array.isRequired,
  selectedVisit: PropTypes.object,

  getChartPreviousVisits: PropTypes.func.isRequired,
  handleShowMessageDialog: PropTypes.func.isRequired,
  handleStartVisit: PropTypes.func.isRequired,
  setSelectedVisit: PropTypes.func.isRequired,
};

BoardDataTable.defaultProps = {
  selectedVisit: null,
};

const mapStateToProps = state => {
  return {
    previousVisits: state.chart.previousVisits,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    getChartPreviousVisits,
    setSelectedVisit,
  })(BoardDataTable)
);
