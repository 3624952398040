import { connect } from 'react-redux';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

class ResourcesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  componentWillMount() {
    this.props.setPageTitle('Resources');
  }

  render() {
    const { classes, groups } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.container}>
        <AppBar position="static" color="default">
          <Tabs value={value} onChange={this.handleChange}>
            {groups.map((group, i) => (
              <Tab label={group.name} key={i} />
            ))}
          </Tabs>
          <iframe title={groups[value].name} src={groups[value].resourceUrl} style={{ height: '80vh' }} />
        </AppBar>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    padding: '1rem',
    textAlign: 'left'
  }
});

const mapStateToProps = state => {
  return {
    groups: state.provider.profile.groups
  };
};

ResourcesContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        setPageTitle
      }
    )(ResourcesContainer)
  )
);
