import { CALL_API } from '../../middleware/api';

export const GET_TWILIO_VIDEO_AUTH_REQUEST = 'GET_TWILIO_VIDEO_AUTH_REQUEST';
export const GET_TWILIO_VIDEO_AUTH_SUCCESS = 'GET_TWILIO_VIDEO_AUTH_SUCCESS';
export const GET_TWILIO_VIDEO_AUTH_FAILURE = 'GET_TWILIO_VIDEO_AUTH_FAILURE';

export const SEND_PATIENT_MESSAGE_REQUEST = 'SEND_PATIENT_MESSAGE_REQUEST';
export const SEND_PATIENT_MESSAGE_SUCCESS = 'SEND_PATIENT_MESSAGE_SUCCESS';
export const SEND_PATIENT_MESSAGE_FAILURE = 'SEND_PATIENT_MESSAGE_FAILURE';

export const CONFIRM_VISIT_CHARGE_REQUEST = 'CONFIRM_VISIT_CHARGE_REQUEST';
export const CONFIRM_VISIT_CHARGE_SUCCESS = 'CONFIRM_VISIT_CHARGE_SUCCESS';
export const CONFIRM_VISIT_CHARGE_FAILURE = 'CONFIRM_VISIT_CHARGE_FAILURE';

export const CALL_PATIENT_REQUEST = 'CALL_PATIENT_REQUEST';
export const CALL_PATIENT_SUCCESS = 'CALL_PATIENT_SUCCESS';
export const CALL_PATIENT_FAILURE = 'CALL_PATIENT_FAILURE';

export const INITIATE_PHONE_CALL_REQUEST = 'INITIATE_PHONE_CALL_REQUEST';
export const INITIATE_PHONE_CALL_SUCCESS = 'INITIATE_PHONE_CALL_SUCCESS';
export const INITIATE_PHONE_CALL_FAILURE = 'INITIATE_PHONE_CALL_FAILURE';

export const PHONE_CALL_STATUS_REQUEST = 'PHONE_CALL_STATUS_REQUEST';
export const PHONE_CALL_STATUS_SUCCESS = 'PHONE_CALL_STATUS_SUCCESS';
export const PHONE_CALL_STATUS_FAILURE = 'PHONE_CALL_STATUS_FAILURE';

export const SET_VIDEO_CONNECTION_STATUS = 'SET_VIDEO_CONNECTION_STATUS';

export const getTwilioVideoAuth = visitId => {
  return {
    [CALL_API]: {
      types: [GET_TWILIO_VIDEO_AUTH_REQUEST, GET_TWILIO_VIDEO_AUTH_SUCCESS, GET_TWILIO_VIDEO_AUTH_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}/video/token`,
      method: 'GET',
    },
  };
};

export const sendPatientMessage = (visitId, msg) => {
  return {
    [CALL_API]: {
      types: [SEND_PATIENT_MESSAGE_REQUEST, SEND_PATIENT_MESSAGE_SUCCESS, SEND_PATIENT_MESSAGE_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}/messaging`,
      method: 'POST',
      payload: {
        body: msg,
      },
    },
  };
};

// payload contains optional reason and suggestedPrice
// if no reason or suggested price, we use authorized price
export const confirmVisitCharge = (visitId, payload) => {
  return {
    [CALL_API]: {
      types: [CONFIRM_VISIT_CHARGE_REQUEST, CONFIRM_VISIT_CHARGE_SUCCESS, CONFIRM_VISIT_CHARGE_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}/charge`,
      method: 'POST',
      payload,
    },
  };
};

export const callPatient = visitId => {
  return {
    [CALL_API]: {
      types: [CALL_PATIENT_REQUEST, CALL_PATIENT_SUCCESS, CALL_PATIENT_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}/callPatient`,
      method: 'POST',
    },
  };
};

export const initiatePhoneCall = visitId => {
  return {
    [CALL_API]: {
      types: [INITIATE_PHONE_CALL_REQUEST, INITIATE_PHONE_CALL_SUCCESS, INITIATE_PHONE_CALL_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}/calls`,
      method: 'POST',
    },
  };
};

export const phoneCallStatus = visitId => {
  return {
    [CALL_API]: {
      types: [PHONE_CALL_STATUS_REQUEST, PHONE_CALL_STATUS_SUCCESS, PHONE_CALL_STATUS_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}/call-status`,
      method: 'GET',
    },
  };
};

export const setVideoConnectionStatus = payload => {
  return {
    type: SET_VIDEO_CONNECTION_STATUS,
    payload,
  };
};
