import moment from 'moment';
import { isNil } from 'lodash';

// display months if someone is under this # years old
const MIN_YEARS_TO_SHOW_MONTHS = 2;

export const getAgeString = dob => {
  const ageDiff = moment().diff(dob, 'years');
  return `${moment().diff(dob, ageDiff < MIN_YEARS_TO_SHOW_MONTHS ? 'months' : 'years')} ${
    ageDiff < MIN_YEARS_TO_SHOW_MONTHS ? 'mo' : 'yr'
  }`;
};

// return formatted price
// optionally provide numDecimals
export const getFormattedPrice = (price, numDecimals) => {
  if (!isNil(numDecimals)) {
    return `${(price / 100).toFixed(numDecimals)}`;
  }
  const remainder = price % 100;
  if (remainder > 0) {
    return `${(price / 100).toFixed(2)}`;
  } else {
    return `${(price / 100).toFixed(0)}`;
  }
};

export const getPriceInCents = dollars => {
  return dollars * 100;
};

const ALLERGIES_LABEL = 'Name';
const ALLERGIES_VALUE = 'Code';

// get display label and value either from server or from page update
// data: array of json objects formatted with key names (i.e. not NIH response)
export const mapAllergyJsonToSelectOptions = data => {
  return data.map(item => {
    const mapObj = { ...item };
    if (mapObj.isManualEntry === true) {
      // manually entered value
      // could come from server or from page edit
      if (mapObj.text && mapObj.id) {
        mapObj.label = mapObj.text;
        mapObj.value = mapObj.id;
      } else if (mapObj.text) {
        mapObj.label = mapObj.text;
        mapObj.value = mapObj.text;
      }
    } else {
      // provide label and value for select fields
      mapObj.label = item[ALLERGIES_LABEL];
      mapObj.value = item[ALLERGIES_VALUE];
    }
    return mapObj;
  });
};

// convert allergy data to select options
export const mapAllergyDataToSelectOptions = allergies => {
  allergies.forEach(allergy => {
    allergy.label = allergy.Name;
    allergy.value = allergy.Code;
  });
  return allergies;
};

// map json objects to list of labels
// input: data array of objects
// output: string
export const mapAllergyJsonToLabelList = data => {
  if (!data || !data.length > 0) return [];

  return data
    .map(item => {
      let label = item[ALLERGIES_LABEL];
      return label ? label : item.label ? item.label : item.text ? item.text : '';
    })
    .join(', ');
};

// input: start ms, end ms
// returns whether now is in that timerange
// if an end time is not provided, only check start time
export const isInTimeRange = (start, end = null) => {
  const nowMs = moment().utc().valueOf();

  if (isNil(end)) {
    const startMs = moment(start).valueOf();
    return nowMs >= startMs;
  } else {
    const startMs = moment(start).valueOf();
    const endMs = moment(end).valueOf();
    return nowMs >= startMs && nowMs <= endMs;
  }
};

export default { getAgeString, getFormattedPrice, getPriceInCents, mapAllergyDataToSelectOptions, isInTimeRange };
