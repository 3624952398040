import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { isNil } from 'lodash';
import { compose } from 'recompose';
import ReactRouterPropTypes from 'react-router-prop-types';
import moment from 'moment';

import MiniBoardCard from './miniBoardCard.component';
import MessageDialog from '../../common/messageDialog/messageDialog.component';
import { visitStatusTypes } from '../../types/visitStatusTypes';
import CustomDialog from '../../common/customDialog/customDialog.component';
import { setVisitStatus as setVisitStatusAction, getBoardItems as getBoardItemsAction, SET_VISIT_STATUS_SUCCESS } from './board.actions';
import {
  clearSelectedVisit as clearSelectedVisitAction,
  setSelectedVisit as setSelectedVisitAction,
  showConfirmChargesModal as showConfirmChargesModalAction,
  showToast as showToastAction,
} from '../layout/layout.actions';
import { sendPatientMessage as sendPatientMessageAction, callPatient as callPatientAction } from '../visit/visit.actions';
import { logEvent } from '../../utilities/googleAnalytics';
import { hasWebcam, hasMicrophone } from '../../utilities/hardwareUtils';

class MiniBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowCancelVisitDialog: false,
      isShowMessageDialog: false,
      isShowReturnToWaitingRoomDialog: false,
      isUnreached: false,
      selectedVisit: null,
    };
  }

  handleToggleWaitingRoomDialog = unreached =>
    this.setState(prevState => ({
      isShowReturnToWaitingRoomDialog: !prevState.isShowReturnToWaitingRoomDialog,
      isUnreached: unreached,
    }));

  handleReturnToWaitingRoom = async () => {
    const {
      providerId,
      selectedVisit: { id: visitId, state },
      clearSelectedVisit,
      getBoardItems,
      setVisitStatus,
    } = this.props;

    logEvent('return_waitingroom', {
      providerId,
      visitId,
      waitingState: state === visitStatusTypes.SELECTED ? 'waiting' : 'started',
    });

    this.handleToggleWaitingRoomDialog();

    await setVisitStatus(visitId, visitStatusTypes.NEW, this.state.isUnreached);
    clearSelectedVisit();
    getBoardItems();
  };

  handleToggleCancelDialog = isCancelButStillChart => () =>
    this.setState(prevState => ({
      isShowCancelVisitDialog: !prevState.isShowCancelVisitDialog,
      isCancelButStillChart,
    }));

  handleCancelVisit = async () => {
    const {
      history,
      providerId,
      selectedVisit: { id: visitId, facility, state },
      clearSelectedVisit,
      getBoardItems,
      setVisitStatus,
      showConfirmChargesModal,
      showToast,
    } = this.props;

    // used for event logging
    const waitingState = state === visitStatusTypes.SELECTED ? 'waiting' : 'started';

    this.handleToggleCancelDialog()();
    if (this.state.isCancelButStillChart) {
      logEvent('cancel_visit_chart', {
        providerId,
        visitId,
        waitingState,
      });

      const isFacilitatedVisit = !isNil(facility);

      const { type } = await setVisitStatus(visitId, visitStatusTypes.COMPLETED);

      if (type === SET_VISIT_STATUS_SUCCESS) {
        clearSelectedVisit();
        // RMD-1778 only show confirm charges modal on non-facilitated visit
        // facilitated visits do not contain pricing information required for the modal
        if (!isFacilitatedVisit) {
          showConfirmChargesModal();
        }
        history.push(`/previous/${visitId}`);
      } else {
        showToast('Could not cancel visit. Please check your connection and try again.');
      }
    } else {
      logEvent('cancel_visit', {
        providerId,
        visitId,
        waitingState,
      });

      await setVisitStatus(visitId, visitStatusTypes.CANCELED);
      clearSelectedVisit();
      getBoardItems();
    }
  };

  handleStartVisit = async () => {
    const { providerId, selectedVisit, setSelectedVisit, setVisitStatus } = this.props;

    logEvent('startvisit', {
      visitId: selectedVisit.id,
      providerId,
    });

    const { type } = await setVisitStatus(selectedVisit.id, visitStatusTypes.STARTED);

    if (type === SET_VISIT_STATUS_SUCCESS) {
      setSelectedVisit({ ...selectedVisit, state: visitStatusTypes.STARTED }, true);
    }
  };

  handleSelectVisit = visit => async () => {
    const { setSelectedVisit, setVisitStatus } = this.props;

    await setVisitStatus(visit.id, visitStatusTypes.SELECTED, false);
    setSelectedVisit({ ...visit, state: visitStatusTypes.SELECTED, isUnreached: false }, true);
  };

  handleToggleMessageDialog = selectedVisit => () =>
    this.setState(prevState => ({
      isShowMessageDialog: !prevState.isShowMessageDialog,
      selectedVisit,
    }));

  handleSendMessage = msg => {
    const { providerId, sendPatientMessage } = this.props;
    const {
      selectedVisit: { id: visitId },
    } = this.state;

    logEvent('message', {
      visitId,
      providerId,
    });

    sendPatientMessage(visitId, msg);
    this.handleToggleMessageDialog(null)();
  };

  render() {
    const { classes, boardItems, selectedVisit, callPatient } = this.props;
    const { isShowMessageDialog, isShowReturnToWaitingRoomDialog, isShowCancelVisitDialog, isUnreached } = this.state;

    // sort by ready state first, wait time asc second
    const sortedBoardItems = boardItems.slice().sort((a, b) => {
      return moment(a.waitTime) - moment(b.waitTime);
    });

    // move ready visit to top of sorted list regardless of wait time
    const readyVisitIndex = sortedBoardItems.findIndex(item => item.state === visitStatusTypes.READY);

    if (readyVisitIndex > -1) {
      const item = sortedBoardItems[readyVisitIndex];
      sortedBoardItems.splice(readyVisitIndex, 1);
      sortedBoardItems.splice(0, 0, item);
    }

    return (
      <div className="page-padding-bottom" elevation={1}>
        {sortedBoardItems.length <= 0 && (
          <Typography className={classes.emptyBoard} variant="h6">
            No Patients on the Board
          </Typography>
        )}
        {sortedBoardItems.map(boardItem => (
          <div className={classes.cardContainer} key={boardItem.id}>
            <MiniBoardCard
              boardItem={!isNil(selectedVisit) && boardItem.id === selectedVisit.id ? selectedVisit : boardItem}
              isDisableStart={!isNil(selectedVisit) || !hasWebcam || !hasMicrophone}
              isReady={boardItem.state === visitStatusTypes.READY}
              isSelected={boardItem.state === visitStatusTypes.SELECTED}
              isStarted={boardItems.state === visitStatusTypes.STARTED}
              onCancelVisitClick={this.handleToggleCancelDialog}
              onRedialClick={() => callPatient(selectedVisit.id)}
              onSelectVisitClick={this.handleSelectVisit}
              onSendMessageClick={this.handleToggleMessageDialog}
              onStartVisitClick={this.handleStartVisit}
              onWaitingRoomClick={this.handleToggleWaitingRoomDialog}
            />
          </div>
        ))}
        <MessageDialog
          open={isShowMessageDialog}
          handleClose={this.handleToggleMessageDialog(null)}
          handleAction={this.handleSendMessage}
        />
        <CustomDialog
          content={
            <Typography variant="subtitle1">
              {isUnreached
                ? 'Are you sure you want to return the patient to the waiting room as unreached?'
                : 'Are you sure you want to return the patient to the waiting room?'}
            </Typography>
          }
          open={isShowReturnToWaitingRoomDialog}
          title="Return to Waiting Room"
          handleAction={this.handleReturnToWaitingRoom}
          handleClose={() => this.handleToggleWaitingRoomDialog()}
        />
        <CustomDialog
          content={<Typography variant="subtitle1">Are you sure you want to cancel this visit?</Typography>}
          open={isShowCancelVisitDialog}
          title={this.state.isCancelButStillChart ? 'Cancel Visit But Still Chart' : 'Cancel Visit'}
          handleAction={this.handleCancelVisit}
          handleClose={this.handleToggleCancelDialog(false)}
        />
      </div>
    );
  }
}

const styles = theme => ({
  cardContainer: {
    padding: '1rem 1rem 0 1rem',
  },
  emptyBoard: {
    color: theme.palette.primary.darkgray,
    padding: '1rem',
  },
});

MiniBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,

  boardItems: PropTypes.array.isRequired,
  providerId: PropTypes.string.isRequired,
  selectedVisit: PropTypes.object,

  callPatient: PropTypes.func.isRequired,
  clearSelectedVisit: PropTypes.func.isRequired,
  getBoardItems: PropTypes.func.isRequired,
  sendPatientMessage: PropTypes.func.isRequired,
  setSelectedVisit: PropTypes.func.isRequired,
  setVisitStatus: PropTypes.func.isRequired,
  showConfirmChargesModal: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

MiniBoard.defaultProps = {
  selectedVisit: null,
};

const mapStateToProps = state => {
  return {
    selectedVisit: state.layout.selectedVisit,
    providerId: state.provider.providerId,
  };
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    callPatient: callPatientAction,
    clearSelectedVisit: clearSelectedVisitAction,
    getBoardItems: getBoardItemsAction,
    sendPatientMessage: sendPatientMessageAction,
    setSelectedVisit: setSelectedVisitAction,
    setVisitStatus: setVisitStatusAction,
    showConfirmChargesModal: showConfirmChargesModalAction,
    showToast: showToastAction,
  })
)(MiniBoard);
