import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Card, CardContent, Typography, Icon } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import { isNil } from 'lodash';

import Timer from '../../common/timer/timer.component';
import { getAgeString } from '../../utilities/dataUtils';
import Colors from '../../styles/colors';
import MiniBoardActions from './miniBoardActions.component';

const MiniBoardCard = ({
  classes,
  theme,
  boardItem,
  selectedVisit,
  isDisableStart,
  isReady,
  isSelected,
  isStarted,
  onCancelVisitClick,
  onRedialClick,
  onSelectVisitClick,
  onSendMessageClick,
  onStartVisitClick,
  onWaitingRoomClick,
}) => {
  const backgroundColor = useMemo(() => {
    const { isVoiceCall } = boardItem;

    if (isReady) return theme.palette.primary.green;
    if (!isSelected) return 'unset';
    if (isVoiceCall) return `${theme.palette.primary.orange}40`; // This adds 25% opacity

    return theme.palette.primary.lightBlue;
  }, [theme, boardItem, isReady, isSelected]);

  const ageString = getAgeString(boardItem.dob);
  const isShowFacilityIcon = !isNil(boardItem.facility);
  const textColor = isReady ? 'textPrimary' : 'textSecondary';
  const { isVoiceCall, isUnreached } = boardItem;
  const iconColor = isVoiceCall ? theme.palette.primary.orange : theme.palette.primary.main;

  return (
    <Card className={classes.card} style={{ backgroundColor }}>
      <CardContent>
        {(isReady || isSelected) && (
          <div className={classNames(classes.infoContainer, classes.patientReadyHeader)}>
            <div style={{ display: 'flex' }}>
              {isReady && <Typography variant="subtitle1">PATIENT READY</Typography>}
              {isSelected && <Typography variant="subtitle1">WAITING FOR PATIENT</Typography>}
              {(isReady || isSelected) && (
                <Icon
                  className={classNames({ 'fal fa-phone-volume': isVoiceCall, 'fal fa-video': !isVoiceCall }, classes.phoneTypeIcon)}
                  style={{ color: iconColor }}
                />
              )}
            </div>
            <Typography variant="subtitle1">
              <Timer startTime={boardItem.waitStartTimestamp} key={boardItem.id} showSeconds />
            </Typography>
          </div>
        )}

        {!isReady && !isSelected && (
          <div className={classes.infoContainer}>
            <Typography className={classes.title} color="textPrimary" variant="subtitle1">
              <Timer startTime={boardItem.waitStartTimestamp} key={boardItem.id} />
            </Typography>
            {isShowFacilityIcon && <Icon className={classNames(classes.facilityIcon, 'fal fa-hospital-user')} />}
          </div>
        )}

        <div className={classes.infoContainer}>
          <Typography variant="h5">{`${boardItem.firstName} ${boardItem.lastName}`}</Typography>
          {(isReady || isSelected) && (
            <Typography variant="h5">
              <a href={`tel:${boardItem.mobileNumber}`} className={classes.phone}>
                <NumberFormat displayType="text" value={(boardItem.mobileNumber || '').substring(2)} format="(###) ###-####" />
              </a>
            </Typography>
          )}
        </div>
        <Typography className={classes.marginBottom} color={textColor}>
          {`${ageString} old`}
        </Typography>
        <Typography className={classes.marginBottom} color={textColor}>
          {`"${boardItem.chiefComplaint}"`}
        </Typography>
      </CardContent>

      <MiniBoardActions
        boardItem={boardItem}
        isDisableStart={isDisableStart}
        isReady={isReady}
        isSelected={isSelected}
        isStarted={isStarted}
        isVoiceCall={isVoiceCall}
        isUnreached={isUnreached || false}
        onCancelVisitClick={onCancelVisitClick}
        onRedialClick={onRedialClick}
        onSendMessageClick={onSendMessageClick}
        onSelectVisitClick={onSelectVisitClick}
        onStartVisitClick={onStartVisitClick}
        onWaitingRoomClick={onWaitingRoomClick}
      />
    </Card>
  );
};

const styles = (/* theme */) => ({
  card: {
    minWidth: 325,
  },
  title: {
    flex: 1,
  },
  marginBottom: {
    marginBottom: 12,
  },
  patientReadyHeader: {
    marginBottom: '1rem',
  },
  facilityIcon: {
    color: Colors.primary.orange,
    overflow: 'visible',
    marginRight: '1rem',
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  phone: {
    color: 'unset',
    textDecoration: 'none',
  },
  phoneTypeIcon: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
});

MiniBoardCard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  boardItem: PropTypes.object.isRequired,
  isDisableStart: PropTypes.bool.isRequired,
  isReady: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isStarted: PropTypes.bool.isRequired,
  selectedVisit: PropTypes.object,
  onCancelVisitClick: PropTypes.func.isRequired,
  onRedialClick: PropTypes.func.isRequired,
  onSelectVisitClick: PropTypes.func.isRequired,
  onSendMessageClick: PropTypes.func.isRequired,
  onStartVisitClick: PropTypes.func.isRequired,
  onWaitingRoomClick: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(MiniBoardCard);
