import { Snackbar, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { hideToast, handleRefresh } from './layout.actions';

const styles = theme => ({
  error: {},
  notification: {
    backgroundColor: theme.palette.primary.dark
  }
});

class Toast extends Component {
  render() {
    const { isShowToast, hideToast, toastMessage, toastAction, classes, variant } = this.props;

    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={isShowToast}
          onClose={hideToast}
          ContentProps={{
            'aria-describedby': 'message-id',
            classes: {
              root: classes[variant]
            }
          }}
          message={
            <div id="message-id" style={{ display: 'flex', alignItems: 'center' }}>
              <div>{toastMessage}</div>
              {toastAction && (
                <Button style={{ color: 'white', marginLeft: '1rem' }} onClick={toastAction.handleClick}>
                  {toastAction.text}
                </Button>
              )}
            </div>
          }
          action={[
            <IconButton key="close" aria-label="Close" color="inherit" onClick={hideToast}>
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

Toast.propTypes = {
  isShowToast: PropTypes.bool.isRequired,
  toastMessage: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['error', 'notification']).isRequired,
  toastAction: PropTypes.object,
  responsiveBreakpoint: PropTypes.object.isRequired,
  hideToast: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    isShowToast: state.layout.isShowToast,
    toastMessage: state.layout.toastMessage,
    variant: state.layout.variant,
    responsiveBreakpoint: state.layout.responsiveBreakpoint,
    toastAction: state.layout.toastAction
  };
};

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    {
      hideToast,
      handleRefresh
    }
  )(Toast)
);
