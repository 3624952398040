import { connect } from 'react-redux';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class LogoutContainer extends Component {
  constructor(props) {
    super(props);

    this.props.auth.logout();
  }

  componentWillMount() {
    this.props.setPageTitle('Logout');
  }

  render() {
    return (
      <div className="registration-form-wrapper">
        <div className="row">
          <div className="small-12 medium-6 medium-offset-3 columns">
            <div className="callout info">Logging out...</div>
          </div>
        </div>
      </div>
    );
  }
}

LogoutContainer.propTypes = {
  setPageTitle: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      setPageTitle,
    }
  )(LogoutContainer)
);
