import {
  GET_MY_PREVIOUS_VISITS_REQUEST,
  GET_MY_PREVIOUS_VISITS_SUCCESS,
  GET_MY_PREVIOUS_VISITS_FAILURE,
  GET_PREVIOUS_VISITS_SEARCH_REQUEST,
  GET_PREVIOUS_VISITS_SEARCH_SUCCESS,
  GET_PREVIOUS_VISITS_SEARCH_FAILURE
} from './previousVisits.actions';
import produce from 'immer';

// const mockPreviousVisits = require('./mockPreviousVisits.json');
// const mockPreviousVisitsAdvanced = require('./mockPreviousVisitsAdvanced.json');

const initialState = {
  previousVisits: [],
  previousVisitsSearchResults: [],
  isLoading: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_MY_PREVIOUS_VISITS_REQUEST:
      draft.isLoading = true;
      break;

    case GET_MY_PREVIOUS_VISITS_SUCCESS:
      draft.isLoading = false;
      draft.previousVisits = action.response.data;
      break;

    case GET_MY_PREVIOUS_VISITS_FAILURE:
      draft.isLoading = false;
      break;

    case GET_PREVIOUS_VISITS_SEARCH_REQUEST:
      draft.isLoading = true;
      break;

    case GET_PREVIOUS_VISITS_SEARCH_SUCCESS:
      draft.isLoading = false;
      draft.previousVisitsSearchResults = action.response.data;
      break;

    case GET_PREVIOUS_VISITS_SEARCH_FAILURE:
      draft.isLoading = false;
      break;

    default:
      break;
  }
}, initialState);
