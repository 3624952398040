import { CALL_API } from '../../middleware/api';

export const GET_PROVIDERS_REQUEST = 'GET_PROVIDERS_REQUEST';
export const GET_PROVIDERS_SUCCESS = 'GET_PROVIDERS_SUCCESS';
export const GET_PROVIDERS_FAILURE = 'GET_PROVIDERS_FAILURE';

export const getProviders = () => {
  return {
    [CALL_API]: {
      types: [GET_PROVIDERS_REQUEST, GET_PROVIDERS_SUCCESS, GET_PROVIDERS_FAILURE],
      authenticated: true,
      endpoint: 'v1/provider/providers',
      method: 'GET'
    }
  };
};
