import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardActions, Tooltip, Icon, withStyles, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core';
import { ChatBubble, ArrowDropDown } from '@material-ui/icons';
import moment from 'moment';
import classNames from 'classnames';

import RelyMDPrimaryButton from '../../common/buttons/relyMDPrimaryButton.component';
import { DATE_FIELD_FORMAT } from '../../utilities/dateUtils';
import BoardStartCallButton from './boardStartCallButton.component';
import { can } from '../login/can';
import { providerGroupPermissions } from '../../types/permissionTypes';
import { visitStatusTypes } from '../../types/visitStatusTypes';
import {
  getActiveBoardItems,
  getAssignedBoardItems,
  getBoardItems as getBoardItemsAction,
  getNewBoardItems,
  SET_VISIT_STATUS_SUCCESS, setVisitStatus as setVisitStatusAction,
} from './board.actions';
import history from '../../history';
import { isNil } from 'lodash';
import Video from '@material-ui/icons/Videocam';
import Phone from '@material-ui/icons/Phone';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { sendPatientMessage as sendPatientMessageAction } from '../visit/visit.actions';
import {
  setPageTitle as setPageTitleAction,
  setSelectedVisit as setSelectedVisitAction,
} from '../layout/layout.actions';

const MiniBoardActions = ({
  classes,
  theme,
  selectedVisit,
  boardItem,
  isDisableStart,
  isReady,
  isSelected,
  isStarted,
  isVoiceCall,
  isUnreached,
  onCancelVisitClick,
  onRedialClick,
  onSendMessageClick,
  onSelectVisitClick,
  onStartVisitClick,
  onWaitingRoomClick,
  setVisitStatus,
                            setSelectedVisit,
}) => {
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [isReturnOpen, setIsReturnOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  /* Reset menus when props change */
  useEffect(() => {
    setIsCancelOpen(false);
    setIsReturnOpen(false);

    setAnchorEl(null);
  }, [boardItem, isDisableStart, isReady, isSelected, isStarted, isVoiceCall, isUnreached]);

  const handleOpenMenu = useCallback(
    openMenu => event => {
      setAnchorEl(event.target);
      openMenu(prev => !prev);
    },
    [setAnchorEl]
  );

  const handleCloseMenu = useCallback(
    openMenu => event => {
      if (anchorEl.contains(event.target)) return;

      openMenu(false);
    },
    [anchorEl]
  );

  const showRedial = (isVoiceCall && isUnreached) || (isVoiceCall && isStarted);

  const refreshBoard = () => {
    getNewBoardItems();
    getAssignedBoardItems();
    getActiveBoardItems();
  };

  const handleStartVisit = (row, action = 'view') => async event => {
    event.stopPropagation();
    event.preventDefault();

    const { type } = await setVisitStatus(row.id, visitStatusTypes.STARTED, false);

    if (type === SET_VISIT_STATUS_SUCCESS) {
      // send reset timestamp flag, as server resets db visit waitTime on visit becoming selected
      setSelectedVisit({ ...row, state: visitStatusTypes.STARTED, isUnreached: false }, true);
      refreshBoard();

      localStorage.setItem(`visit_${row.id}_action`, action);

      history.push(`/visit/${row.id}`);
    }
  };

  /* VISIT NOT YET SELECTED */
  /* START DISABLED IF OTHER VISIT IS SELECTED */
  if (!isSelected && !isReady)
    return (
      <CardActions style={{ justifyContent: 'flex-end' }}>
        {can(providerGroupPermissions.createPatientMessage, boardItem.entranceId) && !isVoiceCall && (
          <RelyMDPrimaryButton variant="outlined" className={classes.actionButton} onClick={onSendMessageClick(boardItem)}>
            <ChatBubble />
          </RelyMDPrimaryButton>
        )}
        <RelyMDPrimaryButton
          className={classNames(classes.button)}
          hasSelectedVisit={!isNil(selectedVisit)}
          visit={boardItem}
          onClick={handleStartVisit(boardItem, 'video')}
        >
          <div className={classNames(classes.startBtnIcon)}>
            <Video className={classNames(classes.icon)} />
          </div>
          <div className={classNames(classes.startBtnText)}>start</div>
        </RelyMDPrimaryButton>
        <RelyMDPrimaryButton
          className={classNames(classes.button)}
          hasSelectedVisit={!isNil(selectedVisit)}
          visit={boardItem}
          onClick={handleStartVisit(boardItem, 'phone')}
        >
          <div className={classNames(classes.startBtnIcon)}>
            <Phone className={classNames(classes.icon)} />
          </div>
          <div className={classNames(classes.startBtnText)}>start</div>
        </RelyMDPrimaryButton>
        {/*{boardItem.isVoiceCall && boardItem.isUnreached && (*/}
        {/*  <div className={classes.unreached}>*/}
        {/*    <Tooltip*/}
        {/*      title={`Marked as unreached on ${moment(boardItem.isUnreached).format(DATE_FIELD_FORMAT)} at ${moment(*/}
        {/*        boardItem.isUnreached*/}
        {/*      ).format('hh:mm a')}`}*/}
        {/*      classes={{ tooltip: classes.tooltip }}*/}
        {/*    >*/}
        {/*      <Icon className={classNames(classes.unreachedIcon, 'fal fa-phone-slash')} />*/}
        {/*    </Tooltip>*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{can(providerGroupPermissions.createVisit, boardItem.entranceId) && (*/}
        {/*  <BoardStartCallButton hasSelectedVisit={isDisableStart} visit={boardItem} onStartVisit={onSelectVisitClick(boardItem)} />*/}
        {/*)}*/}
      </CardActions>
    );

  /* VISIT IS READY OR SELECTED */
  if (isReady || isSelected)
    return (
      <CardActions>
        {can(providerGroupPermissions.createPatientMessage, boardItem.entranceId) && !isVoiceCall && (
          <RelyMDPrimaryButton variant="outlined" className={classes.actionButton} onClick={onSendMessageClick(boardItem)}>
            <ChatBubble />
          </RelyMDPrimaryButton>
        )}
        {!isVoiceCall && (
          <RelyMDPrimaryButton
            className={classNames(classes.actionButton, classes.waitingButton)}
            style={{ flex: '1 1 auto' }}
            variant="outlined"
            onClick={() => onWaitingRoomClick(false)}
          >
            return to waiting room
          </RelyMDPrimaryButton>
        )}
        {isVoiceCall && (
          <>
            <RelyMDPrimaryButton
              aria-owns={isReturnOpen ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              className={classNames(classes.actionButton, classes.waitingButton, { [classes.marginRight]: !showRedial })}
              variant="outlined"
              onClick={handleOpenMenu(setIsReturnOpen)}
            >
              return to waiting room
              <ArrowDropDown style={{ marginTop: -2 }} />
            </RelyMDPrimaryButton>
            <Popper open={isReturnOpen} anchorEl={anchorEl} transition disablePortal style={{ zIndex: 1000 }}>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper style={{ minWidth: 200 }}>
                    <ClickAwayListener onClickAway={handleCloseMenu(setIsReturnOpen)}>
                      <MenuList>
                        <MenuItem onClick={() => onWaitingRoomClick(false)}>Return to Waiting Room</MenuItem>
                        <MenuItem onClick={() => onWaitingRoomClick(true)}>Return to Waiting Room as Unreached</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        )}
        {showRedial && (
          <RelyMDPrimaryButton className={classes.actionButton} onClick={onRedialClick}>
            redial
          </RelyMDPrimaryButton>
        )}
        {isSelected && (
          <>
            <RelyMDPrimaryButton
              aria-owns={isCancelOpen ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              className={classes.actionButton}
              variant="outlined"
              style={{ color: theme.palette.primary.white, backgroundColor: theme.palette.primary.main }}
              onClick={handleOpenMenu(setIsCancelOpen)}
            >
              Cancel Visit
              <ArrowDropDown style={{ marginTop: -2 }} />
            </RelyMDPrimaryButton>
            <Popper open={isCancelOpen} anchorEl={anchorEl} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper style={{ minWidth: 200 }}>
                    <ClickAwayListener onClickAway={handleCloseMenu(setIsCancelOpen)}>
                      <MenuList>
                        <MenuItem onClick={onCancelVisitClick(false)}>Cancel Visit</MenuItem>
                        <MenuItem onClick={onCancelVisitClick(true)}>Cancel Visit But Still Chart</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        )}
        {isReady && (
          <RelyMDPrimaryButton
            variant="outlined"
            className={classes.actionButton}
            style={{ color: theme.palette.primary.white, backgroundColor: theme.palette.primary.main }}
            onClick={onStartVisitClick}
          >
            Start Visit
          </RelyMDPrimaryButton>
        )}
      </CardActions>
    );
};

const styles = theme => ({
  actionButton: {
    fontSize: '0.7rem',
    minHeight: '2.5rem',
  },
  tooltip: {
    backgroundColor: theme.palette.primary.red,
    fontSize: '1rem',
    maxWidth: 'none',
  },
  unreachedIcon: {
    color: theme.palette.primary.red,
    paddingRight: theme.spacing.unit * 2,
  },
  waitingButton: {
    flex: '1 1 auto',
  },
  marginRight: {
    marginRight: '4px', // This matches the spacing of CardActions MUI default
  },
});

MiniBoardActions.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedVisit: PropTypes.object,
  boardItem: PropTypes.object.isRequired,
  isDisableStart: PropTypes.bool.isRequired,
  isReady: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isStarted: PropTypes.bool.isRequired,
  isVoiceCall: PropTypes.bool.isRequired,
  isUnreached: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onRedialClick: PropTypes.func.isRequired,
  onSendMessageClick: PropTypes.func.isRequired,
  onSelectVisitClick: PropTypes.func.isRequired,
  onStartVisitClick: PropTypes.func.isRequired,
  onWaitingRoomClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  boardItems: state.board.boardItems.filter(item => item.state === visitStatusTypes.NEW || item.state === visitStatusTypes.ASSIGNED),
  newBoardItems: state.board.newBoardItems,
  assignedBoardItems: state.board.assignedBoardItems,
  activeBoardItems: state.board.activeBoardItems,
  isLoading: state.board.isLoading,
  providerId: state.provider.providerId,
  selectedVisit: state.layout.selectedVisit,
});

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, {
      getBoardItems: getBoardItemsAction,
      getNewBoardItems,
      getAssignedBoardItems,
      getActiveBoardItems,
      sendPatientMessage: sendPatientMessageAction,
      setPageTitle: setPageTitleAction,
      setSelectedVisit: setSelectedVisitAction,
      setVisitStatus: setVisitStatusAction,
    })(MiniBoardActions)
  )
);
