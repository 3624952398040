import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  icon: {
    color: theme.palette.primary.main,
    padding: 10
  },
  advancedButton: {
    fontSize: '0.7rem',
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.white
    }
  }
});

const DataTableSearch = props => {
  const { classes, handleSearch, handleShowAdvancedSearch } = props;

  return (
    <Paper className={classes.root} elevation={1}>
      <InputBase className={classes.input} placeholder="Search" onChange={handleSearch} />
      <Button variant={'text'} className={classes.advancedButton} disableRipple disableFocusRipple onClick={handleShowAdvancedSearch}>
        {'Advanced'}
      </Button>
      <SearchIcon className={classes.icon} />
    </Paper>
  );
};

DataTableSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  handleShowAdvancedSearch: PropTypes.func,
  handleSearch: PropTypes.func
};

export default withStyles(styles)(DataTableSearch);
