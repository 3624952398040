import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CustomModal from '../../common/modal/customModal.component';
import { hideConfirmChargesModal, showToast } from './layout.actions';
import { Typography } from '@material-ui/core';
import { getFormattedPrice, getPriceInCents } from '../../utilities/dataUtils';
import { Button, TextField } from '@material-ui/core';
import { has, isNil } from 'lodash';
import NumberFormat from 'react-number-format';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import { confirmVisitCharge, CONFIRM_VISIT_CHARGE_SUCCESS } from '../visit/visit.actions';
import { can } from '../login/can';
import { providerGroupPermissions } from '../../types/permissionTypes';
import { setConfirmCharges } from '../chart/chart.actions';

const styles = theme => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  buttonSecondary: {
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.white
    }
  },
  buttonSecondaryDisabled: {
    color: theme.palette.primary.lightgray
  }
});

const MIN_SUGGESTED_VALUE = 0;

const CustomNumberFormat = props => {
  const { inputRef, onChange, min, max, ...rest } = props;
  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      format={'$##'}
      onValueChange={values => {
        let value = values.floatValue > max ? max : values.floatValue < min ? min : values.value;
        if (value.length > 1 && value.substring(0, 1) === '0') {
          value = value.substring(1, 2);
        }
        onChange({
          target: {
            value
          }
        });
      }}
    />
  );
};

class ConfirmChargesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestedAmount: null,
      agreedAmount: null,
      reason: '',
      isInitialized: false,
      hasSuggestChargePermission: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (has(nextProps, 'currentChartVisit.pricing.authorizedPrice')) {
      const hasSuggestChargePermission = can(providerGroupPermissions.createSuggestedPaymentChange);
      const price = nextProps.currentChartVisit.pricing.authorizedPrice;

      this.setState({
        suggestedAmount: getFormattedPrice(price),
        agreedAmount: getFormattedPrice(price),
        hasSuggestChargePermission,
        isInitialized: true
      });
    }

    // clear state on close
    if (nextProps.isShowConfirmChargesModal === false) {
      this.setState({
        suggestedAmount: null,
        agreedAmount: null,
        reason: '',
        isInitialized: false
      });
    }
  }

  handleChange = field => evt => {
    const updateState = { ...this.state };
    updateState[field] = evt.target.value;
    this.setState(updateState);
  };

  // add some custom props to number format for max and min
  getCustomNumberFormat = passProps => {
    return <CustomNumberFormat {...passProps} max={this.state.agreedAmount} min={MIN_SUGGESTED_VALUE} />;
  };

  formHasErrors = () => {
    return this.state.reason === '';
  };

  handleConfirmAgreedAmount = async () => {
    if (has(this.props, 'currentChartVisit.id')) {
      let result = await this.props.confirmVisitCharge(this.props.currentChartVisit.id);
      if (result.type === CONFIRM_VISIT_CHARGE_SUCCESS) {
        this.props.setConfirmCharges(this.props.currentChartVisit.id);
        this.props.hideConfirmChargesModal();
      } else {
        this.props.showToast('Could not confirm charges for the visit. Please check your connection and try again.');
      }
    }
  };

  handleSuggestedAmount = async () => {
    if (has(this.props, 'currentChartVisit.id')) {
      const payload = {
        suggestedPrice: getPriceInCents(this.state.suggestedAmount),
        reason: this.state.reason
      };
      let result = await this.props.confirmVisitCharge(this.props.currentChartVisit.id, payload);
      if (result.type === CONFIRM_VISIT_CHARGE_SUCCESS) {
        this.props.setConfirmCharges(this.props.currentChartVisit.id);
        this.props.hideConfirmChargesModal();
      } else {
        this.props.showToast('Could not update suggested price for the visit. Please check your connection and try again.');
      }
    }
  };

  render() {
    const { isShowConfirmChargesModal, hideConfirmChargesModal, theme, classes, isLoading, currentChartVisit } = this.props;
    const { suggestedAmount, agreedAmount, reason, isInitialized, hasSuggestChargePermission } = this.state;

    const hasVisitLoaded = !isNil(currentChartVisit) || !isShowConfirmChargesModal;
    if (isLoading || !hasVisitLoaded) return <LoadingOverlay />;

    return (
      <CustomModal
        open={isShowConfirmChargesModal && isInitialized}
        handleClose={hideConfirmChargesModal}
        backdropClick
        modalContentProps={{
          style: {
            width: 500,
            overflow: 'auto'
          }
        }}
      >
        <div style={{ marginBottom: '1rem' }}>
          <Typography
            variant={'h5'}
            style={{ color: theme.palette.primary.darkgray, textAlign: 'center', marginBottom: '1rem' }}
          >{`CONFIRM CHARGES`}</Typography>
          <Typography
            style={{ color: theme.palette.primary.darkgray, textAlign: 'center', fontSize: '1.2rem' }}
          >{`Patient agreed to payment charge of $${agreedAmount} at time of visit. Please confirm this charge amount or suggest another amount.`}</Typography>
        </div>

        <div style={{ marginBottom: '1.5rem' }}>
          <Button
            onClick={this.handleConfirmAgreedAmount}
            disableRipple
            className={classes.button}
            style={{ display: 'block', margin: 'auto' }}
          >{`Confirm $${agreedAmount}`}</Button>
        </div>

        {hasSuggestChargePermission && (
          <Fragment>
            <div style={{ marginBottom: '0.5rem' }}>
              <Typography style={{ color: theme.palette.primary.darkgray, textAlign: 'center', fontSize: '1.3rem' }}>{`OR`}</Typography>
            </div>

            <div style={{ display: 'flex', paddingLeft: '1rem', paddingRight: '1rem', marginBottom: '1.5rem' }}>
              <TextField
                id="suggestedAmount"
                label=""
                value={suggestedAmount}
                onChange={this.handleChange('suggestedAmount')}
                InputProps={{
                  inputComponent: this.getCustomNumberFormat,
                  style: { fontSize: '1.3rem', color: theme.palette.primary.darkgray, width: 40 }
                }}
                margin="normal"
                style={{ marginRight: '1rem', alignSelf: 'flex-end' }}
              />

              <TextField
                id="reason"
                label="Reason"
                value={reason}
                onChange={this.handleChange('reason')}
                margin="normal"
                InputProps={{
                  style: { fontSize: '1.3rem', color: theme.palette.primary.darkgray }
                }}
                style={{ flex: 1 }}
              />
            </div>

            <div>
              <Button
                disabled={this.formHasErrors()}
                className={this.formHasErrors() ? classes.buttonSecondaryDisabled : classes.buttonSecondary}
                disableRipple
                style={{ display: 'block', margin: 'auto' }}
                onClick={this.handleSuggestedAmount}
              >{`SUGGEST ALTERNATIVE`}</Button>
            </div>
          </Fragment>
        )}
      </CustomModal>
    );
  }
}

ConfirmChargesModal.propTypes = {};

const mapStateToProps = state => {
  return {
    isShowConfirmChargesModal: state.layout.isShowConfirmChargesModal,
    currentChartVisit: state.chart.currentChartVisit,
    isLoading: state.layout.isShowConfirmChargesModal && (state.chart.isLoading || state.visit.isLoading)
  };
};

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    {
      hideConfirmChargesModal,
      showToast,
      confirmVisitCharge,
      setConfirmCharges
    }
  )(ConfirmChargesModal)
);
