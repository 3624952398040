import auth0 from 'auth0-js';
import { AUTH_CONFIG } from './auth0-variables';
import { clearNotificationRegistration } from '../../fcm';

export const INACTIVITY_TIMEOUT_MS = 15 * 60 * 1000; // 15 mins
export const INACTIVITY_TIMEOUT_POLLING_INTERVAL_MS = 1000;

class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    responseType: 'token id_token',
    scope: 'email openid',
    audience: AUTH_CONFIG.audience
  });

  constructor(history, setAuthAction, clearAuthAction, onAuthSet) {
    this.history = history;
    this.setAuthAction = setAuthAction;
    this.clearAuthAction = clearAuthAction;
    this.onAuthSet = onAuthSet;

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
  }

  login() {
    this.auth0.authorize();
  }

  handleAuthentication(props) {
    this.auth0.parseHash({ hash: props.location.hash }, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.authResult = authResult;
        this.setSession(authResult);
        this.setAuthAction(authResult.idToken, authResult.accessToken);
        if (this.onAuthSet) this.onAuthSet(); // provide callback after auth becomes set
      } else if (err) {
        this.history.replace('/login');
        console.log(`ERROR: `, err);
        // alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  logout() {
    clearNotificationRegistration();
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    this.clearAuthAction();
    window.location = `https://${AUTH_CONFIG.domain}/v2/logout?returnTo=${AUTH_CONFIG.logoutRedirectUrl}&client_id=${AUTH_CONFIG.clientId}`;
  }
}

export default Auth;
