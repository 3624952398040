import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import ImageSlider from '../../common/imageSlider/imageSlider.component';
import ImageDetailModal from '../../common/imageDetailModal/imageDetailModal.component';

const styles = {
  padding: {
    padding: '1rem',
    paddingTop: 0,
  }
};

class ChartPatientPhotos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowImageDetail: false,
      currentImage: {}
    };
  }

  handleShowImageDetail = image => {
    this.setState({
      isShowImageDetail: true,
      currentImage: image
    });
  };

  handleCloseImageDetail = () => {
    this.setState({
      isShowImageDetail: false,
      currentImage: {}
    });
  };

  render() {
    const { classes, images } = this.props;
    const { currentImage, isShowImageDetail } = this.state;

    return (
      <div className={classes.padding}>
        <Paper>
          <Typography style={{ padding: '1rem 1rem 0 1rem' }}>{`PHOTOS${
            images && images.length > 0 ? ' (' + images.length + ')' : ':'
          }`}</Typography>
          <div style={{ padding: '1rem', height: 150 }}>
            {images && images.length <= 0 && <Typography>{'No Photos Available'}</Typography>}
            {images && images.length > 0 && <ImageSlider handleImageClick={this.handleShowImageDetail} images={images} />}
          </div>
        </Paper>
        <ImageDetailModal open={isShowImageDetail} image={currentImage} handleClose={this.handleCloseImageDetail} />
      </div>
    );
  }
}

ChartPatientPhotos.propTypes = {
  classes: PropTypes.object.isRequired,
  images: PropTypes.array
};

export default withStyles(styles)(ChartPatientPhotos);
