import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DataTable from '../../common/dataTable/dataTable.component';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { getFriendlyName } from '../../types/providerStatusTypes';
import NumberFormat from 'react-number-format';

const styles = theme => ({
  tableRowRoot: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.primary.lightgray,
    },
  },
});

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Provider', sortable: true },
  { id: 'availability', numeric: false, disablePadding: false, label: 'Status', sortable: true },
  { id: 'mobileNumber', numeric: false, disablePadding: false, label: 'Phone', sortable: true },
  { id: 'patientName', numeric: false, disablePadding: false, label: 'Seeing Pt', sortable: true },
];

class ProvidersDataTable extends Component {
  // get custom row render component
  renderRow = (row, i) => {
    return (
      <Fragment key={i}>
        <TableRow tabIndex={-1} classes={{ root: this.props.classes.tableRowRoot }}>
          <TableCell align="left">{row.name}</TableCell>
          <TableCell align="left">{getFriendlyName(row.availability, row.notificationThreshold)}</TableCell>
          <TableCell align="left">
            <a href={`tel:${row.mobileNumber}`} style={{ textDecoration: 'none', color: 'unset' }}>
              <NumberFormat displayType={'text'} value={(row.mobileNumber || '').substring(2)} format={'###-###-####'} />
            </a>
          </TableCell>
          <TableCell>{row.currentVisit ? `${row.currentVisit.patientName} - "${row.currentVisit.chiefComplaint}"` : ''}</TableCell>
        </TableRow>
      </Fragment>
    );
  };

  render() {
    const { data } = this.props;
    return <DataTable data={data} title={''} columns={columns} renderRow={this.renderRow} disableSearch />;
  }
}

ProvidersDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

export default withStyles(styles)(ProvidersDataTable);
