import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ChartPatientPreviousVisits from '../chart/chartPatientPreviousVisits.component';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';
import { mapJsonToLabelList } from '../../utilities/nihApiUtils';
import { nihApiTypes } from '../../types/nihApiTypes';
import { mapAllergyJsonToLabelList } from '../../utilities/dataUtils';
import { can } from '../login/can';
import { providerGroupPermissions } from '../../types/permissionTypes';
import Colors from '../../styles/colors';
import { has, isNil } from 'lodash';

const styles = theme => ({
  expansionPanelContainer: {
    minHeight: '6rem',
    padding: '1rem',
  },
  text: {
    marginBottom: '1rem',
    fontSize: '1rem',
  },
  phiIndicator: {
    color: Colors.primary.red,
    fontSize: '1.2rem',
    fontStyle: 'italic',
  },
});

class BoardRowExpansionPanel extends Component {
  render() {
    const { classes, data, previousVisits } = this.props;

    const isFacilitatedVisit = has(data, 'facility') && !isNil(data.facility);
    const isShowPhiIndicator = isFacilitatedVisit && data.facility.phiVisible !== true;

    return (
      <Grid container className={classes.expansionPanelContainer}>
        {isShowPhiIndicator && (
          <Grid item xs={12} style={{ marginBottom: '0.5rem' }}>
            <Typography className={classes.phiIndicator}>* Facilitator cannot see or set PHI</Typography>
          </Grid>
        )}
        <Grid item xs={3}>
          {can(providerGroupPermissions.readMHx, data.entranceId) && (
            <Typography className={classes.text}>{`MHx: ${mapJsonToLabelList(
              nihApiTypes.NIH_CONDITIONS,
              data.medicalHistory
            )}`}</Typography>
          )}
          {can(providerGroupPermissions.readSHx, data.entranceId) && (
            <Typography className={classes.text}>{`SHx: ${mapJsonToLabelList(
              nihApiTypes.NIH_PROCEDURES,
              data.surgicalHistory
            )}`}</Typography>
          )}
          {can(providerGroupPermissions.readMedication, data.entranceId) && (
            <Typography className={classes.text}>{`Medications: ${mapJsonToLabelList(
              nihApiTypes.NIH_RXTERMS,
              data.medications
            )}`}</Typography>
          )}
          {can(providerGroupPermissions.readAllergy, data.entranceId) && (
            <Typography className={classes.text}>{`Allergies: ${mapAllergyJsonToLabelList(data.allergies)}`}</Typography>
          )}
        </Grid>
        <Grid item xs={3} style={{ display: 'flex' }}>
          <Typography className={classes.text} style={{ marginRight: '1rem' }}>{`Ph:`}</Typography>
          <div>
            <a href={`tel:${data.mobileNumber}`} style={{ textDecoration: 'none', color: 'unset' }}>
              <NumberFormat
                className={classes.text}
                displayType={'text'}
                value={(data.mobileNumber || '').substring(2)}
                format={'(###) ###-####'}
              />
            </a>
          </div>
        </Grid>
        <Grid item xs={6}>
          <ChartPatientPreviousVisits
            previousVisits={previousVisits}
            patientName={`${data.firstName} ${data.lastName}`}
            isEditEnabled={true}
          />
        </Grid>
      </Grid>
    );
  }
}

BoardRowExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(BoardRowExpansionPanel);
