const BOARD = {
  route: '/',
  name: 'Board',
  icon: 'HOME'
};

const PREVIOUS = {
  route: '/previous',
  name: 'Previous',
  icon: 'PREVIOUS'
};

const PROVIDERS = {
  route: '/providers',
  name: 'Providers',
  icon: 'PROVIDERS'
};

const RESOURCES = {
  route: '/resources',
  name: 'Resources',
  icon: 'RESOURCES'
};

const LOGOUT = {
  route: '/logout',
  name: 'Logout',
  icon: 'POWER'
};

export default [BOARD, PREVIOUS, PROVIDERS, RESOURCES, LOGOUT];
