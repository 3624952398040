import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { groupBy } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid, Icon, Tooltip, Typography, Avatar, FormControl, InputLabel, Select, MenuItem, Input } from '@material-ui/core';
import ReactSelectField from '../../common/reactSelectField/reactSelectField.component';
import { nihApiTypes } from '../../types/nihApiTypes';
import { fetchData } from '../../common/api/api.actions';
import { GET_API_DATA_SUCCESS } from '../../common/api/api.actions';
import { searchAllergies, SEARCH_ALLERGIES_SUCCESS } from '../chart/chart.actions';
import { getProxyApiPath, mapNihDataToSelectOptions, mapJsonArrayToSelectOptions } from '../../utilities/nihApiUtils';
import { mapAllergyJsonToSelectOptions } from '../../utilities/dataUtils';
import { medicalHistoryTypes } from '../../types/medicalHistoryTypes';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { can } from '../login/can';
import { providerGroupPermissions } from '../../types/permissionTypes';

const styles = theme => ({
  padding: {
    padding: '1rem',
  },
  formContainer: {
    marginTop: '1rem',
  },
  formLabel: {
    marginRight: '0.5rem',
    marginTop: '1rem',
  },
  formField: {
    // display: 'flex',
    minHeight: 50,
  },
  formControl: {
    width: '100%',
    minHeight: '40px',
  },
  select: {
    minHeight: '40px',
  },
  icon: {
    fontSize: 18,
    color: theme.palette.primary.main,
    marginTop: -2,
    marginLeft: 2,
    marginRight: 2,
  },
  tooltipContent: {
    fontSize: '0.8rem',
    color: theme.palette.primary.white,
  },
  rxIcon: {
    fontSize: 17,
    marginLeft: 5,
  },
  rxIconAvatar: {
    backgroundColor: theme.palette.primary.lightgray,
    color: theme.palette.secondary.contrastText,
    height: 24,
    width: 24,
    marginRight: 4,
  },
});

const familyMembers = ['mother', 'father', 'brother', 'sister'];

const ChartFamilyHistoryFormComponent = ({ classes, visit, isReadOnly, fetchData, handleFormUpdate, children }) => {
  const [state, setState] = useState({
    familyMedicalHistory: visit.familyMedicalHistory || [],
    newEntry: {
      familyMember: '',
      medicalHistory: [],
    },
  });

  const filteredHistory = familyMember => {
    return state.familyMedicalHistory.filter(item => item.family_member === familyMember);
  };

  const availableFamilyMembers = () => {
    return familyMembers.filter(member => !state.familyMedicalHistory.map(item => item.family_member).includes(member));
  };

  const handleFetchData = type => async query => {
    let response = await fetchData(getProxyApiPath(type, query));
    if (response.type === GET_API_DATA_SUCCESS) {
      return mapNihDataToSelectOptions(type, response.response);
    }
  };

  const getFamilyMemberOptions = () => {
    return availableFamilyMembers().map((item, i) => (
      <MenuItem value={item} key={i}>
        {item.toUpperCase()}
      </MenuItem>
    ));
  };

  const setNewEntryFamilyMember = familyMember => {
    setState(prev => {
      return {
        ...prev,
        newEntry: {
          ...prev.newEntry,
          familyMember,
        },
      };
    });
  };

  const setNewEntryMedicalHistory = medicalHistory => {
    setState(prev => {
      return {
        ...prev,
        newEntry: {
          ...prev.newEntry,
          medicalHistory,
        },
      };
    });
  };

  const renderFamilyMemberRows = () => {
    const grouped = groupBy(state.familyMedicalHistory, 'family_member');
    const familyMembers = Object.keys(grouped);

    return familyMembers.map(item => {
      return (
        <Grid spacing={16} container className={classes.formContainer} key={item}>
          <Grid item xs={2} className={classes.formField}>
            <div className={classes.formLabel}>{'Family Member'}</div>
            <FormControl className={classes.formControl}>
              <Select
                className={classes.select}
                value={item}
                // onChange={this.handleChange('templateId')}
                input={<Input name="templateId" id="templateId-label-placeholder" />}
              >
                <MenuItem value={item}>{item.toUpperCase()}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={10} className={classes.formField}>
            <div className={classes.formLabel}>{'Medical History'}</div>
            <FormControl className={classes.formControl}>
              <ReactSelectField
                selectedValues={
                  filteredHistory(item).length > 0 ? mapJsonArrayToSelectOptions(nihApiTypes.NIH_CONDITIONS, filteredHistory(item)) : []
                }
                handleChange={values => {
                  if (values.length > 0) {
                    values.forEach(value => {
                      value.family_member = item;
                    });
                  }

                  const currentMedicalHistory = state.familyMedicalHistory.filter(history => history.family_member !== item);
                  const newMedicalHistory = [...currentMedicalHistory, ...values];

                  setState(prev => {
                    return {
                      ...prev,
                      familyMedicalHistory: newMedicalHistory,
                    };
                  });
                }}
                isMulti={true}
                isReadOnly={isReadOnly || !can(providerGroupPermissions.updateMHx)}
                handleFetchData={handleFetchData(nihApiTypes.NIH_CONDITIONS)}
              />
            </FormControl>
          </Grid>
        </Grid>
      );
    });
  };

  const renderBlankField = () => {
    return (
      <Grid spacing={16} container className={classes.formContainer}>
        <Grid item xs={2} className={classes.formField}>
          <div className={classes.formLabel}>{'Family Member'}</div>
          <FormControl className={classes.formControl}>
            <Select
              className={classes.select}
              value={state.newEntry.familyMember}
              onChange={e => setNewEntryFamilyMember(e.target.value)}
              input={<Input name="templateId" id="templateId-label-placeholder" />}
            >
              {getFamilyMemberOptions()}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={10} className={classes.formField}>
          <div className={classes.formLabel}>{'Medical History'}</div>
          <FormControl className={classes.formControl}>
            <ReactSelectField
              selectedValues={
                state.newEntry.medicalHistory.length > 0
                  ? mapJsonArrayToSelectOptions(nihApiTypes.NIH_CONDITIONS, state.newEntry.medicalHistory)
                  : []
              }
              handleChange={value => setNewEntryMedicalHistory(value)}
              isMulti={true}
              isReadOnly={isReadOnly || !can(providerGroupPermissions.updateMHx)}
              handleFetchData={handleFetchData(nihApiTypes.NIH_CONDITIONS)}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    if (state.newEntry.familyMember && state.newEntry.medicalHistory.length > 0) {
      setState(prev => {
        const entry = {
          ...state.newEntry.medicalHistory[0],
          family_member: state.newEntry.familyMember,
        };

        return {
          ...prev,
          familyMedicalHistory: [...prev.familyMedicalHistory, entry],
          newEntry: {
            familyMember: '',
            medicalHistory: [],
          },
        };
      });
    }
  }, [state.newEntry.familyMember, state.newEntry.medicalHistory]);

  useEffect(() => {
    const hasChanged = JSON.stringify(visit.familyMedicalHistory || []) !== JSON.stringify(state.familyMedicalHistory);

    if (hasChanged) {
      handleFormUpdate({
        familyMedicalHistory: state.familyMedicalHistory,
      });
    }
  }, [state.familyMedicalHistory, visit.familyMedicalHistory]);

  return (
    <>
      <div className={classes.padding}>
        <Paper style={{ padding: '1rem 1rem 2rem 1rem' }}>
          <Typography>{'FAMILY MEDICAL HISTORY:'}</Typography>

          {renderFamilyMemberRows()}
          {availableFamilyMembers().length > 0 && renderBlankField()}
        </Paper>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    providerId: state.provider.providerId,
  };
};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, {
    fetchData,
  })(ChartFamilyHistoryFormComponent)
);
