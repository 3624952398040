import React, { Component, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { withTheme } from '@material-ui/core/styles';

const NextArrow = props => {
  const { onClick, theme } = props;
  return (
    <div
      onClick={onClick}
      style={{
        position: 'absolute',
        top: '50%',
        cursor: 'pointer',
        transform: 'translate(0, -50%)',
        WebkitTransform: 'translate(0, -50%)',
        right: -15,
      }}
    >
      <ChevronRight style={{ color: theme.palette.primary.darkgray }} />
    </div>
  );
};

const PrevArrow = props => {
  const { onClick, theme } = props;
  return (
    <div
      onClick={onClick}
      style={{
        position: 'absolute',
        top: '50%',
        cursor: 'pointer',
        transform: 'translate(0, -50%)',
        WebkitTransform: 'translate(0, -50%)',
        left: -15,
      }}
    >
      <ChevronLeft style={{ color: theme.palette.primary.darkgray }} />
    </div>
  );
};

const SliderImage = ({ image, handleImageClick }) => {
  const [thumbUrl, setThumbUrl] = useState(!!image.thumbnailUrl ? image.thumbnailUrl : image.url);

  const handleError = () => {
    setThumbUrl(image.url);
  };

  return (
    <div>
      <div style={{ padding: '0.25rem' }}>
        <img
          onClick={handleImageClick ? () => handleImageClick(image) : null}
          src={thumbUrl}
          alt={image.alt}
          style={{ height: '145px', width: '100%', objectFit: 'cover', cursor: handleImageClick ? 'pointer' : 'default' }}
          onError={handleError}
        />
      </div>
    </div>
  );
};

class ImageSlider extends Component {
  render() {
    const defaultSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 900, // when width <=
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    const { images, settings, handleImageClick, theme } = this.props;
    const appliedSettings = settings ? settings : defaultSettings;

    return (
      <Slider {...appliedSettings} nextArrow={<NextArrow theme={theme} />} prevArrow={<PrevArrow theme={theme} />}>
        {images.map((image, i) => {
          return (
            <SliderImage handleImageClick={handleImageClick} image={image} key={i} />
            // <div key={i}>
            //   <div style={{ padding: '0.25rem' }}>
            //     <img
            //       onClick={handleImageClick ? () => handleImageClick(image) : null}
            //       // src={src}
            //       src="https://test.patient.relymd.app/intake/visits/1234"
            //       alt={image.alt}
            //       style={{ height: '145px', width: '100%', objectFit: 'cover', cursor: handleImageClick ? 'pointer' : 'default' }}
            //       // onError={`this.src = '${image.url}';this.onerror = null;`}
            //       onError={() => {
            //         this.src = 'https://static.relymd.app/dev/marketing/ent_rmd/12d94b0e-3a9d-4c74-a3b4-b24c908b8f31.jpeg';
            //       }}
            //     />
            //   </div>
            // </div>
          );
        })}
      </Slider>
    );
  }
}

ImageSlider.propTypes = {
  images: PropTypes.array.isRequired,
  settings: PropTypes.object,
};

export default withTheme()(ImageSlider);
