import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles, Icon } from '@material-ui/core';
import classNames from 'classnames';

import RelyMDPrimaryButton from '../../common/buttons/relyMDPrimaryButton.component';
import { phoneVisitStatusTypes } from '../../types/visitStatusTypes';

const VisitStatus = ({
  classes,
  hasRetry,
  hasParticipants,
  isConnected,
  isConnecting,
  isVoiceCall,
  voiceState,
  connectToRoom,
  convertToVoice,
}) => {
  const isWaiting =
    (isConnected && !hasParticipants && voiceState !== phoneVisitStatusTypes.COMPLETED) || voiceState === phoneVisitStatusTypes.RINGING;
  const hasFailedTwice = !isConnecting && !isConnected && hasRetry;
  const callDisconnected = isConnected && !hasParticipants && voiceState === phoneVisitStatusTypes.COMPLETED;
  const isNoAnswer = voiceState === phoneVisitStatusTypes.NO_ANSWER;

  if (isNoAnswer) return <Typography variant="h6">Patient did not answer</Typography>;

  if (isConnecting) return <Typography variant="h6">Connecting...</Typography>;

  if (isWaiting) return <Typography variant="h6">Waiting on the patient to connect...</Typography>;

  if (callDisconnected) return <Typography variant="h6">Patient has hung up or was disconnected</Typography>;

  if (hasFailedTwice)
    return (
      <>
        <Typography align="center" variant="h6">
          Connection to the video call has failed, please check your connection and try again.
        </Typography>
        <div className={classes.failedActions}>
          <RelyMDPrimaryButton onClick={connectToRoom}>retry</RelyMDPrimaryButton>
          <RelyMDPrimaryButton className={classes.convertButton} variant="outlined" onClick={convertToVoice}>
            <Icon className={classNames('fal fa-phone-volume', classes.actionIcon)} />
            convert to voice
          </RelyMDPrimaryButton>
        </div>
      </>
    );

  if (isConnected && isVoiceCall) {
    return <Icon className={classNames(classes.volumeIcon, 'fal fa-phone-volume')} />;
  }

  return !isConnected && <Typography variant="h6">You are not currently in a call.</Typography>;
};

const styles = theme => ({
  volumeIcon: {
    color: theme.palette.primary.orange,
    fontSize: '12rem',
    paddingTop: theme.spacing.unit * 10,
  },
  failedActions: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing.unit * 4,
    width: '100%',
  },
  actionIcon: {
    fontSize: '1.5rem',
    paddingRight: theme.spacing.unit * 2,
  },
  convertButton: {
    color: theme.palette.common.white,
    border: theme.palette.primary.orange,
    backgroundColor: theme.palette.primary.orange,
    '&:hover': {
      color: theme.palette.common.white,
      border: theme.palette.primary.orange,
      backgroundColor: theme.palette.primary.orange,
    },
  },
});

VisitStatus.propTypes = {
  classes: PropTypes.object.isRequired,

  hasParticipants: PropTypes.bool.isRequired,
  hasRetry: PropTypes.bool.isRequired,
  isConnected: PropTypes.bool.isRequired,
  isConnecting: PropTypes.bool.isRequired,
  isVoiceCall: PropTypes.bool.isRequired,
  voiceState: PropTypes.string,

  connectToRoom: PropTypes.func.isRequired,
  convertToVoice: PropTypes.func.isRequired,
};

export default withStyles(styles)(VisitStatus);
