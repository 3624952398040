import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import CustomModal from '../../common/modal/customModal.component';

const styles = theme => ({
  closeButton: {
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.white,
      color: theme.palette.primary.main
    }
  },
  iframe: {
    width: '100%',
    height: '100%'
  }
});

class RxModal extends Component {
  handleClose = () => {
    this.props.handleClose();
  };

  render() {
    const { url, open, handleClose, classes } = this.props;

    return (
      <CustomModal
        open={open}
        handleClose={handleClose}
        modalContentProps={{
          style: {
            minWidth: '95%',
            height: '95%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <iframe title={'rxiframe'} className={classes.iframe} src={url} />
        </div>
        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant={'outlined'} onClick={this.handleClose} className={classes.closeButton} disableRipple>
            {'Close'}
          </Button>
        </div>
      </CustomModal>
    );
  }
}

RxModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired
};

const withStylesRxModal = withStyles(styles, { withTheme: true })(RxModal);

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(withStylesRxModal);
