import { detect } from 'detect-browser';
export const browser = detect();

// overall list of browsers and os:
// 'aol' | 'edge' | 'yandexbrowser' | 'vivaldi' | 'kakaotalk' | 'samsung' | 'silk' | 'miui' | 'beaker' | 'edge-chromium' | 'chrome' | 'chromium-webview' | 'phantomjs' | 'crios' | 'firefox' | 'fxios' | 'opera-mini' | 'opera' | 'ie' | 'bb10' | 'android' | 'ios' | 'safari' | 'facebook' | 'instagram' | 'ios-webview' | 'searchbot';
// 'iOS' | 'Android OS' | 'BlackBerry OS' | 'Windows Mobile' | 'Amazon OS' | 'Windows 3.11' | 'Windows 95' | 'Windows 98' | 'Windows 2000' | 'Windows XP' | 'Windows Server 2003' | 'Windows Vista' | 'Windows 7' | 'Windows 8' | 'Windows 8.1' | 'Windows 10' | 'Windows ME' | 'Open BSD' | 'Sun OS' | 'Linux' | 'Mac OS' | 'QNX' | 'BeOS' | 'OS/2' | 'Chrome OS' | 'Search Bot';

// allow only these supported browsers
const supportedBrowsers = ['chrome', 'firefox'];
const supportedBrowser = () => {
  return true;
  // return supportedBrowsers.indexOf(browser.name) > -1;
};
export const isSupportedBrowser = supportedBrowser();

// detect mobile based on these OS definitions
const mobileOSList = ['iOS', 'Android OS', 'BlackBerry OS', 'Windows Mobile', 'Amazon OS'];
const mobileOS = () => {
  return mobileOSList.indexOf(browser.os) > -1;
};
export const isMobile = mobileOS();

export default { browser, isSupportedBrowser, isMobile };
