import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withRouteOnEnter } from '../../../utilities/withRouteOnEnter.component';
import BoardPage from '../../board/board.container';
import ProvidersPage from '../../providers/providers.container';
import PreviousVisitsPage from '../../previousVisits/previousVisits.container';
import ChartPage from '../../chart/chart.container';
import ResourcesPage from '../../resources/resources.container';
import LogoutPage from '../../login/logout.container';
import VisitPage from '../../visit/visit.container';

import { LOGIN_STATE } from '../../login/login.actions';

class Routes extends Component {
  checkAuth = () => {
    if (this.props.loginState === LOGIN_STATE.LOGGED_IN) {
      // NOTE: Right here you can read from the user's profile to determine if they have what they need to proceed
      if (this.props.location.pathname === '/login') {
        this.props.history.replace('/');
      }
    } else {
      console.log('NOT LOGGED IN!');
      if (this.props.location.pathname !== '/login') {
        this.props.history.replace('/login');
      }
    }
  };

  render() {
    const { auth } = this.props;

    return (
      <Switch>
        <Route exact path="/" component={withRouteOnEnter(this.checkAuth)(BoardPage)} />
        <Route exact path="/logout" render={props => <LogoutPage auth={auth} />} />

        <Route exact path="/providers" component={withRouteOnEnter(this.checkAuth)(ProvidersPage)} />
        <Route exact path="/previous" component={withRouteOnEnter(this.checkAuth)(PreviousVisitsPage)} />
        <Route exact path="/previous/:visitId" component={withRouteOnEnter(this.checkAuth)(ChartPage)} />
        <Route exact path="/resources" component={withRouteOnEnter(this.checkAuth)(ResourcesPage)} />
        <Route exact path="/visit/:visitId" component={withRouteOnEnter(this.checkAuth)(VisitPage)} />

        <Redirect from="*" to="/" />
      </Switch>
    );
  }
}

Routes.propTypes = {
  auth: PropTypes.object.isRequired,
  loginState: PropTypes.number.isRequired,
};

export default withRouter(Routes);
