import React from 'react';
import PropTypes from 'prop-types';
import Colors from '../../styles/colors';
import { withStyles, Typography, Card } from '@material-ui/core';
import alertTypes from '../../types/alertTypes';
import classnames from 'classnames';

const styles = ({ spacing }) => ({
  bannerContainer: {
    padding: spacing.unit * 2,
    color: Colors.primary.white,
    marginBottom: spacing.unit / 2,
    borderRadius: 0
  },
  alert: {
    backgroundColor: Colors.primary.orange
  },
  critical: {
    backgroundColor: Colors.primary.red
  },
  normal: {
    backgroundColor: Colors.primary.main
  }
});

const MessageBanner = ({ classes, message, type }) => {
  const containerClasses = classnames([
    classes.bannerContainer,
    type === alertTypes.CRITICAL ? classes.critical : type === alertTypes.NORMAL ? classes.normal : classes.alert
  ]);
  return (
    <Card className={containerClasses}>
      <Typography color="inherit" variant="h6">
        {message}
      </Typography>
    </Card>
  );
};

MessageBanner.defaultProps = {
  type: alertTypes.ALERT
};

MessageBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string
};

export default withStyles(styles)(MessageBanner);
