import { GET_PROVIDERS_REQUEST, GET_PROVIDERS_SUCCESS, GET_PROVIDERS_FAILURE } from './providers.actions';
import produce from 'immer';

const initialState = {
  providers: [],
  isLoading: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_PROVIDERS_REQUEST:
      draft.isLoading = true;
      break;

    case GET_PROVIDERS_SUCCESS:
      draft.isLoading = false;
      draft.providers = action.response.data;
      break;

    case GET_PROVIDERS_FAILURE:
      draft.isLoading = false;
      break;

    default:
      break;
  }
}, initialState);
