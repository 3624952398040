import React, { Component } from 'react';

import { connect } from 'react-redux';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PreviousVisitsDataTable from './previousVisitsDataTable.component';
import { getMyPreviousVisits, previousVisitsSearch } from './previousVisits.actions';
import classnames from 'classnames';
import PreviousVisitSummaryModal from './previousVisitSummary/previousVisitSummaryModal.component';
import { GET_PREVIOUS_VISIT_SUCCESS, getPreviousVisit } from '../chart/chart.actions';
import { logEvent } from '../../utilities/googleAnalytics';

const styles = theme => ({
  container: {
    padding: '1rem',
    flex: '1'
  }
});

class PreviousVisitsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdvancedSearch: false,
      isShowVisitSummaryModal: false,
      currentVisit: {},
      currentVisitNotes: [],
      visitPaging: {
        current: 0,
        total: 0
      },
      tableVisits: []
    };
  }

  componentDidMount() {
    this.props.setPageTitle('Previous');
    this.props.getMyPreviousVisits();
  }

  componentWillReceiveProps(nextProps) {
    this.handlePreviousVisitsDataChanged(nextProps.previousVisits);
  }

  handleRowEdit = isAddend => id => {
    logEvent(isAddend ? 'addend_chart' : 'Edit_chart', {
      visitId: id,
      providerId: this.props.providerId
    });

    this.props.history.push(`/previous/${id}`);
  };

  handleRowClick = async (rowData, index) => {
    const { id } = rowData;

    logEvent('view_patienthistory', {
      visitId: id,
      providerId: this.props.providerId
    });

    let response = await this.props.getPreviousVisit(id);

    if (response.type === GET_PREVIOUS_VISIT_SUCCESS) {
      this.setState({
        isShowVisitSummaryModal: true,
        currentVisit: response.response,
        visitPaging: {
          total: this.props.previousVisits.length,
          current: index
        }
      });
    }
  };

  handleAdvancedSearch = async searchTerms => {
    logEvent('search_allvisits', {
      providerId: this.props.providerId,
      searchTerms
    });

    await this.props.previousVisitsSearch(searchTerms);
    this.setState({
      isAdvancedSearch: true
    });

    this.handlePreviousVisitsDataChanged(this.props.previousVisitsSearchResults);
  };

  handleAdvanceSearchClosed = () => {
    this.setState({
      isAdvancedSearch: false
    });

    this.handlePreviousVisitsDataChanged(this.props.previousVisits);
  };

  handleCloseVisitSummary = () => {
    this.setState({
      isShowVisitSummaryModal: false,
      currentVisit: {}
    });
  };

  handleSetCurrentVisit = async index => {
    const newVisit = this.props.previousVisits[index];

    const { id } = newVisit;
    let response = await this.props.getPreviousVisit(id);

    if (response.type === GET_PREVIOUS_VISIT_SUCCESS) {
      this.setState({
        isShowVisitSummaryModal: true,
        currentVisit: response.response,
        visitPaging: {
          ...this.state.visitPaging,
          current: index
        }
      });
    }
  };

  // track what visits are currently visible in table
  handlePreviousVisitsDataChanged = data => {
    this.setState({
      tableVisits: data
    });
  };

  render() {
    const { classes, providerId, isLoading, previousVisits, previousVisitsSearchResults, noteTypes } = this.props;
    const { isAdvancedSearch, isShowVisitSummaryModal, currentVisit, visitPaging } = this.state;

    const isAddend = providerId !== currentVisit.providerId;

    return (
      <div className={classnames([classes.container, 'page-padding-bottom'])}>
        <PreviousVisitsDataTable
          data={isAdvancedSearch ? previousVisitsSearchResults : previousVisits}
          handleRowClick={this.handleRowClick}
          handleRowEdit={this.handleRowEdit}
          onAdvancedSearchClose={this.handleAdvanceSearchClosed}
          handleAdvancedSearch={this.handleAdvancedSearch}
          isAdvancedSearch={isAdvancedSearch}
          providerId={providerId}
          isLoading={isLoading}
          handlePreviousVisitsDataChanged={this.handlePreviousVisitsDataChanged}
        />
        <PreviousVisitSummaryModal
          open={isShowVisitSummaryModal}
          visit={currentVisit}
          handleClose={this.handleCloseVisitSummary}
          handleEdit={this.handleRowEdit(isAddend)}
          isAddend={isAddend}
          visitPaging={visitPaging}
          handleSetCurrentVisit={this.handleSetCurrentVisit}
          noteTypes={noteTypes}
          isDisablePaging={true} // disable paging outside of chart view
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    noteTypes: state.chart.noteTypes,
    previousVisits: state.previousVisits.previousVisits,
    previousVisitsSearchResults: state.previousVisits.previousVisitsSearchResults,
    providerId: state.provider.providerId,
    isLoading: state.previousVisits.isLoading,
    notes: state.chart.notes
  };
};

PreviousVisitsContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        setPageTitle,
        getMyPreviousVisits,
        previousVisitsSearch,
        getPreviousVisit
      }
    )(PreviousVisitsContainer)
  )
);
