import { CALL_API } from '../../middleware/api';

export const GET_PROVIDER_REQUEST = 'GET_PROVIDER_REQUEST';
export const GET_PROVIDER_SUCCESS = 'GET_PROVIDER_SUCCESS';
export const GET_PROVIDER_FAILURE = 'GET_PROVIDER_FAILURE';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const UPDATE_AVAILABILITY_REQUEST = 'UPDATE_AVAILABILITY_REQUEST';
export const UPDATE_AVAILABILITY_SUCCESS = 'UPDATE_AVAILABILITY_SUCCESS';
export const UPDATE_AVAILABILITY_FAILURE = 'UPDATE_AVAILABILITY_FAILURE';

export const SET_AVAILABILITY_SUCCESS = 'SET_AVAILABILITY_SUCCESS';

export const getProvider = providerId => {
  return {
    [CALL_API]: {
      types: [GET_PROVIDER_REQUEST, GET_PROVIDER_SUCCESS, GET_PROVIDER_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/providers/${providerId}`,
      method: 'GET'
    }
  };
};

export const getProfile = () => {
  return {
    [CALL_API]: {
      types: [GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS, GET_PROFILE_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/profile`,
      method: 'GET'
    }
  };
};

export const updateAvailability = (availability, notificationThreshold) => {
  return {
    [CALL_API]: {
      types: [UPDATE_AVAILABILITY_REQUEST, UPDATE_AVAILABILITY_SUCCESS, UPDATE_AVAILABILITY_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/availability`,
      method: 'POST',
      payload: { availability, notificationThreshold }
    }
  };
};

export const setAvailability = ({ notificationThreshold, value }) => {
  return {
    type: SET_AVAILABILITY_SUCCESS,
    payload: { notificationThreshold, value }
  };
};
