import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

const BORDER_RADIUS = 10;

const styles = theme => ({
  modalRoot: {
    borderRadius: BORDER_RADIUS,
    margin: '1rem'
  },
  modalContent: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: 'none',
    top: `50%`,
    left: `50%`,
    maxHeight: '95%',
    transform: `translate(-50%, -50%)`,
    borderRadius: BORDER_RADIUS,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 'unset'
    }
  },
  modalContentRootDefault: {}
});

class CustomModal extends React.Component {
  render() {
    const { open, classes, handleClose, children, backdropClick, escapeKeyDown, modalContentProps } = this.props;

    return (
      <Modal
        aria-labelledby="custom-modal"
        aria-describedby="custom-modal"
        open={open}
        onClose={handleClose}
        // disable by default
        disableBackdropClick={backdropClick === true ? false : true}
        disableEscapeKeyDown={escapeKeyDown === true ? false : true}
        classes={{ root: classes.modalRoot }}
      >
        <div className={classes.modalContent} {...modalContentProps}>
          {children}
        </div>
      </Modal>
    );
  }
}

CustomModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  backdropClick: PropTypes.bool,
  escapeKeyDown: PropTypes.bool,
  modalContentProps: PropTypes.object
};

CustomModal = withStyles(styles)(CustomModal);

export default CustomModal;
