import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { find } from 'lodash';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { can } from '../login/can';
import { providerGroupPermissions } from '../../types/permissionTypes';

const styles = {
  formControl: {
    width: '100%',
    marginBottom: '1rem'
  },
  gridItem: {
    marginBottom: '1rem'
  }
};

class ChartPatientNoteTemplateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      noteTypeId: '',
      templateId: '',
      visibleToPatient: false
    };
  }

  // reset template selection when changing category
  handleChange = name => event => {
    let updateObj = { [name]: event.target.value };
    if (name === 'noteTypeId') {
      let noteType = find(this.props.noteTypes, { id: event.target.value });
      updateObj.visibleToPatient = noteType.visibleToPatientByDefault;
    }
    this.setState(updateObj);
  };

  formHasErrors = () => {
    return this.state.templateId === '';
  };

  getNoteTypeOptions = () => {
    return this.props.noteTypes
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .map((noteType, i) => {
        return (
          <MenuItem value={noteType.id} key={i}>
            {noteType.name}
          </MenuItem>
        );
      });
  };

  getNoteTemplateOptions = noteTypeId => {
    if (noteTypeId !== null && noteTypeId !== '') {
      let noteType = find(this.props.noteTypes, { id: noteTypeId });
      if (noteType) {
        return noteType.templates
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          .map((template, i) => {
            return (
              <MenuItem value={template.id} key={i}>
                {template.name}
              </MenuItem>
            );
          });
      }
    }
    return [];
  };

  handleToggleShare = () => {
    this.setState({
      visibleToPatient: !this.state.visibleToPatient
    });
  };

  render() {
    let { classes, handleCancel, handleCreateNote } = this.props;
    const { noteTypeId, templateId, visibleToPatient } = this.state;

    return (
      <Grid container>
        <Grid item xs={12} className={classes.gridItem}>
          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor="noteTypeId-placeholder">
              {'Note Type'}
            </InputLabel>
            <Select
              value={noteTypeId}
              onChange={this.handleChange('noteTypeId')}
              input={<Input name="noteTypeId" id="noteTypeId-placeholder" />}
            >
              {this.getNoteTypeOptions()}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor="templateId-label-placeholder">
              {'Template'}
            </InputLabel>
            <Select
              value={templateId}
              onChange={this.handleChange('templateId')}
              input={<Input name="templateId" id="templateId-label-placeholder" />}
            >
              {this.getNoteTemplateOptions(noteTypeId)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <FormControlLabel
            control={
              <Checkbox
                checked={visibleToPatient}
                onChange={this.handleToggleShare}
                value="visibleToPatient"
                color="primary"
                style={{ paddingTop: 0, paddingBottom: 0, paddingRight: '1rem' }}
                disabled={!can(providerGroupPermissions.updateNotePatientVisibility)}
              />
            }
            label="Shared with Patient"
          />
        </Grid>

        <Grid container item xs={12} justify={'flex-end'}>
          <Button
            disabled={this.formHasErrors()}
            variant={'outlined'}
            style={{ marginLeft: 'auto', marginRight: '0.5rem' }}
            onClick={() => handleCreateNote(noteTypeId, templateId, visibleToPatient)}
          >
            {'Add'}
          </Button>
          <Button variant={'outlined'} onClick={handleCancel}>
            {'Cancel'}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

ChartPatientNoteTemplateForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCreateNote: PropTypes.func,
  handleCancel: PropTypes.func,
  noteTypes: PropTypes.array.isRequired
};

export default withStyles(styles)(ChartPatientNoteTemplateForm);
