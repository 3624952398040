import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Icon } from '@material-ui/core';
import classNames from 'classnames';

import { hasWebcam, hasMicrophone } from '../../utilities/hardwareUtils';
import RelyMDPrimaryButton from '../../common/buttons/relyMDPrimaryButton.component';

const BoardStartCallButton = ({ classes, theme, hasSelectedVisit, visit, onStartVisit, ...rest }) => {
  const { isVoiceCall } = visit;

  const isDisabled = useMemo(() => hasSelectedVisit || !hasMicrophone || (!visit.isVoiceCall && !hasWebcam), [hasSelectedVisit, visit]);

  const styleObj = useMemo(() => {
    let backgroundColor = isDisabled ? theme.palette.grey[400] : theme.palette.primary.main;

    if (!isDisabled && visit.isVoiceCall) {
      backgroundColor = theme.palette.primary.orange;
    }

    return {
      backgroundColor,
      borderColor: backgroundColor,
      color: isDisabled ? theme.palette.primary.darkgray : theme.palette.primary.white,
      cursor: isDisabled ? 'none' : 'pointer',
      width: 150,
    };
  }, [theme, isDisabled, visit]);

  const actionObj = useMemo(
    () => ({
      icon: !isVoiceCall ? 'fal fa-video' : 'fal fa-phone-volume',
      text: !isVoiceCall ? 'start video' : 'start call',
    }),
    [isVoiceCall]
  );

  return (
    <RelyMDPrimaryButton disabled={isDisabled} style={styleObj} variant="outlined" onClick={onStartVisit} {...rest}>
      <Icon className={classNames(actionObj.icon, classes.actionIcon)} />
      {actionObj.text}
    </RelyMDPrimaryButton>
  );
};

const styles = theme => ({
  actionIcon: {
    paddingRight: theme.spacing.unit,
  },
});

BoardStartCallButton.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  hasSelectedVisit: PropTypes.bool.isRequired,
  visit: PropTypes.object.isRequired,

  onStartVisit: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(BoardStartCallButton);
