import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, withStyles, Table, TableBody, TableCell, TableRow, Button } from '@material-ui/core';
import moment from 'moment';
import { isEmpty } from 'lodash';
import ChartPrescriptionInfo from './chartPrescriptionInfo.component';
import CustomDialog from '../../common/customDialog/customDialog.component';

const styles = theme => ({
  padding: {
    padding: '1rem'
  },
  tableRowRoot: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.primary.lightgray
    },
    height: '1rem',
    cursor: 'pointer'
  }
});

class ChartPatientPrescriptions extends Component {
  state = {
    isShowPrescriptionInfoDialog: false,
    prescription: {}
  };

  handleRowClick = async (prescription, index) => {
    this.setState({
      isShowPrescriptionInfoDialog: true,
      prescription
    });
  };

  render() {
    const { classes, prescriptions, isRefreshing, handleRefreshPrescriptions } = this.props;

    return (
      <div className={classes.padding}>
        <Paper style={{ padding: '1rem 1rem 2rem 1rem' }}>
          {isEmpty(prescriptions) && <Typography style={{ marginBottom: '0.5rem' }}>{'NO PRESCRIPTIONS'}</Typography>}
          {!isEmpty(prescriptions) && (
            <Fragment>
              <Typography style={{ marginBottom: '0.5rem' }}>{'PRESCRIPTIONS:'}</Typography>
              <Table style={{ tableLayout: 'auto' }}>
                <TableBody>
                  {prescriptions.map((prescription, i) => {
                    return (
                      <TableRow
                        classes={{ root: classes.tableRowRoot }}
                        key={prescription.PrescriptionId}
                        onClick={() => this.handleRowClick(prescription, i)}
                      >
                        <TableCell style={{ width: '25%' }}>
                          <Typography>{moment(prescription.WrittenDate).format('M/D/YYYY')}</Typography>
                        </TableCell>
                        <TableCell style={{ width: '75%' }}>
                          <Typography>{prescription.GenericProductName}</Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Fragment>
          )}
          {handleRefreshPrescriptions && (
            <Button variant={'outlined'}
                    style={{ margin: '1rem', color: '#2B82D8' }} onClick={handleRefreshPrescriptions} disabled={isRefreshing || false}>
              {isRefreshing && 'Refreshing...'}
              {!isRefreshing && 'Refresh'}
            </Button>
          )}

        </Paper>
        <CustomDialog
          open={this.state.isShowPrescriptionInfoDialog}
          handleClose={() => this.setState({ prescription: {}, isShowPrescriptionInfoDialog: false })}
          content={<ChartPrescriptionInfo prescription={this.state.prescription} />}
          title="Prescription"
          cancelOnly={true}
          cancelText="Close"
        />
      </div>
    );
  }
}

ChartPatientPrescriptions.propTypes = {
  classes: PropTypes.object.isRequired,
  handleRefreshPrescriptions: PropTypes.func,
  isRefreshing: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(ChartPatientPrescriptions);
