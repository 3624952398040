export const providerStatusTypes = {
  ON_CALL: 'on-call',
  UNAVAILABLE: 'unavailable',
  BACKUP: 'backup'
};

export const getFriendlyName = (availability, notificationThreshold) => {
  switch (availability) {
    case providerStatusTypes.ON_CALL:
      return 'On-Call';
    case providerStatusTypes.BACKUP:
      return `Backup - ${notificationThreshold} mins`;
    default:
      return 'Unavailable';
  }
};

export default { providerStatusTypes, getFriendlyName };
