import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

class CustomDialog extends Component {
  render() {
    let {
      open,
      handleClose,
      actionText,
      cancelText,
      title,
      content,
      handleAction,
      cancelOnly,
      isActionDisabled,
      actionButtonProps
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          {!cancelOnly && (
            <Button
              onClick={handleAction}
              autoFocus
              className="button"
              variant="outlined"
              disabled={isActionDisabled}
              {...actionButtonProps}
            >
              {actionText ? actionText : 'OK'}
            </Button>
          )}
          <Button onClick={handleClose} className="button" variant="outlined">
            {cancelText ? cancelText : 'CANCEL'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CustomDialog.defaultProps = {
  isActionDisabled: false
};

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  content: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  cancelText: PropTypes.string,
  handleAction: PropTypes.func,
  cancelOnly: PropTypes.bool,
  isActionDisabled: PropTypes.bool,
  actionButtonProps: PropTypes.object
};

export default CustomDialog;
