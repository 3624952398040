import jwt_decode from 'jwt-decode';

export const SET_AUTH = 'SET_AUTH';
export const CLEAR_AUTH = 'CLEAR_AUTH';

/* Define a login state to use across the app. */
export const LOGIN_STATE = {
  NOT_LOGGED_IN: 0,
  LOGGED_IN: 1
};

export const setAuth = (auth, access) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_AUTH,
      auth: auth !== null ? jwt_decode(auth) : null,
      access: access !== null ? jwt_decode(access) : ''
    });
  };
};

export const clearAuth = () => {
  return {
    type: CLEAR_AUTH
  };
};
