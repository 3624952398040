import { isEmpty, isNil } from 'lodash';
import { relationshipTypes } from '../types/insuranceRelationships';

export const createInsurancePayload = insuranceInfo => {
  const deleteFields = [
    'attachments',
    'cardImageThumbnailUrl',
    'cardImageUrl',
    'cardImage2ThumbnailUrl',
    'cardImage2Url',
    'cardImage3ThumbnailUrl',
    'cardImage3Url',
    'cardImage4ThumbnailUrl',
    'cardImage4Url',
  ];

  const payload = { ...insuranceInfo };

  payload.cardImageUploadId = undefined;
  payload.cardImageUpload2Id = undefined;
  payload.cardImageUpload3Id = undefined;
  payload.cardImageUpload4Id = undefined;

  insuranceInfo.attachments.forEach((attachment, i) => {
    if (i === 0) {
      payload.cardImageUploadId = attachment.id;
    } else {
      payload[`cardImageUpload${i + 1}Id`] = attachment.id;
    }
  });

  if (isNil(payload.relationshipToInsured) || payload.relationshipToInsured === relationshipTypes.RELATIONSHIP_SELF.value) {
    payload.subscriberAddressCity = null;
    payload.subscriberAddressLine1 = null;
    payload.subscriberAddressLine2 = null;
    payload.subscriberAddressState = null;
    payload.subscriberAddressZip = null;
    payload.subscriberDob = null;
    payload.subscriberGender = null;
    payload.subscriberName = null;
  }

  deleteFields.forEach(field => delete payload[field]);

  return payload;
};

export const formatInsuranceInfo = (insuranceInfo, profile) => {
  if (isEmpty(insuranceInfo)) return {};

  return {
    ...insuranceInfo,
    subscriberAddressLine1: insuranceInfo.subscriberAddressLine1 || profile.addressLine1,
    subscriberAddressLine2: insuranceInfo.subscriberAddressLine2 || profile.addressLine2,
    subscriberAddressCity: insuranceInfo.subscriberAddressCity || profile.addressCity,
    subscriberAddressState: insuranceInfo.subscriberAddressState || profile.addressState,
    subscriberAddressZip: insuranceInfo.subscriberAddressZip || profile.addressZip,
    attachments: formatInsuranceAttachments(insuranceInfo),
  };
};

export const formatInsuranceAttachments = insuranceInfo => {
  const insuranceIdKeys = ['cardImageUploadId', 'cardImageUpload2Id', 'cardImageUpload3Id', 'cardImageUpload4Id'];
  const toReturn = [];

  insuranceIdKeys.forEach((key, i) => {
    if (i === 0 && insuranceInfo[key]) {
      toReturn.push({
        thumbnailUrl: insuranceInfo.cardImageThumbnailUrl,
        id: insuranceInfo.cardImageUploadId,
        url: insuranceInfo.cardImageUrl,
      });
    } else if (insuranceInfo[key]) {
      toReturn.push({
        thumbnailUrl: insuranceInfo[`cardImage${i + 1}ThumbnailUrl`],
        id: insuranceInfo[`cardImageUpload${i + 1}Id`],
        url: insuranceInfo[`cardImage${i + 1}Url`],
      });
    }
  });

  return toReturn;
};
