import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, Typography, Button, TablePagination } from '@material-ui/core';
import { isEmpty } from 'lodash';
import NumberFormat from 'react-number-format';

const styles = theme => ({
  container: {},
  tableRowRoot: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.primary.lightgray
    }
  },
  rowContentContainer: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    display: 'flex'
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white
    }
  }
});

// pharmacy fields
//
//   PharmacyId: number;
//   StoreName: string;
//   Address1: string;
//   Address2: string;
//   City: string;
//   State: string;
//   ZipCode: string;
//   PrimaryPhone: string;
//   PrimaryPhoneType: number;
//   PrimaryFax: string;
//   PhoneAdditional1: string | null;
//   PhoneAdditionalType1: number;
//   PhoneAdditional2: string | null;
//   PhoneAdditionalType2: number;
//   PhoneAdditional3: string | null;
//   PhoneAdditionalType3: number;
//   PharmacySpecialties: string[];
//   ServiceLevel: number;
//   Latitude: number;
//   Longitude: number;

class PharmacySearchResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      rowsPerPage: 5
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage
    });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: event.target.value,
      page: 0
    });
  };

  render() {
    const { pharmacies, classes, theme, handlePharmacySelect, isLoading } = this.props;
    const { rowsPerPage, page } = this.state;

    return (
      <div>
        <Table className={classes.table}>
          <TableBody>
            {pharmacies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
              <TableRow key={i} classes={{ root: classes.tableRowRoot }}>
                <TableCell component="td" scope="row">
                  <div className={classes.rowContentContainer}>
                    <div style={{ flex: 1 }}>
                      <Typography style={{ fontSize: '1.2rem', color: theme.palette.primary.darkgray, marginBottom: '0.5rem' }}>
                        {row.StoreName}
                      </Typography>

                      <Typography style={{ fontSize: '1rem', color: theme.palette.primary.darkgray }}>{row.Address1}</Typography>

                      {!isEmpty(row.Address2) && (
                        <Typography style={{ fontSize: '1rem', color: theme.palette.primary.darkgray }}>{row.Address2}</Typography>
                      )}

                      <Typography style={{ fontSize: '1rem', color: theme.palette.primary.darkgray }}>{`${row.City}, ${row.State}  ${
                        row.ZipCode
                      }`}</Typography>

                      <NumberFormat
                        style={{ fontSize: '1rem', color: theme.palette.primary.darkgray }}
                        displayType={'text'}
                        format={'(###) ###-####'}
                        value={row.PrimaryPhone}
                      />

                      {/* {!isEmpty(row.PhoneAdditional1) && (
                        <Typography style={{ fontSize: '1rem', color: theme.palette.primary.darkgray }}>{`${row.PhoneAdditional1} (${
                        row.PrimaryPhoneType1
                          })`}</Typography>
                      )} */}
                    </div>

                    <div>
                      <Button
                        onClick={!isLoading ? () => handlePharmacySelect(row) : null}
                        variant={'outlined'}
                        className={classes.button}
                        disableRipple
                      >
                        {'Select'}
                      </Button>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={pharmacies.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

PharmacySearchResults.propTypes = {
  classes: PropTypes.object.isRequired,
  pharmacies: PropTypes.array.isRequired,
  handlePharmacySelect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(PharmacySearchResults);
