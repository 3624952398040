import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import Paper from '@material-ui/core/Paper';
import classnames from 'classnames';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { generateGuid } from '../../utilities/guid';
import { keyboardCodes } from '../../types/keyboardCodes.js';
import { isNil } from 'lodash';

// input height minimum prevents shrinking when no values are present
const INPUT_MIN_HEIGHT = 40;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  input: {
    display: 'flex',
    padding: 0,
    minHeight: INPUT_MIN_HEIGHT
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center'
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 0.08)
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

const MIN_SEARCH_CHARACTERS = 3;
const DEBOUNCE_WAIT_TIME = 250;

const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};

const Control = props => {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
};

const Option = props => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
};

const Placeholder = props => {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

const SingleValue = props => {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

const ValueContainer = props => {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
};

const MultiValue = props => {
  let { rxIcon } = props.selectProps;
  const labelElements = [].concat(props.children);
  const chipClasses = [props.selectProps.classes.chip];

  // need to wrap rxIcon in order to apply key prop
  if (!isNil(rxIcon) && props.data.isManualEntry !== true) {
    labelElements.unshift(<div key={props.data.value}>{rxIcon}</div>);
    chipClasses.push(props.selectProps.classes.chipFocused);
  }

  return (
    <Chip
      tabIndex={-1}
      label={labelElements}
      className={classnames(chipClasses)}
      onDelete={!props.isDisabled ? props.removeProps.onClick : null}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
};

const Menu = props => {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
};

const DropdownIndicator = props => {
  // return <KeyboardArrowDown {...props.innerProps} style={{ margin: '0.5rem', cursor: 'pointer', height: 24 }} />;
  return null;
};

const ClearIndicator = props => {
  // return <Close {...props.innerProps} style={{ ...indicatorStyles, height: 18 }} />;
  return null;
};

const IndicatorSeparator = props => {
  return null;
};

let components = {
  Control,
  Menu,
  MultiValue,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  DropdownIndicator,
  ClearIndicator,
  IndicatorSeparator
};

const MAX_LABEL_CHARACTERS = 64;
const TRUNCATE_ELLIPSIS = '...';

class ReactSelectField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValues: props.selectedValues ? props.selectedValues : [],
      inputValue: '',
      options: props.options ? props.options : []
    };

    this.searchTimer = null;
    this.selectRef = null;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedValues: nextProps.selectedValues
    });
  }

  getTruncatedLabel = text => {
    if (text && text.length > MAX_LABEL_CHARACTERS) {
      return `${text.substring(0, MAX_LABEL_CHARACTERS)}${TRUNCATE_ELLIPSIS}`;
    }
    return text;
  };

  // handles updating the accepted set of values within the select field
  // values: values to add to current values
  handleChange = values => {
    this.setState({
      selectedValues: values.map(val => {
        return {
          ...val,
          label: this.getTruncatedLabel(val.label)
        };
      }),
      inputValue: '', // reset search query when selecting option
      options: [] // reset options when selecting option
    });

    this.props.handleChange(values);
  };

  handleAcceptCurrentValue = () => {
    if (this.state.inputValue.trim() !== '') {
      let newValues = [
        ...this.state.selectedValues,
        {
          value: generateGuid(), // need unique key for value
          label: this.getTruncatedLabel(this.state.inputValue),
          isManualEntry: true // mark selected value as a manual text entry
        }
      ];
      // add value to field on blur and clear manual input
      this.setState({
        selectedValues: newValues,
        inputValue: '',
        options: []
      });

      this.props.handleChange(newValues);
    }
  };

  onInputChange = (query, { action }) => {
    switch (action) {
      // Prevents resetting our input after option has been selected
      // debounce input
      case 'input-change':
        let trimQuery = query.trim();
        // ignore comma- and space-only queries
        // see this.onKeyDown for custom comma
        if ((trimQuery !== ',' && trimQuery !== '') || this.state.inputValue !== '') {
          this.setState({
            inputValue: query
          });
          if (this.searchTimer) clearTimeout(this.searchTimer);
          if (query.length >= MIN_SEARCH_CHARACTERS) {
            this.searchTimer = setTimeout(async () => {
              const options = await this.props.handleFetchData(query);
              this.setState({ options });
            }, DEBOUNCE_WAIT_TIME);
          } else {
            this.setState({
              options: []
            });
          }
        }
        break;
      case 'input-blur':
        this.handleAcceptCurrentValue();
        break;
      default:
        break;
    }
  };

  // implement custom behavior on certain key presses
  onKeyDown = event => {
    const { keyCode } = event;

    // track focused menu option if needed
    let focusedOption;

    switch (keyCode) {
      // prevent default behavior on tab
      // prevents tabbing out of the field
      case keyboardCodes.TAB:
        if (this.state.inputValue !== '') {
          event.preventDefault();
          event.stopPropagation();
          this.handleAcceptCurrentValue();
        }
        break;

      // comma
      // accept current text input
      case keyboardCodes.COMMA:
        this.handleAcceptCurrentValue();
        break;

      // enter - manually accept current highlighted value from menu
      // or, if no highlighted value, accept current text input
      case keyboardCodes.ENTER:
        focusedOption = this.selectRef.select.state.focusedOption;
        if (!focusedOption) {
          this.handleAcceptCurrentValue();
        }
        break;

      default:
        break;
    }
  };

  // optional: filter an option out of the list
  // default: apply no filter
  filterOption = option => {
    return option;
  };

  render() {
    const { isMulti, classes, isReadOnly, theme, rxIcon, placeholder } = this.props;
    const { selectedValues, inputValue, options } = this.state;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.primary.darkgray,
        '& input': {
          font: 'inherit'
        }
      })
    };

    return (
      <div style={{ marginTop: isReadOnly && selectedValues.length <= 0 ? '1rem' : 'unset' }} onKeyDown={this.onKeyDown}>
        <Select
          classes={classes}
          styles={selectStyles}
          options={options}
          components={components}
          value={selectedValues}
          inputValue={inputValue}
          onChange={this.handleChange}
          onInputChange={this.onInputChange} // also track manual entry outside of search and select
          placeholder={placeholder || ''} // no placeholder text
          isMulti={isMulti}
          menuPlacement={'auto'}
          isDisabled={isReadOnly === true}
          blurInputOnSelect={false}
          closeMenuOnSelect={true}
          isReadOnly={isReadOnly === true}
          noOptionsMessage={() => null} // dont show 'no options' message
          ref={ref => (this.selectRef = ref)} // used to determine whether an option is highlighted in react-select internal state
          filterOption={this.filterOption} // override internal react-select filtering
          rxIcon={rxIcon}
        />
      </div>
    );
  }
}

ReactSelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  isMulti: PropTypes.bool,
  options: PropTypes.array,
  isReadOnly: PropTypes.bool,
  handleFetchData: PropTypes.func,
  selectedValues: PropTypes.array,
  placeholder: PropTypes.string,
  rxIcon: PropTypes.element // whether to show an rx icon on non-manual entries
};

export default withStyles(styles, { withTheme: true })(ReactSelectField);
