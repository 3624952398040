import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, Typography, Paper, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import CustomModal from '../../common/modal/customModal.component';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import PharmacySearchForm from './pharmacySearchForm.component';
import { pharmacySearch, PHARMACY_SEARCH_SUCCESS, pharmacySelect, PHARMACY_SELECT_SUCCESS } from './pharmacy.actions';
import { isEmpty, has } from 'lodash';
import PharmacySearchResults from './pharmacySearchResults.component';
import { showToast } from '../layout/layout.actions';

const styles = theme => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white
    }
  },
  buttonDisabled: {
    backgroundColor: theme.palette.primary.lightgray,
    '&:hover': {
      backgroundColor: theme.palette.secondary.lightgray
    }
  }
});

const initialState = {
  searchResults: [],
  showSearchResults: false,
  isExpandSearchPanel: true
};

class PharmacySelectionModal extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentWillReceiveProps(nextProps) {
    // ensure we clear state when modal is closed
    // can happen by user cancel or selection
    if (nextProps.open === false) {
      this.setState(initialState);
    }
  }

  handleChange = name => event => {
    const updateState = { ...this.state };
    updateState[name] = event.target.value;
    this.setState(updateState);
  };

  handlePharmacySelect = async selection => {
    if (has(selection, 'PharmacyId')) {
      let result = await this.props.pharmacySelect(this.props.visitId, selection.PharmacyId.toString());
      if (result.type === PHARMACY_SELECT_SUCCESS) {
        if (has(result, 'response.success') && result.response.success === true) {
          this.props.onPharmacySelect();
        } else {
          alert('This pharmacy cannot be selected. Please try another one or contact support.');
        }
      } else {
        alert('Error during pharmacy selection. Please check your connection and try again.');
      }
    }
  };

  toggleExpandPanel = () => {
    if (this.state.showSearchResults && !isEmpty(this.state.searchResults)) {
      this.setState({
        isExpandSearchPanel: !this.state.isExpandSearchPanel
      });
    }
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handlePharmacySearch = async criteria => {
    const result = await this.props.pharmacySearch(this.props.visitId, criteria);

    if (result.type === PHARMACY_SEARCH_SUCCESS) {
      this.setState({
        searchResults: result.response,
        showSearchResults: true,
        isExpandSearchPanel: result.response.length > 0 ? false : true
      });
    } else {
      this.setState({
        searchResults: [],
        showSearchResults: true,
        isExpandSearchPanel: true
      });
    }
  };

  render() {
    const { theme, open, handleClose, isLoading, isSelectingPharmacy } = this.props;
    const { showSearchResults, searchResults, isExpandSearchPanel } = this.state;

    if (isSelectingPharmacy) return <LoadingOverlay />;

    return (
      <CustomModal
        open={open}
        handleClose={handleClose}
        modalContentProps={{
          style: {
            minWidth: '60%',
            maxHeight: '95%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <div style={{ flex: 1 }}>
          {/* TODO: do we need to show current pharmacy? */}
          {/* <div style={{ padding: '0 1rem 1rem 1rem' }}>
              <Typography style={{ fontSize: '1.2rem', color: theme.palette.primary.darkgray, marginBottom: '0.5rem' }}>
                {'Current Pharmacy'}
              </Typography>
              <Typography style={{ fontSize: '1rem', color: theme.palette.primary.darkgray }}>{'CVS/Browngreens Longname'}</Typography>
              <Typography style={{ fontSize: '1rem', color: theme.palette.primary.darkgray }}>{'123 E. Main St.'}</Typography>
              <Typography style={{ fontSize: '1rem', color: theme.palette.primary.darkgray }}>{'Raleigh, NC  27607'}</Typography>
            </div> */}

          <ExpansionPanel expanded={isExpandSearchPanel} onChange={this.toggleExpandPanel}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="expansionpanel" id="expansionpanel">
              <Typography style={{ fontSize: '1.3rem', color: theme.palette.primary.darkgray }}>Edit Patient Pharmacy</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <PharmacySearchForm handlePharmacySearch={this.handlePharmacySearch} isLoading={isLoading || isSelectingPharmacy} />
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {showSearchResults && (
            <Paper style={{ padding: '1rem', marginTop: '1rem', overflow: 'auto' }}>
              {isEmpty(searchResults) && (
                <div>
                  <Typography style={{ fontSize: '1rem', color: theme.palette.primary.darkgray }}>
                    {'No Search Results. Please modify your search criteria and try again.'}
                  </Typography>
                </div>
              )}
              {!isEmpty(searchResults) && (
                <PharmacySearchResults pharmacies={searchResults} handlePharmacySelect={this.handlePharmacySelect} isLoading={isLoading} />
              )}
            </Paper>
          )}
        </div>
        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant={'outlined'} onClick={this.handleClose} disableRipple>
            {'Cancel'}
          </Button>
        </div>
      </CustomModal>
    );
  }
}

PharmacySelectionModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onPharmacySelect: PropTypes.func.isRequired,
  visitId: PropTypes.string.isRequired
};

const withStylesPharmacySelectionModal = withStyles(styles, { withTheme: true })(PharmacySelectionModal);

const mapStateToProps = state => {
  return {
    isLoading: state.pharmacy.isLoading,
    isSelectingPharmacy: state.pharmacy.isSelectingPharmacy
  };
};

export default connect(
  mapStateToProps,
  {
    pharmacySearch,
    pharmacySelect,
    showToast
  }
)(withStylesPharmacySelectionModal);
