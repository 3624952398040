import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import DataTableToolbar from './dataTableToolbar.component';
import DataTableHead from './dataTableHead.component';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import { TableHead, Typography } from '@material-ui/core';
import CustomModal from '../modal/customModal.component';
import DataTableAdvancedSearchForm from './dataTableAdvancedSearchForm.component';
import DataTableAdvancedSearchToolbar from './dataTableAdvancedSearchToolbar.component';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import { logEvent } from '../../utilities/googleAnalytics';
import { isNil } from 'lodash';

const styles = theme => ({
  root: {
    height: '100%',
    // display: 'flex',
    // flexDirection: 'column',
  },
  table: {
    backgroundColor: 'transparent !important',
  },
  tableContainer: {
    overflow: 'auto',
    flex: '1',
    maxWidth: '100%',
    display: 'block',
  },
});

const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

const getSorting = (order, orderBy) => {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
};

class DataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: props.defaultSortOrder ? props.defaultSortOrder : 'desc',
      orderBy: props.defaultSortField ? props.defaultSortField : 'date',
      data: props.data && props.data.length > 0 ? props.data : [],
      page: 0,
      rowsPerPage: 10,
      isShowAdvancedSearchModal: false,
      advancedSearchData: null,
      isFilterIncomplete: false,
      searchText: '',
    };

    this.searchTimer = null;
    this.analyticsTimer = null;
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.disableSearch) {
      // clear filters on transition back to simple search from advanced
      // clear filters for disableSearch = true
      let isClearFilters = this.props.isAdvancedSearch === true && nextProps.isAdvancedSearch !== true;

      // if clearing filters, use next data
      // else get filtered data from nextProps
      let updateData = isClearFilters ? nextProps.data : this.getFilteredData(nextProps);

      // clear filters if needed
      const updateObj = { data: updateData };
      if (isClearFilters) {
        updateObj.isFilterIncomplete = false;
        updateObj.searchText = '';
      }

      this.setState(updateObj);
    } else {
      // search disabled
      this.setState({
        data: nextProps.data,
      });
    }
  }

  componentWillUnmount() {
    if (this.searchTimer) clearTimeout(this.searchTimer);
  }

  onDataChanged = data => {
    if (this.props.onDataChanged) this.props.onDataChanged(this.getFilteredData());
  };

  onPagingChanged = data => {
    if (this.props.onPagingChanged) this.props.onPagingChanged();
  };

  // get current data in table
  // optionally pass in next props as data
  getFilteredData = nextProps => {
    const { columns } = this.props;
    const { searchText } = this.state;
    let { data } = nextProps ? nextProps : this.props;
    let { isAdvancedSearch } = nextProps ? nextProps : this.props;

    let filteredData = data;

    if (!isAdvancedSearch) {
      let filterColumns = columns.filter(col => col.searchable === true);

      if (searchText !== '') {
        filteredData = data.filter(row => {
          let isMatch = false;
          filterColumns.forEach(fc => {
            let rowValue = '';
            if (fc.searchFields) {
              fc.searchFields.forEach(field => {
                if (row[field]) {
                  rowValue += ' ' + row[field];
                }
              });
            } else if (row[fc.id]) {
              rowValue = row[fc.id];
            }

            const regex = new RegExp(searchText, 'gi');
            const rowMatch = rowValue.toString().match(regex) ? true : false;
            if (rowMatch === true) isMatch = true;
          });
          return isMatch;
        });
      }

      // apply filter by incomplete
      if (this.state.isFilterIncomplete) {
        filteredData = filteredData.filter(row => {
          return row.unsignedNoteCount > 0 || row.hasUnsignedChanges === true;
        });
      }
    }

    return filteredData;
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSearch = async event => {
    const searchText = event.target.value;

    if (this.searchTimer) clearTimeout(this.searchTimer);
    if (this.analyticsTimer) clearTimeout(this.analyticsTimer);

    if (searchText === '') {
      await this.setState({
        data: this.props.data,
        searchText,
      });

      this.onDataChanged();
    } else {
      if (!isNil(this.props.searchAnalyticsOptions)) {
        this.analyticsTimer = setTimeout(() => {
          logEvent(this.props.searchAnalyticsOptions.eventName, {
            providerId: this.props.searchAnalyticsOptions.providerId,
          });
        }, 1500); // wait longer than search debounce to prevent lots of extra events
      }

      this.searchTimer = setTimeout(() => {
        const filterData = this.getFilteredData();
        this.setState({ data: filterData, searchText });
        this.onDataChanged();
      }, 300);
    }
  };

  handleIncompleteToggle = event => {
    if (this.state.isFilterIncomplete === false) {
      this.setState(
        {
          isFilterIncomplete: !this.state.isFilterIncomplete,
        },
        () => {
          this.onDataChanged();
        }
      );
    } else {
      this.setState(
        {
          isFilterIncomplete: !this.state.isFilterIncomplete,
        },
        () => {
          this.onDataChanged();
        }
      );
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
    this.onPagingChanged(this.state.data);
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
    this.onPagingChanged(this.state.data);
  };

  handleShowAdvancedSearch = () => {
    this.setState({
      isShowAdvancedSearchModal: true,
    });
  };

  handleAdvancedSearch = searchData => {
    this.setState({
      isShowAdvancedSearchModal: false,
      advancedSearchData: searchData,
    });

    this.props.handleAdvancedSearch(searchData);
  };

  handleCloseAdvancedSearch = () => {
    this.setState({
      isShowAdvancedSearchModal: false,
    });
  };

  handleClearAdvancedSearchData = () => {
    this.setState({
      advancedSearchData: null,
    });

    this.props.onAdvancedSearchClose();
  };

  render() {
    const { classes, columns, title, isAdvancedSearch, renderRow, disableSearch, isLoading, theme } = this.props;
    const { order, orderBy, rowsPerPage, page, isShowAdvancedSearchModal, advancedSearchData, isFilterIncomplete } = this.state;
    let { data } = this.state;
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    if (isLoading === true) {
      return <LoadingOverlay />;
    }

    return (
      <div className={classes.root}>
        {isAdvancedSearch && !disableSearch === true && (
          <DataTableAdvancedSearchToolbar
            title={'Advanced Search'}
            handleClose={this.handleClearAdvancedSearchData}
            handleSearch={this.handleAdvancedSearch}
            advancedSearchData={advancedSearchData}
            numberOfRows={data.length}
          />
        )}

        {!isAdvancedSearch && !disableSearch === true && (
          <DataTableToolbar
            title={title}
            handleSearch={this.handleSearch}
            handleShowAdvancedSearch={this.handleShowAdvancedSearch}
            advancedSearchData={advancedSearchData}
            handleIncompleteToggle={this.handleIncompleteToggle}
            isFilterIncomplete={isFilterIncomplete}
          />
        )}

        <div className={classes.tableContainer}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            {data && data.length > 0 ? (
              <DataTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
                columns={columns}
              />
            ) : (
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant={'h6'} style={{ padding: '1rem', color: theme.palette.primary.darkgray }}>
                      No Results Available
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n, i) => renderRow(n, i))}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </div>

        {data && data.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        )}

        <CustomModal open={isShowAdvancedSearchModal} handleClose={this.handleCloseAdvancedSearch}>
          <DataTableAdvancedSearchForm handleSearch={this.handleAdvancedSearch} handleClose={this.handleCloseAdvancedSearch} />
        </CustomModal>
      </div>
    );
  }
}

DataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
  columns: PropTypes.array.isRequired,
  onAdvancedSearchClose: PropTypes.func,
  handleAdvancedSearch: PropTypes.func,
  isAdvancedSearch: PropTypes.bool,
  renderRow: PropTypes.func.isRequired,
  disableSearch: PropTypes.bool,
  onDataChanged: PropTypes.func, // callback when sort/page/filter is updated
  defaultSortOrder: PropTypes.string,
  defaultSortField: PropTypes.string,
  searchAnalyticsOptions: PropTypes.object, // optional: provide analytics object to track search events
};

export default withStyles(styles, { withTheme: true })(DataTable);
