import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Timer from '../../common/timer/timer.component';
import { visitStatusTypes } from '../../types/visitStatusTypes';

const PatientBannerSummary = ({ classes, selectedVisit: { firstName, lastName, mobileNumber, state, waitStartTimestamp } }) => {
  return (
    <>
      <Grid item className={classes.statusContainer}>
        {state === visitStatusTypes.SELECTED && (
          <Typography className={classes.heading} variant="h6">
            Waiting for patient:
          </Typography>
        )}
        {state === visitStatusTypes.READY && (
          <Typography className={classes.heading} variant="h6">
            Patient Ready:
          </Typography>
        )}
        <Typography className={classes.heading} variant="h6">{`${firstName} ${lastName}`}</Typography>
        {mobileNumber && (
          <Typography className={classes.heading} variant="h6">
            <NumberFormat displayType="text" value={(mobileNumber || '').substring(2)} format="(###) ###-####" />
          </Typography>
        )}
      </Grid>
      <Grid item className={classes.statusContainer}>
        <Typography className={classes.heading} style={{ alignSelf: 'center' }} variant="h6">
          <Timer startTime={waitStartTimestamp} showSeconds />
        </Typography>
      </Grid>
    </>
  );
};

const styles = theme => ({
  heading: {
    marginRight: theme.spacing.unit * 2,
  },
  statusContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',

    '@media (max-width: 960px)': {
      flex: '1 0 100%',
      marginTop: '1rem',
    },
  },
});

PatientBannerSummary.propTypes = {
  classes: PropTypes.object.isRequired,

  selectedVisit: PropTypes.object.isRequired,
};

export default withStyles(styles)(PatientBannerSummary);
