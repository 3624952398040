import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { InlineDatePicker } from 'material-ui-pickers';
import moment from 'moment';
import { isNil } from 'lodash';
import { getRelationshipOptions, relationshipTypes } from '../../types/insuranceRelationships';
import ImageDetailModal from '../../common/imageDetailModal/imageDetailModal.component';
import { getUSStateOptions } from '../../utilities/provinceUtils';
import { genderTypes, getLongGender } from '../../types/genderTypes';
import { DATE_FIELD_MASK, DATE_FIELD_FORMAT } from '../../utilities/dateUtils';

const genderOptions = [
  {
    value: genderTypes.MALE,
    label: getLongGender(genderTypes.MALE),
  },
  {
    value: genderTypes.FEMALE,
    label: getLongGender(genderTypes.FEMALE),
  },
  {
    value: genderTypes.ASK,
    label: getLongGender(genderTypes.ASK),
  },
];

const PatientInsurancePhoto = ({ imageUrl, thumbUrl, ...props }) => {
  const [src, setSrc] = useState(thumbUrl);

  return <img {...props} src={src} alt="Insurance" onError={() => setSrc(imageUrl)} />;
};

const ChartPatientInsurance = ({
  classes,
  insurance: {
    attachments,
    carrierName,
    policyNumber,
    relationshipToInsured,
    subscriberAddressLine1,
    subscriberAddressLine2,
    subscriberAddressCity,
    subscriberAddressState,
    subscriberAddressZip,
    subscriberDob,
    subscriberGender,
    subscriberId,
    subscriberName,
    cardImageUrl,
    cardImageThumbnailUrl,
    cardImageUploadId,
    cardImage2Url,
    cardImage2ThumbnailUrl,
    cardImageUpload2Id,
    cardImage3Url,
    cardImage3ThumbnailUrl,
    cardImageUpload3Id,
    cardImage4Url,
    cardImage4ThumbnailUrl,
    cardImageUpload4Id,
  },
  handleUpdateInsuranceField,
  handleRemoveInsurancePhoto,
}) => {
  const [isShowImageDetail, setIsShowImageDetail] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [invalidDateFormat, setInvalidDateFormat] = useState(false);

  const handleToggleImageDetails = useCallback(
    url => {
      setIsShowImageDetail(!isShowImageDetail);
      setImageUrl(url);
    },
    [setIsShowImageDetail, setImageUrl, isShowImageDetail]
  );

  // set date value in state
  // accept format as valid if date is accepted
  const handleDateSelect = useCallback(
    name => date => {
      handleUpdateInsuranceField(name)(date);
      setInvalidDateFormat(false);
    },
    [handleUpdateInsuranceField, setInvalidDateFormat]
  );

  // do validation on each character entry
  const handleDateInputChange = name => event => {
    setInvalidDateFormat(false);

    const { value } = event.target;
    const updateMoment = moment(value);
    const isValid = updateMoment.isValid();

    // valid date
    if (isValid) {
      handleUpdateInsuranceField(name)(event);
    }

    // blank update value
    if (value === '') {
      handleUpdateInsuranceField(name)(null);
    }

    // invalid date and have update value
    if (!isValid && value !== '') {
      setInvalidDateFormat(true);
    }
  };

  const showExtraFields = !isNil(relationshipToInsured) && relationshipToInsured !== relationshipTypes.RELATIONSHIP_SELF.value;
  const hasPhotos = attachments.length > 0;

  return (
    <div className={classes.padding}>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.expansionRoot }}>
          <Typography className={classes.title}>INSURANCE INFORMATION</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container>
            <Grid item sm={12} md={hasPhotos ? 6 : 12}>
              <TextField
                value={carrierName || ''}
                className={classes.textField}
                label="Carrier Name"
                onChange={handleUpdateInsuranceField('carrierName')}
              />
              <TextField
                value={subscriberId || ''}
                className={classes.textField}
                label="Subscriber / Member ID"
                onChange={handleUpdateInsuranceField('subscriberId')}
              />
              <TextField
                value={policyNumber || ''}
                className={classes.textField}
                label="Policy / Group Number"
                onChange={handleUpdateInsuranceField('policyNumber')}
              />
              <Grid container spacing={8}>
                <Grid item xs={showExtraFields ? 6 : 12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="relationshipToInsured">Relationship to Insured</InputLabel>
                    <Select value={relationshipToInsured || ''} onChange={handleUpdateInsuranceField('relationshipToInsured')}>
                      <MenuItem key="simonwashere" value="" />
                      {getRelationshipOptions().map(o => (
                        <MenuItem key={o.value} value={o.value}>
                          {o.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {showExtraFields && (
                  <Grid item xs={6}>
                    <TextField
                      value={subscriberName || ''}
                      className={classes.textField}
                      label="Subscriber Name"
                      onChange={handleUpdateInsuranceField('subscriberName')}
                    />
                  </Grid>
                )}
              </Grid>
              {showExtraFields && (
                <>
                  <Grid container spacing={8}>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="subscriberGender">Subscriber Gender</InputLabel>
                        <Select value={subscriberGender || ''} onChange={handleUpdateInsuranceField('subscriberGender')}>
                          <MenuItem value="" />
                          {genderOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl}>
                        <InlineDatePicker
                          keyboard
                          clearable
                          label="Subscriber Date of Birth"
                          value={subscriberDob}
                          onChange={handleDateSelect('subscriberDob')}
                          onInputChange={handleDateInputChange('subscriberDob')}
                          format={DATE_FIELD_FORMAT}
                          mask={DATE_FIELD_MASK}
                          error={false} // disable error effects
                          helperText={invalidDateFormat ? 'Invalid Date' : ''}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={8}>
                    <Grid item xs={6}>
                      <TextField
                        value={subscriberAddressLine1 || ''}
                        className={classes.textField}
                        label="Subscriber Address Line 1"
                        onChange={handleUpdateInsuranceField('subscriberAddressLine1')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={subscriberAddressLine2 || ''}
                        className={classes.textField}
                        label="Subscriber Address Line 2"
                        onChange={handleUpdateInsuranceField('subscriberAddressLine2')}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={8}>
                    <Grid item xs={4}>
                      <TextField
                        value={subscriberAddressCity || ''}
                        className={classes.textField}
                        label="Subscriber City"
                        onChange={handleUpdateInsuranceField('subscriberAddressCity')}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="subscriberState">Subscriber State</InputLabel>
                        <Select value={subscriberAddressState || ''} onChange={handleUpdateInsuranceField('subscriberAddressState')}>
                          <MenuItem value="" />
                          {getUSStateOptions().map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        value={subscriberAddressZip || ''}
                        className={classes.textField}
                        label="Subscriber Zip"
                        onChange={handleUpdateInsuranceField('subscriberAddressZip')}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item sm={12} md={6}>
              {[
                {
                  id: cardImageUploadId,
                  thumbUrl: cardImageThumbnailUrl,
                  imageUrl: cardImageUrl,
                },
                {
                  id: cardImageUpload2Id,
                  thumbUrl: cardImage2ThumbnailUrl,
                  imageUrl: cardImage2Url,
                },
                {
                  id: cardImageUpload3Id,
                  thumbUrl: cardImage3ThumbnailUrl,
                  imageUrl: cardImage3Url,
                },
                {
                  id: cardImageUpload4Id,
                  thumbUrl: cardImage4ThumbnailUrl,
                  imageUrl: cardImage4Url,
                },
              ]
                .filter(item => item.imageUrl)
                .map((attachment, index) => (
                  <div key={attachment.id}>
                    <PatientInsurancePhoto
                      className={classes.cardImage}
                      imageUrl={attachment.imageUrl}
                      thumbUrl={attachment.thumbUrl}
                      onClick={() => handleToggleImageDetails(attachment.imageUrl)}
                    />
                    <div className={classes.actions}>
                      <Button onClick={() => handleRemoveInsurancePhoto(index)} variant="outlined" className={classes.actionButton}>
                        Remove Photo
                      </Button>
                    </div>
                  </div>
                ))}
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
        {/* </Paper> */}
      </ExpansionPanel>
      <ImageDetailModal open={isShowImageDetail} image={{ url: imageUrl }} handleClose={handleToggleImageDetails} />
    </div>
  );
};

const styles = theme => ({
  expansionRoot: {
    padding: '0 1rem',
  },
  title: {
    marginBottom: '0.5rem',
  },
  padding: {
    padding: '1rem',
  },
  textField: {
    width: '100%',
    marginBottom: '1rem',
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cardImage: {
    maxHeight: 200,
    cursor: 'pointer',
    float: 'right',
    marginRight: '1rem',
  },
  formControl: {
    marginBottom: '1rem',
    width: '100%',
  },
  actionButton: {
    color: theme.palette.primary.main,
    margin: '1rem',
  },
});

ChartPatientInsurance.propTypes = {
  classes: PropTypes.object.isRequired,

  insurance: PropTypes.object.isRequired,

  handleUpdateInsuranceField: PropTypes.func.isRequired,
  handleRemoveInsurancePhoto: PropTypes.func.isRequired,
};

export default withStyles(styles)(ChartPatientInsurance);
