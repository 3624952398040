import React from 'react';
import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';

const RelyMDPrimaryButton = ({ children, classes, variant, onClick, ...rest }) => {
  return (
    <Button classes={{ contained: classes.contained }} color="primary" variant={variant} onClick={onClick} {...rest}>
      {children}
    </Button>
  );
};

const styles = () => ({
  contained: {
    boxShadow: 'none',
  },
});

RelyMDPrimaryButton.propTypes = {
  classes: PropTypes.object.isRequired,

  children: PropTypes.any.isRequired,
  variant: PropTypes.string,

  onClick: PropTypes.func.isRequired,
};

RelyMDPrimaryButton.defaultProps = {
  variant: 'outlined',
};

export default withStyles(styles)(RelyMDPrimaryButton);
