import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { OutlinedInput, FormControl, InputLabel, Button } from '@material-ui/core';
import moment from 'moment';
import { InlineDatePicker } from 'material-ui-pickers';
import { isEmpty } from 'lodash';
import { keyboardCodes } from '../../types/keyboardCodes';
import { DATE_FIELD_FORMAT, DATE_FIELD_MASK } from '../../utilities/dateUtils';

const styles = theme => ({
  formControl: {
    width: '100%',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonDisabled: {
    backgroundColor: theme.palette.primary.lightgray,
  },
  headingText: {
    fontSize: '1.2rem',
    fontWeight: 500,
    color: theme.palette.primary.darkgray,
  },
  closeButton: {
    color: theme.palette.primary.main,
  },
});

class DataTableAdvancedSearchForm extends Component {
  constructor(props) {
    super(props);

    const initState = {
      firstName: '',
      lastName: '',
      dob: null,
      visitDate: null,
      providerName: '',

      dobInvalidDateFormat: false,
      visitDateInvalidDateFormat: false,
    };

    // init inbound data if provided via props
    if (!isEmpty(props.data)) {
      // string fields
      ['firstName', 'lastName', 'providerName'].forEach(key => {
        initState[key] = !isEmpty(props.data[key]) ? props.data[key] : '';
      });

      // date fields
      ['visitDate', 'dob'].forEach(key => {
        initState[key] = !isEmpty(props.data[key]) ? moment(props.data[key]) : null;
      });
    }

    this.state = initState;

    // for outline notch width calculation
    this.fnameNode = null;
    this.lnameNode = null;
    this.providerNameNode = null;
  }

  componentDidMount() {
    // force refs to exist for label widths
    this.forceUpdate();
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  // set date value in state
  // accept format as valid if date is accepted
  handleDateAccept = name => date => {
    this.setState({ [name]: date, dobInvalidDateFormat: false, visitDateInvalidDateFormat: false });
  };

  // do validation on each character entry
  onDateInputChange = name => event => {
    const updateVal = event.target.value;
    const updateMoment = moment(updateVal);
    const isValid = updateMoment.isValid();
    const invalidDateKey = `${name}InvalidDateFormat`;

    // valid date
    if (isValid) {
      this.handleDateAccept(name)(updateMoment);
      this.setState({
        [invalidDateKey]: false,
      });
    }

    // blank update value
    if (updateVal === '') {
      this.handleDateAccept(name)(null);
      this.setState({
        [invalidDateKey]: false,
      });
    }

    // invalid date and have update value
    if (!isValid && updateVal !== '') {
      this.setState({
        [invalidDateKey]: true,
      });
    }
  };

  formHasErrors = () => {
    const dateFields = ['dob', 'visitDate'];
    const textFields = ['firstName', 'lastName', 'providerName'];

    const dateFieldsEmpty = dateFields.every(item => this.state[item] === null);
    const textFieldsEmpty = textFields.every(item => this.state[item] === '');
    const { dobInvalidDateFormat, visitDateInvalidDateFormat } = this.state;

    return (dateFieldsEmpty && textFieldsEmpty) || dobInvalidDateFormat || visitDateInvalidDateFormat;
  };

  handleSubmit = () => {
    const { firstName, lastName, dob, visitDate, providerName } = this.state;
    const data = {
      firstName,
      lastName,
      dob: !isEmpty(dob) ? dob.format(DATE_FIELD_FORMAT) : '',
      visitDate: !isEmpty(visitDate) ? visitDate.format(DATE_FIELD_FORMAT) : '',
      providerName,
    };

    this.props.handleSearch(data);
  };

  handleKeyDown = event => {
    switch (event.keyCode) {
      case keyboardCodes.ENTER:
        if (!this.formHasErrors()) this.handleSubmit();
        break;

      default:
        break;
    }
  };

  render() {
    const { classes, handleClose, isToolbarView } = this.props;
    const { firstName, lastName, dob, visitDate, providerName, dobInvalidDateFormat, visitDateInvalidDateFormat } = this.state;

    const formHasErrors = this.formHasErrors();

    return (
      <Grid container spacing={24} style={{ maxWidth: '100%' }} onKeyDown={this.handleKeyDown}>
        {!isToolbarView && (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography className={classes.headingText}>PREVIOUS VISIT SEARCH</Typography>
          </Grid>
        )}

        <Grid item xs={12} md={6} lg={isToolbarView ? 3 : 6} className={classes.gridItem}>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel
              ref={ref => {
                this.fnameNode = ReactDOM.findDOMNode(ref);
              }}
              htmlFor="firstName"
            >
              First Name
            </InputLabel>
            <OutlinedInput
              autoComplete="no"
              id="firstName"
              value={firstName}
              onChange={this.handleChange('firstName')}
              labelWidth={this.fnameNode ? this.fnameNode.offsetWidth : 0}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} lg={isToolbarView ? 3 : 6}>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel
              ref={ref => {
                this.lnameNode = ReactDOM.findDOMNode(ref);
              }}
              htmlFor="lastName"
            >
              Last Name
            </InputLabel>
            <OutlinedInput
              autoComplete="no"
              id="lastName"
              value={lastName}
              onChange={this.handleChange('lastName')}
              labelWidth={this.lnameNode ? this.lnameNode.offsetWidth : 0}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} lg={isToolbarView ? 3 : 6}>
          <InlineDatePicker
            className={classes.formControl}
            keyboard
            clearable
            variant="outlined"
            label="Patient DOB"
            value={dob}
            onChange={this.handleDateAccept('dob')}
            onInputChange={this.onDateInputChange('dob')}
            format={DATE_FIELD_FORMAT}
            openTo="year"
            mask={DATE_FIELD_MASK}
            error={false} // disable error effects
            helperText={dobInvalidDateFormat ? 'Invalid Date' : ''}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={isToolbarView ? 3 : 6}>
          <InlineDatePicker
            className={classes.formControl}
            keyboard
            clearable
            variant="outlined"
            label="Patient Visit Date"
            value={visitDate}
            onChange={this.handleDateAccept('visitDate')}
            onInputChange={this.onDateInputChange('visitDate')}
            format={DATE_FIELD_FORMAT}
            mask={DATE_FIELD_MASK}
            error={false} // disable error effects
            helperText={visitDateInvalidDateFormat ? 'Invalid Date' : ''}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={isToolbarView ? 3 : 6}>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel
              ref={ref => {
                this.providerNameNode = ReactDOM.findDOMNode(ref);
              }}
              htmlFor="providerName"
            >
              Provider Name
            </InputLabel>
            <OutlinedInput
              autoComplete="no"
              id="providerName"
              value={providerName}
              onChange={this.handleChange('providerName')}
              labelWidth={this.providerNameNode ? this.providerNameNode.offsetWidth : 0}
            />
          </FormControl>
        </Grid>

        <Grid container item xs={12} justify="flex-end">
          <Button
            className={classes.closeButton}
            variant="outlined"
            style={{
              marginRight: '1rem',
              height: isToolbarView ? '2.5rem' : 'unset',
              alignSelf: isToolbarView ? 'flex-end' : 'unset',
            }}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant="outlined"
            onClick={this.handleSubmit}
            disabled={formHasErrors}
            classes={{ root: formHasErrors ? classes.buttonDisabled : classes.button }}
            style={{ height: isToolbarView ? '2.5rem' : 'unset', alignSelf: isToolbarView ? 'flex-end' : 'unset' }}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    );
  }
}

DataTableAdvancedSearchForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  isToolbarView: PropTypes.bool,
};

export default withStyles(styles)(DataTableAdvancedSearchForm);
