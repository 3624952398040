import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import { Typography } from '@material-ui/core';
import { getFriendlyName, providerStatusTypes } from '../../types/providerStatusTypes';
import { logEvent } from '../../utilities/googleAnalytics';

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 160,
    color: theme.palette.primary.white
  },
  outlinedInputRoot: {
    color: theme.palette.primary.white,
    borderColor: [[theme.palette.primary.white], '!important']
  },
  notchedOutline: {
    color: theme.palette.primary.white,
    borderColor: [[theme.palette.primary.white], '!important'],
    borderWidth: [1, '!important']
  },
  selectIcon: {
    fill: theme.palette.primary.white
  },
  notchedOutlineInput: {
    paddingTop: 12,
    paddingRight: 8,
    paddingBottom: 12,
    paddingLeft: 14
  },
  callStatusLabelTypography: {
    color: theme.palette.primary.white,
    fontSize: 12,
    fontWeight: 100
  }
});

class CallStatusSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      labelWidth: 0,
      availability: props.provider ? props.provider.availability : 'unavailable',
      notificationThreshold: props.provider ? props.provider.notificationThreshold : null
    };

    this.inputLabelRef = null;
  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.inputLabelRef).offsetWidth
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.provider !== this.props.provider) {
      this.setState({
        availability: nextProps.provider.availability,
        notificationThreshold: nextProps.provider.notificationThreshold
      });
    }
  }

  handleChange = event => {
    let value = event.target.value.split(',');
    const status = value[0];
    const duration = value[1];

    logEvent('update_callstatus', {
      providerId: this.props.provider.id,
      currentStatus: this.getCallStatusValue(),
      newStatus: `${status},${duration}`
    });

    this.props.updateAvailability(status, duration);
  };

  getCallStatusValue = () => {
    const { provider } = this.props;
    return provider.availability === providerStatusTypes.BACKUP
      ? `${provider.availability},${provider.notificationThreshold}`
      : `${provider.availability},5`;
  };

  render() {
    const { classes } = this.props;
    const { labelWidth, availability, notificationThreshold } = this.state;
    const callStatusValue = availability === providerStatusTypes.BACKUP ? `${availability},${notificationThreshold}` : `${availability},5`;

    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel
          shrink
          htmlFor="outlined-call-status"
          ref={ref => {
            this.inputLabelRef = ref;
          }}
          className={classes.inputLabel}
        >
          <Typography className={classes.callStatusLabelTypography}>{'CALL STATUS'}</Typography>
        </InputLabel>
        <Select
          value={callStatusValue}
          onChange={this.handleChange}
          input={
            <OutlinedInput
              name="callstatus"
              id="outlined-call-status"
              labelWidth={labelWidth}
              classes={{ root: classes.outlinedInputRoot, notchedOutline: classes.notchedOutline, input: classes.notchedOutlineInput }}
            />
          }
          inputProps={{
            classes: {
              icon: classes.selectIcon
            }
          }}
        >
          <MenuItem value={`${providerStatusTypes.UNAVAILABLE},5`}>{getFriendlyName(providerStatusTypes.UNAVAILABLE)}</MenuItem>
          <MenuItem value={`${providerStatusTypes.ON_CALL},5`}>{getFriendlyName(providerStatusTypes.ON_CALL)}</MenuItem>
          <MenuItem value={`${providerStatusTypes.BACKUP},5`}>{getFriendlyName(providerStatusTypes.BACKUP, 5)}</MenuItem>
          <MenuItem value={`${providerStatusTypes.BACKUP},10`}>{getFriendlyName(providerStatusTypes.BACKUP, 10)}</MenuItem>
          <MenuItem value={`${providerStatusTypes.BACKUP},15`}>{getFriendlyName(providerStatusTypes.BACKUP, 15)}</MenuItem>
        </Select>
      </FormControl>
    );
  }
}

export default withStyles(styles)(CallStatusSelect);
