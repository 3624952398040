export const visitStatusTypes = {
  NEW: 'new',
  ASSIGNED: 'assigned',
  SCHEDULED: 'scheduled',
  SELECTED: 'selected',
  READY: 'ready',
  CANCELED: 'canceled',
  STARTED: 'started',
  COMPLETED: 'completed',
};

export const phoneVisitStatusTypes = {
  COMPLETED: 'completed',
  INITIATED: 'initiated',
  IN_PROGRESS: 'in-progress',
  NO_ANSWER: 'no-answer',
  RINGING: 'ringing',
};
