import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DataTable from '../../common/dataTable/dataTable.component';
import moment from 'moment';
import TableRowActions from '../../common/dataTable/dataTableRowActions.component';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { mapJsonToLabelList } from '../../utilities/nihApiUtils';
import { nihApiTypes } from '../../types/nihApiTypes';
import { can } from '../login/can';
import { providerGroupPermissions } from '../../types/permissionTypes';

const styles = theme => ({
  root: {
    // width: '100%'
  },
  tableRowRoot: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.primary.lightgray
    },
    cursor: 'pointer'
  }
});

// COLUMN DEFINITIONS
// note: ids determine sort order key

// DEFAULT
const defaultColumns = [
  { id: 'created', numeric: false, disablePadding: false, label: 'Date', sortable: true, searchable: true },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    sortable: true,
    searchable: true,
    searchFields: ['firstName', 'lastName']
  },
  { id: 'dob', numeric: false, disablePadding: false, label: 'DoB', sortable: true, searchable: true },
  { id: 'signedDate', numeric: false, disablePadding: false, label: 'Signed', sortable: true },
  { id: 'hasUnsignedChanges', numeric: false, disablePadding: false, label: 'Unsigned Changes', sortable: true },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
];

// ADVANCED
const advancedColumns = [
  { id: 'created', numeric: false, disablePadding: false, label: 'Date', sortable: true, searchable: true },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    sortable: true,
    searchable: true,
    searchFields: ['firstName', 'lastName']
  },
  { id: 'dx', numeric: false, disablePadding: false, label: 'Dx', sortable: true, searchable: true },
  { id: 'provider', numeric: false, disablePadding: false, label: 'Provider', sortable: true, searchable: true },
  { id: 'signedDate', numeric: false, disablePadding: false, label: 'Notes (unsigned/total)', sortable: true, searchable: false }
];

class PreviousVisitsDataTable extends Component {
  handleRowClick = (event, row, index) => {
    event.stopPropagation();
    event.preventDefault();

    if (
      can(providerGroupPermissions.readVisit, row.entranceId) ||
      can(providerGroupPermissions.updateVisit, row.entranceId) ||
      can(providerGroupPermissions.createAddendum, row.entranceId) ||
      can(providerGroupPermissions.updateAddendum, row.entranceId)
    ) {
      this.props.handleRowClick(row, index);
    }
  };

  handleRowEdit = (event, id, isAddend) => {
    event.stopPropagation();
    event.preventDefault();

    this.props.handleRowEdit(isAddend)(id);
  };

  // get custom row render component
  getRowRenderFunc = isAdvancedSearch => {
    return isAdvancedSearch
      ? (row, i) => {
          const totalNotes = row.unsignedNoteCount + row.signedNoteCount;
          const isAddend = row.providerId !== this.props.providerId;

          return (
            <TableRow
              onClick={event => this.handleRowClick(event, row, i)}
              role="checkbox"
              tabIndex={-1}
              key={i}
              classes={{ root: this.props.classes.tableRowRoot }}
            >
              <TableCell>{new moment(row.created).format('M/D/YYYY')}</TableCell>
              <TableCell align="left">{`${row.firstName} ${row.lastName}`}</TableCell>
              <TableCell align="left">{mapJsonToLabelList(nihApiTypes.NIH_DIAGNOSIS, row.diagnosis)}</TableCell>
              <TableCell align="left">{row.providerName}</TableCell>
              <TableCell align="left">
                {row.unsignedNoteCount > 0 ? (
                  <div>
                    <span style={{ color: this.props.theme.palette.primary.red }}>{`${row.signedNoteCount}`}</span>
                    <span>{`/${totalNotes}`}</span>
                  </div>
                ) : (
                  `${row.signedNoteCount}/${totalNotes}`
                )}
              </TableCell>
              <TableCell align="left">
                <TableRowActions
                  rowData={row}
                  handleViewAction={event => this.handleRowClick(event, row, i)}
                  handleEditAction={event => this.handleRowEdit(event, row.id, isAddend)}
                  isAddend={isAddend}
                />
              </TableCell>
            </TableRow>
          );
        }
      : (row, i) => {
          const hasUnsignedChanges = row.hasUnsignedChanges === true;
          const isAddend = row.providerId !== this.props.providerId;

          return (
            <TableRow
              onClick={event => this.handleRowClick(event, row, i)}
              role="checkbox"
              tabIndex={-1}
              key={i}
              classes={{ root: this.props.classes.tableRowRoot }}
            >
              <TableCell>{new moment(row.created).format('M/D/YYYY')}</TableCell>
              <TableCell align="left">{`${row.firstName} ${row.lastName}`}</TableCell>
              <TableCell align="left">{new moment(row.dob).format('M/D/YYYY')}</TableCell>
              <TableCell align="left">{row.signedNoteCount > 0 ? `Yes (${row.signedNoteCount})` : 'No'}</TableCell>
              <TableCell align="left">
                {hasUnsignedChanges ? <div style={{ color: this.props.theme.palette.primary.orange }}>{'Yes'}</div> : 'No'}
              </TableCell>
              <TableCell align="left">
                {
                  <TableRowActions
                    rowData={row}
                    handleViewAction={event => this.handleRowClick(event, row, i)}
                    handleEditAction={event => this.handleRowEdit(event, row.id, isAddend)}
                    isAddend={isAddend}
                  />
                }
              </TableCell>
            </TableRow>
          );
        };
  };

  render() {
    const {
      data,
      onAdvancedSearchClose,
      handleAdvancedSearch,
      isAdvancedSearch,
      isLoading,
      handlePreviousVisitsDataChanged,
      providerId
    } = this.props;
    return (
      <DataTable
        data={data}
        title={'My Previous Visits'}
        columns={isAdvancedSearch ? advancedColumns : defaultColumns}
        onAdvancedSearchClose={onAdvancedSearchClose}
        handleAdvancedSearch={handleAdvancedSearch}
        isAdvancedSearch={isAdvancedSearch}
        renderRow={this.getRowRenderFunc(isAdvancedSearch)}
        isLoading={isLoading}
        onDataChanged={handlePreviousVisitsDataChanged}
        searchAnalyticsOptions={{
          eventName: 'search_myvisits',
          providerId
        }}
      />
    );
  }
}

PreviousVisitsDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  handleRowEdit: PropTypes.func,
  handleRowClick: PropTypes.func,
  data: PropTypes.array.isRequired,
  onAdvancedSearchClose: PropTypes.func,
  handleAdvancedSearch: PropTypes.func,
  isAdvancedSearch: PropTypes.bool,
  providerId: PropTypes.string,
  handlePreviousVisitsDataChanged: PropTypes.func
};

export default withStyles(styles, { withTheme: true })(PreviousVisitsDataTable);
