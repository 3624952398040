import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import MainLayout from './modules/layout/main.layout';
import PropTypes from 'prop-types';
import React from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import UnsupportedBrowser from './modules/layout/unsupportedBrowser.component';
import { isSupportedBrowser } from './utilities/browserUtils';

const Root = ({ store }) => (
  <Provider store={store}>
    <DragDropContextProvider backend={HTML5Backend}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div style={{ display: 'flex' }}>
          {!isSupportedBrowser && <UnsupportedBrowser />}
          {isSupportedBrowser && <Route path="/" component={MainLayout} />}
        </div>
      </MuiPickersUtilsProvider>
    </DragDropContextProvider>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired
};
export default Root;
